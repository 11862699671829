import { Button, ErrorMsg } from '@pelotoncycle/design-system'
import { useBenefits } from 'data/hooks'
import { getBenefitById } from 'data/selectors'
import { ProgramBenefitCountry } from 'data/types/graphql-global-types'
import { useState, FocusEvent } from 'react'
import { useWatch, Control, FieldError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Box, Flex } from 'ui/components'
import { useCurrencyForCountry } from 'ui/hooks'
import { hasValue } from 'utils'
import {
  ActivePayCheckbox,
  BenefitSelect,
  CountrySelect,
  PricingInput,
  TProgramBenefits,
} from './Fields'
import { FormSection } from './FormSection'
import { SplitRow } from './SplitRow'
import { StyledRemoveButton } from './StyledRemoveButton'

type TProgramBenefitFormProps = {
  handleUpdateValues: () => void
  handleResetValues: () => void
  index: number
  control: Control<TProgramBenefits>
  isDisabled: boolean
  buttonText: string
  titleText?: string
  onBlurPricingField: () => void
  onChangeBenefit?: () => void
  onChangeCountry?: () => void
  error?: FieldError
  canReset?: boolean
  programEligibleForActivePay: boolean
  countryBenefitCombinations: {
    country: ProgramBenefitCountry
    benefitId: string
    index: number
  }[]
}

const ADD_BTN_ID = 'add-program-benefit'

const ProgramBenefitForm = ({
  programEligibleForActivePay,
  handleUpdateValues,
  handleResetValues,
  index,
  control,
  isDisabled,
  buttonText,
  titleText,
  onBlurPricingField,
  onChangeBenefit,
  onChangeCountry,
  error,
  canReset,
  countryBenefitCombinations = [],
}: TProgramBenefitFormProps) => {
  const { t } = useTranslation()
  const [submitCount, setSubmitCount] = useState(0)
  const { data: benefitsData } = useBenefits()

  const [watchBenefitId, watchPartnerPays, watchUserPays, watchDiscount, watchCountry] =
    useWatch({
      control,
      name: [
        `programBenefits.${index}.benefit.id`,
        `programBenefits.${index}.partnerPaysRaw`,
        `programBenefits.${index}.userPaysRaw`,
        `programBenefits.${index}.discountRaw`,
        `programBenefits.${index}.country`,
      ],
    })

  const existingCountriesForBenefit = countryBenefitCombinations.reduce((acc, item) => {
    if (item.index !== index && item.benefitId === watchBenefitId) {
      acc.push(item.country)
    }

    return acc
  }, [] as ProgramBenefitCountry[])

  const existingBenefitsForCountry = countryBenefitCombinations.reduce((acc, item) => {
    if (item.index !== index && item.country === watchCountry) {
      acc.push(item.benefitId)
    }

    return acc
  }, [] as string[])

  const { currency, currencySymbol } = useCurrencyForCountry(watchCountry)

  const benefitRecord = getBenefitById({
    benefits: benefitsData?.benefits,
    id: watchBenefitId,
  })
  const isDigitalSubscription = !!(
    benefitRecord && benefitRecord.subscriptionType.startsWith('digital')
  )
  const isFullySubsidized = !watchUserPays || Number(watchUserPays) === 0
  const activePayAvailable = isFullySubsidized && isDigitalSubscription

  const handleBlur = (event: FocusEvent<HTMLInputElement, HTMLElement>) => {
    // goofy - but revalidation isn't clearing errors on dependent pricing fields,
    // so we have to do it manually, but only after initial add/submit has been called.
    // Additionally, onBlur events have a higher priority than onClick, so clicking the Add button
    // while a pricing input has focus triggers an onBlur event, which swallows the click. We
    // therefore have to check that the blur event was caused by blurring other form fields, not
    // by clicking Add (which will trigger validations anyway)
    const blurTriggeredByAddBtn = event.relatedTarget?.dataset?.id === ADD_BTN_ID
    if (submitCount > 0 && !blurTriggeredByAddBtn) {
      onBlurPricingField()
    }
  }

  const pricingFieldRequired = (dependentValues: unknown[]) =>
    dependentValues.filter(hasValue).length < 2

  const handleCommit = () => {
    setSubmitCount(submitCount + 1)
    handleUpdateValues()
  }

  return (
    <div>
      {error?.message && (
        <Box margin="16px 0">
          <ErrorMsg>{error.message}</ErrorMsg>
        </Box>
      )}
      <FormSection title={titleText} subtext={t('partner.select_benefit_subtext')}>
        <SplitRow>
          <BenefitSelect
            index={index}
            control={control}
            isRequired
            existingBenefitsForCountry={existingBenefitsForCountry}
            handleChange={onChangeBenefit}
          />
          <CountrySelect
            index={index}
            control={control}
            isRequired
            existingCountriesForBenefit={existingCountriesForBenefit}
            handleChange={onChangeCountry}
          />
        </SplitRow>
      </FormSection>

      <FormSection
        title={t('partner.pricing', { currency: `${currencySymbol} ${currency}` })}
        subtext={t('partner.pricing_subtext')}
      >
        <SplitRow>
          <PricingInput
            control={control}
            fieldName="partnerPaysRaw"
            label={t('partner.partner_pays')}
            index={index}
            currency={currency}
            isRequired={pricingFieldRequired([watchUserPays, watchDiscount])}
            dependentValues={[watchUserPays, watchDiscount]}
            onBlur={handleBlur}
          />
          <PricingInput
            control={control}
            fieldName="userPaysRaw"
            label={t('partner.user_pays')}
            index={index}
            currency={currency}
            isRequired={pricingFieldRequired([watchPartnerPays, watchDiscount])}
            dependentValues={[watchPartnerPays, watchDiscount]}
            onBlur={handleBlur}
          />
          <PricingInput
            control={control}
            fieldName="discountRaw"
            label={t('partner.discount')}
            index={index}
            currency={currency}
            isRequired={pricingFieldRequired([watchPartnerPays, watchUserPays])}
            dependentValues={[watchPartnerPays, watchUserPays]}
            onBlur={handleBlur}
          />
        </SplitRow>

        {/* persist this in the form so default value doesn't change from false to null on re-render */}
        <Hidden show={programEligibleForActivePay}>
          <ActivePayCheckbox
            index={index}
            control={control}
            text={t('partner.eligible_for_active_pay')}
            activePayAvailable={activePayAvailable}
          />
        </Hidden>
      </FormSection>

      <Flex alignItems="center">
        <Box marginRight="16px">
          <Button
            onClick={handleCommit}
            data-id={ADD_BTN_ID}
            width="adaptive"
            isDisabled={isDisabled}
          >
            {buttonText}
          </Button>
        </Box>
        {canReset && (
          <StyledRemoveButton onClick={handleResetValues}>
            {t('partner.reset')}
          </StyledRemoveButton>
        )}
      </Flex>
    </div>
  )
}

const Hidden = styled.div<{ show: boolean }>`
  margin: 16px 0;
  display: ${props => (props.show ? 'block' : 'none')};
`

export { ProgramBenefitForm }
