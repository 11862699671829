import { ProgramBenefitCountry } from 'data/types/graphql-global-types'
import { Partner_partner_program as TProgram } from '../queries/types/Partner'

type TProgramBenefits = TProgram['programBenefits']

/* Return an object whose keys are a county code and values are program benefits
 * for that county. Keys not added if no program benefits for a country.
 * {
 *   US: [{id: 1, ...programBenefitData}, {id: 2, ...programBenefitData}],
 *   GB: [{id: 9, ...programBenefitData}],
 * }
 * */
const sortProgramBenefitsByCountry = (programBenefits: TProgramBenefits) => {
  const initialValue = {} as Record<ProgramBenefitCountry, TProgramBenefits>

  return programBenefits.reduce((acc, pb) => {
    const { country } = pb
    if (country && !acc[country]) {
      acc[country] = [pb]
    } else if (country && Array.isArray(acc[country])) {
      acc[country].push(pb)
    }

    return acc
  }, initialValue)
}

export { sortProgramBenefitsByCountry }
