import { ProgramBenefitCountry } from 'data/types/graphql-global-types'
import { useState, useEffect } from 'react'
import { getCurrencySymbol, getCurrencyForCountry, DEFAULT_CURRENCY } from 'utils'

const useCurrencyForCountry = (country: ProgramBenefitCountry | null) => {
  const countryCurrency = getCurrencyForCountry(country) || DEFAULT_CURRENCY
  const [currency, setCurrency] = useState(countryCurrency)

  useEffect(() => {
    if (country) {
      setCurrency(getCurrencyForCountry(country))
    }
  }, [country])

  return {
    currency,
    currencySymbol: getCurrencySymbol(currency),
    setCurrency,
  }
}

export { useCurrencyForCountry }
