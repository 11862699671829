import { insightsSharedCopy } from 'ui/copy/insightsSharedCopy'

const translation = {
  appName: 'OneWellness Admin',
  loading: 'Loading',
  login: 'SSO Login',
  logout: 'Log Out',
  logging_out: 'Logging you out',
  name: 'Name',
  email: 'Email',
  not_found: 'Not found',
  not_found_main: 'That page could not be found.',
  not_found_support:
    '<0>Go back</0>, make sure you are logged, or try refreshing the page.',
  united_states: 'United States',
  united_kingdom: 'United Kingdom',
  australia: 'Australia',
  canada: 'Canada',
  germany: 'Germany',
  downtime_page: {
    title: 'Our site is temporarily down for maintenance.',
    body: 'We are undergoing scheduled maintenance and will be back online shortly.',
  },
  table_columns: {
    partnerName: 'Partner Name',
    type: 'Report Type',
    startDate: 'Report date',
    rowCount: 'Row count',
    approved: 'Approval Status',
    amountPayable: 'Amount Payable',
    taxPayable: 'Tax Payable',
    fullName: 'Full Name',
    emailAddress: 'Email Address',
    name: 'Partner Name',
    salesforceCustomerId: 'Salesforce ID',
  },
  insights: {
    ...insightsSharedCopy,
    select_partner: 'Select Partner',
    make_selection: 'Select a partner to view Insights data',
  },
  partners: {
    title: 'Partners',
    no_partners: 'No partners found.',
    no_matching_partners:
      'No partners found matching "{{search}}" search. Try searching for a different name.',
    name: 'Name',
    salesforce_customer_id: 'Salesforce ID',
    account_manager: 'Account Manager',
    enrolled: 'Enrolled',
    setup_status: 'Setup Status',
    search_by_name: 'Search by partner name',
    sort_by: 'Sort by',
    sort_name_asc: 'Partner Name A-Z',
    sort_name_desc: 'Partner Name Z-A',
  },
  partnerTransferUsers: {
    sftp_partner_transfer_users: 'SFTP Partner Transfer Users',
    ssh_keys_title: 'SSH Keys',
    ssh_public_key_support:
      'User name must be saved in User Details section before you can add SSH keys.',
    add_another_ssh_key: 'Add another SSH key',
    remove: 'Remove',
    sftp_usernames: 'SFTP Usernames',
    notes: 'Notes',
    notes_placeholder: 'Notes, technical contact info, etc.',
    save_sftp: 'Save SFTP Details',
    username: 'User name',
    username_required: 'User name *',
    username_locked:
      'The SFTP user name cannot be changed after the user has been created.',
    username_helptext:
      "The following are valid username characters: a-z, A-Z, 0-9, underscore '_', hyphen '-', period '.', and at sign '@'. The user name can't start with a hyphen, period, or at sign.",
    section_title: 'SFTP Users (not required)',
    transfer_user_section_title: 'User Details',
    ssh_public_key_body: 'SSH Public Key',
    add_another_partner_transfer_user: 'Add another partner transfer user',
    remove_partner_transfer_user: 'Remove Partner Transfer User',
    undo: 'Undo',
    create_failed: 'Failed to create partner transfer user.',
    create_succeeded: 'Partner transfer user has been saved',
    update_failed: 'Failed to update partner transfer user.',
    update_succeeded: 'Partner transfer user has been updated',
    delete_succeeded_one: 'Partner Transfer User deleted.',
    delete_succeeded_other: '{{count}} partner transfer users deleted.',
    delete_failed_one: 'Partner Transfer User could not be deleted.',
    delete_failed_other: '{{count}} partner transfer users could not be deleted.',
    errors: {
      required: '{{name}} is required.',
      maxLength: '{{name}} cannot exceed {{maxLength}} characters.',
      generic: 'The {{name}} field contains an error.',
      pattern: '{{name}} must have valid format.{{ details }}',
      username_format_details:
        "The following are valid characters: a-z, A-Z, 0-9, underscore '_', hyphen '-', period '.', and '@'. The user name can't start with a hyphen, period, or at sign.",
    },
  },
  transferUserKeys: {
    section_title: 'Transfer User Keys',
    ssh_public_key: 'SSH Public Key',
    delete_ssh_key: 'Schedule key for deletion',
    undelete_ssh_key: 'SSH Key scheduled for deletion. Click to undo',
    create_succeeded_one: 'SSH key has been saved.',
    create_succeeded_other: '{{count}} SSH keys saved.',
    create_failed_one: 'Failed to save SSH key.',
    create_failed_other: '{{count }} SSH keys failed to save.',
    delete_succeeded_one: 'SSH key has been deleted.',
    delete_succeeded_other: '{{count}} SSH keys deleted.',
    delete_failed_one: 'Failed to delete transfer user key.',
    delete_failed_other: '{{count }} transfer user keys could not be deleted.',
    errors: {
      required: '{{name}} is required.',
      pattern: '{{name}} must have valid format. {{ details }}',
      ssh_key_format_details: 'formatting details',
    },
  },
  partner: {
    partner: 'Partner',
    info: 'Partner Info',
    details: 'Partner Details',
    type: 'Type',
    partner_type: 'Partner Type',
    partner_type_error:
      'Partner has no partner type. Partner type is required to configure remaining partner details.',
    partner_type_rapid: 'Rapid Partner',
    partner_type_cw: 'Corporate Wellness',
    partner_type_locked: 'Partner type cannot be changed after partner has been created.',
    name: 'Partner Name',
    name_required: 'Partner Name *',
    program: 'Program',
    program_name: 'Program Name',
    program_name_required: 'Program Name *',
    program_benefits: 'Program Benefits',
    slug: 'Partner Slug',
    slug_required: 'Partner Slug *',
    identifier: 'Partner Identifier',
    identifier_required: 'Partner Identifier *',
    identifier_slug: 'Partner Identifier ("partner slug")',
    enrolled: 'Enrolled',
    salesforce_customer_id: 'Salesforce ID',
    salesforce_customer_id_required: 'Salesforce ID *',
    contact_info: 'Contact Info',
    no_contacts: 'No contacts exist for this partner.',
    contact_info_subtext: 'At least one contact is required per partner',
    email: 'Partner Email',
    enrollment: 'Enrollment',
    enrollment_tooltip:
      'When this box is checked, this partner will use the "magic link" welcome emails and the company name will be included in upcoming company lookup search results',
    enrollment_optimization_opt_in: 'Uses Optimized Enrollment',
    use_optimized_enrollment: 'Use Optimized Enrollment',
    first_name: 'First Name',
    first_name_label: 'First name',
    first_name_required: 'First name *',
    last_name: 'Last Name',
    last_name_label: 'Last name',
    last_name_required: 'Last name *',
    email_label: 'Email',
    email_required: 'Email *',
    sftp_notification: 'Receives SFTP Notifications',
    back_to_partners: 'Back to Partners',
    add_partner: 'Add Partner',
    add_another_contact: 'Add Another Contact',
    add_another_domain: 'Add Another Domain',
    create_partner: 'Create Partner',
    save_and_continue: 'Save & Continue',
    update_partner: 'Edit Partner',
    update_details_title: '1. Partner Details',
    update_eligibility_title: '2. Eligibility',
    update_emails_title: '3. Email Configuration',
    update_benefits_title: '4. Benefit Configuration',
    benefit: 'Benefit',
    benefits: 'Benefits',
    eligibility: 'Eligibility',
    view_partner_details: 'View Partner Details',
    create_succeeded: '{{name}} has been saved',
    create_failed: 'Failed to create partner. {{error}}',
    update_succeeded: '{{name}} has been updated',
    update_failed: 'Failed to update partner. {{error}}',
    create_contacts_succeeded_one: 'Contact created for {{name}}.',
    create_contacts_succeeded_other: '{{count}} contacts created for {{name}}.',
    create_contacts_failed_one: 'Failed to create contact for {{name}}.',
    create_contacts_failed_other: 'Failed to create {{count}} contacts for {{name}}.',
    update_contacts_succeeded_one: 'Contact updated for {{name}}.',
    update_contacts_succeeded_other: '{{count}} contacts updated for {{name}}.',
    update_contacts_failed_one: 'Contact could not be updated for {{name}}.',
    update_contacts_failed_other: '{{count}} contacts could not be updated for {{name}}.',
    delete_contacts_succeeded_one: 'Contact deleted.',
    delete_contacts_succeeded_other: '{{count}} contacts deleted.',
    delete_contacts_failed_one: 'Contact could not be deleted.',
    delete_contacts_failed_other: '{{count}} contacts could not be deleted.',
    create_program_benefits_succeeded_one: 'Program benefit created for {{name}}.',
    create_program_benefits_succeeded_other:
      '{{count}} program benefits created for {{name}}.',
    create_program_benefits_failed_generic: 'Failed to create program benefit.',
    create_program_benefits_failed_one: 'Failed to create program benefit for {{name}}.',
    create_program_benefits_failed_other:
      'Failed to create {{count}} program benefits for {{name}}',
    update_program_benefits_failed_one: 'Failed to update program benefit for {{name}}.',
    update_program_benefits_failed_other:
      '{{count}} program benefits failed to update for {{name}}.',
    update_program_benefits_failed_generic: 'Failed to update program benefit.',
    update_program_benefits_succeeded_one: 'Program benefit updated for {{name}}.',
    update_program_benefits_succeeded_other:
      '{{count}} program benefits updated for {{name}}.',
    remove: 'Remove',
    undo: 'Undo',
    field: 'Field',
    errors: {
      required: '{{name}} is required.',
      pattern: '{{name}} must have valid format.{{ details }}',
      isBlank: '{{name}} cannot be blank.',
      generic: 'The {{name}} field contains an error.',
      domain: 'Email domains must have valid format, e.g. example.com, abc.net',
      all_required: 'This field cannot be blank when other date parts are filled.',
      maxLength: '{{name}} cannot exceed {{maxLength}} characters.',
      price_format_details: ' e.g. $10.00',
      price_too_small: 'Non-zero price fields must be at least one dollar.',
      two_of_three_required: 'Please fill in two of the three pricing fields',
      active_pay_unavailable:
        'ActivePay is only eligible for benefits with a digital subscription and no user payment amount.',
      one_field_must_be_blank: 'One {{type}} field must be blank.',
      default_form_contains_data:
        'This form contains unsaved data. Please either add it to the list, or reset this form before attempting to save.',
      duplicate_country_benefit_combination:
        'This country and benefit combination already exists and cannot be duplicated.',
    },
    go_back: 'Go Back',
    eligibility_method: 'Eligibility Method',
    eligibility_method_required: 'Eligibility Method *',
    eligibility_method_dbo: 'DBO',
    eligibility_method_file: 'Eligibility File',
    eligibility_method_none: 'None',
    date_format: 'Date Format',
    date_format_required: 'Date Format *',
    dbo_domains: 'DBO Domains',
    dbo_domains_subtext:
      'At least one domain is required for partners with DBO eligibility method',
    domain_required: 'Domain *',
    email_configuration: 'Email Configuration',
    emails: 'Emails',
    welcome_email: 'Welcome Email',
    welcome_emails: 'Welcome Emails',
    welcome_email_date: 'Welcome Email Date',
    welcome_email_configure_prompt:
      'What date and time would you like to send your welcome email?',
    date: 'Date',
    time: 'Time',
    email_templates_fetch_error: 'Unable to load email templates.',
    benefits_fetch_error: 'Unable to load benefits.',
    welcome_email_template: 'Welcome Email Template',
    welcome_email_time: 'Time',
    welcome_email_timezone: 'Time zone',
    welcome_email_enabled: 'Welcome Email Enabled',
    email_select_template: 'Select the template for your {{type}} email.',
    reminder_email_template: 'Reminder Email Template',
    reminder_email: 'Reminder Email',
    reminder_emails: 'Reminder Emails',
    reminder_email_date: 'Reminder Email Date',
    reminder_email_label: 'Send email reminder after...',
    reminder_email_days: 'Reminder Email Days',
    reminder_email_enabled: 'Reminder Email Enabled',
    termination_date: 'Termination Date',
    partner_termination_date: 'Partner Termination Date',
    configure_details: 'Configure Details',
    select_template: 'Select template',
    turn_on_emails: 'Turn on {{type}} emails',
    reset: 'Reset',
    no: 'No',
    yes: 'Yes',
    program_details: 'Program Details',
    program_details_subtext: "Name the partner's program",
    active_pay: 'Active Pay',
    active_pay_duration: 'Active Pay Duration',
    active_pay_subtext: 'Select your ActivePay duration',
    add_benefit: 'Add A Benefit',
    add_another_benefit: 'Add Another Benefit',
    enable_active_pay: 'Enable ActivePay',
    select_active_pay_period: 'Select ActivePay period',
    select_benefit: 'Select benefit',
    select_benefit_subtext: 'Select a benefit from the dropdown list.',
    select_country: 'Select country',
    select_active_pay: 'Select ActivePay',
    no_active_pay: 'No ActivePay',
    three_months_active_pay: '3 Months ActivePay',
    six_months_active_pay: '6 Months ActivePay',
    program_created: 'Program created',
    program_updated: 'Program updated',
    program_create_failed: 'Failed to create program. {{error}}',
    program_update_failed: 'Failed to update program. {{error}}',
    list_of_added_benefits: 'List of added benefits',
    add: 'Add',
    update: 'Update',
    save: 'Save',
    continue: 'Continue',
    pricing: 'Pricing ({{currency}})',
    pricing_subtext:
      'Once 2 of the 3 pricing fields are complete, the third will calculate automatically.',
    partner_pays: 'Partner Pays',
    user_pays: 'User Pays',
    discount: 'Discount',
    undo_changes: 'Undo Changes',
    eligible_for_active_pay: 'Eligible for ActivePay?',
    no_program_benefits: 'None',
    additional_details: 'Additional Details',
    logo: 'Partner Logo (not required)',
    logo_subtext: 'Partner logo as displayed in the member onboarding header.',
    logo_upload_text: 'Drag and drop a PNG file or click here to select',
    logo_tooltip:
      'The uploaded logo will display below on a black background to preview how it will look on member onboarding. To see the raw upload, click the logo.',
    delete_logo: 'Delete Logo',
  },
  reporting: {
    title: 'Reporting',
    eel: 'Employee Enrollment List',
    eligibility_and_enrollment_check: 'Eligibility & Enrollment Check',
    month: 'Month',
    year: 'Year',
    generate_reconciliation_report: 'Generate Reconciliation Report',
    mark_all_approved: 'mark all approved',
    no_reports: 'No reports found for the month you selected.',
    download_error:
      'An error occured when trying to dowload this report. Please try again.',
    approval_error:
      'An error occured when trying to approve the selected reports. Please try again.',
  },
  reporting_check: {
    search_label: 'Search by Email or Employee Name',
    results_one: '{{count}} Result for "{{query}}"',
    results_other: '{{count}} Results for "{{query}}"',
    no_eligible_people: 'No eligible people found for "{{query}}".',
    back_to_results: 'Back to search results',
    employee_info: 'Employee Info',
    first_name: 'First Name',
    last_name: 'Last Name',
    eligibility_periods: 'Eligibility Periods',
    current_eligibility: 'Current Eligibility',
    is_claimed: 'Is Claimed?',
    cms_rider_links: 'CMS Rider Links',
    partner: 'Partner',
    sponsor: 'Sponsor',
    id: 'ID',
  },
  modals: {
    session_will_expire: 'Your session will expire in 60 seconds',
    session_will_expire_subtext: 'To continue your session, click "stay logged in"',
    stay_logged_in: 'STAY LOGGED IN',
    logging_in: 'Logging in...',
    logging_in_message: "We're logging you back in",
  },
  errors: {
    fetching_partners:
      'An error occured while trying to fetch the list of partners. Please try again',
  },
}

export { translation }
