import { useMutation } from '@apollo/client'
import {
  PartnerTransferUserDeleteVariables,
  PartnerTransferUserDelete,
} from 'data/mutations/types/PartnerTransferUserDelete'
import { DELETE_PARTNER_TRANSFER_USER } from '../mutations'
import { GET_PARTNER_TRANSFER_USERS } from '../queries'

type TID = {
  partnerId: string
}

const usePartnerTransferUserDelete = ({ partnerId }: TID) => {
  const [mutate, { loading, error }] = useMutation<PartnerTransferUserDelete>(
    DELETE_PARTNER_TRANSFER_USER,
    {
      refetchQueries: [{ query: GET_PARTNER_TRANSFER_USERS, variables: { partnerId } }],
    },
  )

  const deletePartnerTransferUser = ({
    partnerTransferUserId,
  }: PartnerTransferUserDeleteVariables) => {
    return mutate({
      variables: {
        partnerTransferUserId,
      },
    })
  }

  return { deletePartnerTransferUser, error, loading }
}

export { usePartnerTransferUserDelete }
