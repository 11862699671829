import { ApolloError } from '@apollo/client'
import {
  SingleSelect,
  LinkButton,
  Button,
  Tooltip,
  grey,
} from '@pelotoncycle/design-system'
import { usePartnerUpdate } from 'data/hooks'
import { Partner_partner as TPartner } from 'data/queries/types/Partner'
import { PartnerInput } from 'data/types/graphql-global-types'
import { useMemo, useState, useEffect, BaseSyntheticEvent } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useAlerts, AlertVariants, Box, Flex, SelectContainer } from 'ui/components'
import { deserializePartner, serializePartner, ELIGIBILITY_METHODS } from 'utils'
import {
  DateFormatSelect,
  DomainInputsSection,
  PartnerSlugInput2,
  PartnerEnrollmentOptimizationCheckbox,
  FormInput,
  DOMAIN_DEFAULT,
} from './Fields'
import { FormSection } from './FormSection'
import { StyledForm } from './StyledForm'
import { StyledFormActionsContainer } from './StyledFormActionsContainer'

type TEligibilityFormProps = {
  partner: TPartner
  previousStep: string
  onSuccess?: () => void
}

// Only submit relevant fields for given eligibilty method
const normalizeDataForSubmit = (dirtyFields: string[], input: FormInput) => {
  const initialValue = {} as Partial<
    Pick<PartnerInput, 'dateFormat' | 'domains' | 'slug' | 'enrollmentOptimization'>
  >

  return dirtyFields.reduce((acc, field) => {
    switch (field) {
      case 'dateFormat':
        acc.dateFormat = serializePartner.dateFormat(input.dateFormat)
        break
      case 'domains':
        acc.domains = serializePartner.domains(input.domains)
        break
      case 'slug':
        acc.slug = input[field]
        break
      case 'enrollmentOptimization':
        acc.enrollmentOptimization = input[field]
        break
      default:
        break
    }

    return acc
  }, initialValue)
}

const PartnerEligibilityForm = ({
  partner,
  onSuccess,
  previousStep,
}: TEligibilityFormProps) => {
  const { t } = useTranslation()
  const eligibilityMethodType = deserializePartner.eligibilityMethod(partner)
  const [eligibilityMethod, setEligibilityMethod] = useState(eligibilityMethodType)

  const partnerHasDomains = partner?.domains?.length
  const partnerHasDateFormat = partner?.dateFormat

  const { addAlert } = useAlerts()
  const { updatePartner, loading: updatePartnerLoading } = usePartnerUpdate({
    partnerId: partner.id,
  })

  const { name, slug, domains, dateFormat, enrollmentOptimization } = partner

  // react-hook-form requires objects for useFieldArray so it can add an id per entry for tracking/state
  const normalizedDomains = useMemo(
    () =>
      domains && domains.length ? domains.map(d => ({ domain: d })) : [DOMAIN_DEFAULT],
    [domains],
  )

  const { control, handleSubmit, formState, reset, trigger } = useForm<FormInput>({
    defaultValues: {
      slug,
      dateFormat,
      domains: normalizedDomains,
      enrollmentOptimization,
    },
  })

  const { isDirty, dirtyFields } = formState
  const isLoading = updatePartnerLoading
  const shouldDisableSubmit = isLoading || !isDirty

  const eligibilityMethodIsDBO = eligibilityMethod === ELIGIBILITY_METHODS.DBO
  const eligibilityMethodIsFile = eligibilityMethod === ELIGIBILITY_METHODS.FILE
  const eligibilityMethodIsNone = eligibilityMethod === ELIGIBILITY_METHODS.NONE

  useEffect(() => {
    // preserve the initial partner values when switching back to a prior eligibility method
    // to avoid values disappearing before a submit (e.g. persist domains between eligibility method toggling)
    reset({
      slug: slug || '',
      dateFormat,
      domains: normalizedDomains,
      enrollmentOptimization: Boolean(enrollmentOptimization),
    })
  }, [
    eligibilityMethod,
    reset,
    slug,
    normalizedDomains,
    dateFormat,
    enrollmentOptimization,
  ])

  const eligibilityMethodNames = Object.values(ELIGIBILITY_METHODS)

  const handleUpdatePartner: SubmitHandler<FormInput> = input => {
    const fieldsToUpdate = Object.keys(dirtyFields).filter(field =>
      Boolean(dirtyFields[field as keyof FormInput]),
    )
    const normalizedFields = normalizeDataForSubmit(fieldsToUpdate, input)

    // Because the eligibility method is derived from multiple field values, the client must
    // manage certain fields that conflict with new eligibility method
    // e.g. changing from DBO -> None requires FE to also remove any existing domains and dateFormat values
    // In order to persist these values in the form between changes to eligibility method, we have to manually unset those values here on submit
    const overrideFields: Partial<PartnerInput> = {}
    if (eligibilityMethodIsNone) {
      if (partnerHasDomains) overrideFields.domains = []
      if (partnerHasDateFormat) overrideFields.dateFormat = null
    } else if (eligibilityMethodIsFile && partner?.domains) {
      if (partnerHasDomains) overrideFields.domains = []
    } else if (eligibilityMethodIsDBO) {
      // domains must be included on any DBO update or backend will derive the eligibliity method as FILE
      overrideFields.domains = serializePartner.domains(input.domains)
    }

    const inputData = { ...normalizedFields, ...overrideFields }

    updatePartner({ input: inputData })
      .then(({ data: responseData }) => {
        if (responseData?.partnerUpdate?.requestOk) {
          addAlert({
            variant: AlertVariants.success,
            message: t('partner.update_succeeded', { name }),
            autoClose: 8000,
          })

          if (onSuccess) onSuccess()
        }
      })
      .catch(e => {
        let error = ''
        if (typeof e === 'string') error = e
        if (e instanceof ApolloError && typeof e.message === 'string') error = e.message
        addAlert({
          variant: AlertVariants.error,
          message: t('partner.update_failed', { error }),
          autoClose: 8000,
        })
      })
  }

  const submitForm = (e: BaseSyntheticEvent) => {
    handleSubmit(handleUpdatePartner)(e).catch(err => {
      /* eslint-disable-next-line */
      console.log('err ==>', err)
    })
  }

  let formFields = null

  if (eligibilityMethodIsDBO) {
    formFields = (
      <>
        <DateFormatSelect control={control} />

        <Box marginTop="24px">
          <FormSection
            title={t('partner.dbo_domains')}
            subtext={t('partner.dbo_domains_subtext')}
          >
            <DomainInputsSection control={control} trigger={trigger} />
          </FormSection>
        </Box>
      </>
    )
  } else if (eligibilityMethodIsFile) {
    formFields = <DateFormatSelect control={control} />
  } else if (eligibilityMethodIsNone) {
    formFields = <PartnerSlugInput2 control={control} />
  }

  return (
    <StyledForm data-testid="eligibility-form">
      <form onSubmit={submitForm}>
        <FormSection title={t('partner.enrollment')}>
          <Flex alignItems="center">
            <PartnerEnrollmentOptimizationCheckbox control={control} />
            <Tooltip textColor={grey[80]} margin="0 8px" position="right">
              {t('partner.enrollment_tooltip')}
            </Tooltip>
          </Flex>
        </FormSection>
        <FormSection title={t('partner.eligibility')}>
          <SelectContainer data-testid="eligibilityMethodSelect">
            <SingleSelect
              label={t('partner.eligibility_method_required')}
              items={eligibilityMethodNames}
              selectedItem={eligibilityMethod}
              handleSelectedItemChange={setEligibilityMethod}
            />
          </SelectContainer>

          {formFields}
        </FormSection>

        <StyledFormActionsContainer>
          <Box width="126px" marginRight="16px">
            <LinkButton
              color="dark"
              variant="outline"
              component={Link}
              to={previousStep}
              text={t('partner.go_back')}
              width="adaptive"
            />
          </Box>
          <Box width="126px">
            <Button
              type="submit"
              data-testid="partnerEligibilityUpdate"
              width="adaptive"
              isDisabled={shouldDisableSubmit}
            >
              {t('partner.save_and_continue')}
            </Button>
          </Box>
        </StyledFormActionsContainer>
      </form>
    </StyledForm>
  )
}

export { PartnerEligibilityForm }
