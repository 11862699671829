import { black } from '@pelotoncycle/design-system'
import { IconProps } from './IconTypes'

const Clock = ({ width = '20px', height = '20px', fill = black }: IconProps) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill={fill}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10ZM10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0ZM10.5 3.5C10.5 3.22386 10.2761 3 10 3C9.72386 3 9.5 3.22386 9.5 3.5V10.5C9.5 10.7761 9.72386 11 10 11H15C15.2761 11 15.5 10.7761 15.5 10.5C15.5 10.2239 15.2761 10 15 10H10.5V3.5Z"
      fill={fill}
    />
  </svg>
)

export { Clock }
