import { useMutation } from '@apollo/client'
import {
  TransferUserKeyCreate,
  TransferUserKeyCreateVariables,
} from 'data/mutations/types/TransferUserKeyCreate'
import { CREATE_TRANSFER_USER_KEY } from '../mutations'
import { GET_PARTNER_TRANSFER_USERS } from '../queries'

type TID = {
  partnerId: string
}

const useTransferUserKeyCreate = ({ partnerId }: TID) => {
  const [mutate, { loading, error }] = useMutation<TransferUserKeyCreate>(
    CREATE_TRANSFER_USER_KEY,
    {
      refetchQueries: [{ query: GET_PARTNER_TRANSFER_USERS, variables: { partnerId } }],
    },
  )

  const createTransferUserKey = ({
    transferUserKeyInput,
  }: TransferUserKeyCreateVariables) => {
    return mutate({
      variables: {
        transferUserKeyInput,
      },
    })
  }

  return { createTransferUserKey, error, loading }
}

export { useTransferUserKeyCreate }
