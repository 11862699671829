import { useMutation } from '@apollo/client'
import {
  TransferUserKeyDelete_transferUserKeyDelete as TransferUserKeyDelete,
  TransferUserKeyDeleteVariables,
} from 'data/mutations/types/TransferUserKeyDelete'
import { DELETE_TRANSFER_USER_KEY } from '../mutations'
import { GET_PARTNER_TRANSFER_USERS } from '../queries'

type TID = {
  partnerId: string
}

// this should probably require partnerId
const useTransferUserKeyDelete = ({ partnerId }: TID) => {
  const [mutate, { loading, error }] = useMutation<TransferUserKeyDelete>(
    DELETE_TRANSFER_USER_KEY,
    {
      refetchQueries: [{ query: GET_PARTNER_TRANSFER_USERS, variables: { partnerId } }],
    },
  )

  const deleteTransferUserKey = ({
    transferUserKeyId,
  }: TransferUserKeyDeleteVariables) => {
    return mutate({
      variables: {
        transferUserKeyId,
      },
    })
  }

  return { deleteTransferUserKey, error, loading }
}

export { useTransferUserKeyDelete }
