import { grey } from '@pelotoncycle/design-system'
import styled from 'styled-components'

const StyledFormActionsContainer = styled.div`
  display: flex;
  padding: 32px 0;
  border-top: 1px solid ${grey[50]};
  justify-content: flex-end;
`

export { StyledFormActionsContainer }
