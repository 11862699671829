import { useAuth0 } from '@auth0/auth0-react'
import { useDecision } from '@optimizely/react-sdk'
import React, { useEffect, useState } from 'react'
import { useInterval } from 'react-use'
import {
  Screen,
  LoadingScreen,
  AuthError,
  AlertDisplay,
  AlertProvider,
  OptimizelyProvider,
  DowntimeScreen,
} from 'ui/components'
import { Header } from 'ui/components/@admin'
import { LogoutWarningModal } from 'ui/components/Modals'
import { useInputChange } from 'ui/hooks'
import { SHOW_DOWNTIME } from 'utils'
import { GlobalStyle } from './GlobalStyle'
import 'i18n/config'
import { Routes } from './Routes'

// after 118 minutes (2hrs minus token refresh buffer), attempt to refresh user's creds
const tokenExpInMs = 7.2e6 // 2 hr
// leave enough time to perform token refresh before expiration. auth0 will error
// if refresh attempt occurs within 60 seconds of exp
const tokenRefreshBuffer = 120000 // 2 min

const tokenInterval = tokenExpInMs - tokenRefreshBuffer

const Content = () => {
  const [decision] = useDecision(SHOW_DOWNTIME)

  if (decision.enabled) {
    return <DowntimeScreen isAdmin />
  }

  return (
    <>
      <Header />
      <AlertDisplay />
      <Routes />
    </>
  )
}

const App = () => {
  useInputChange()
  const [showLogoutModal, setShowLogoutModal] = useState(false)

  const { isLoading, getAccessTokenSilently, isAuthenticated, error } = useAuth0()

  useEffect(() => {
    if (isAuthenticated) {
      document.title = '1W Admin'
    } else {
      document.title = '1W Admin - Log in'
    }
  }, [isAuthenticated])

  // after user authenticates, set an interval to try to 'refresh' access token (will only work for chrome users)
  useInterval(
    () => {
      // ignoreCache must be true to fetch a fresh token
      getAccessTokenSilently({ ignoreCache: true }).catch(e => {
        /* eslint-disable-next-line no-console */
        console.log('error fetching remote token ==>', e)
      })
    },
    // only start the interval for logged-in users
    isAuthenticated ? tokenInterval : null,
  )

  if (isLoading) return <LoadingScreen />
  if (error) return <AuthError error={error} />

  return (
    <OptimizelyProvider>
      <AlertProvider>
        <GlobalStyle />
        <Screen>
          <Content />
        </Screen>
        <LogoutWarningModal
          isOpen={showLogoutModal}
          handleClose={() => setShowLogoutModal(false)}
        />
      </AlertProvider>
    </OptimizelyProvider>
  )
}

export { App }
