import { ApolloError } from '@apollo/client'
import { usePartner } from 'data/hooks'
import { Partner_partner as TPartner } from 'data/queries/types/Partner'
import { Audiences } from 'data/types/graphql-global-types'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import { TStep } from 'ui/components'
import { isRapidPartner, dateFormatExists } from 'utils'
import { PARTNERS_ROUTE } from 'utils/constants/admin'

type RouteParams = {
  partnerId: string
}

type TContext = {
  partner?: TPartner | null
  loading: boolean
  error?: ApolloError
  steps: TStep[]
  getPathForPreviousStep: () => string
  getPathForNextStep: () => string
  refetch: () => void
}

type TProviderProps = {
  children: React.ReactNode
}

const getRequiredDetails = (partner: TPartner | null | undefined) => {
  if (!partner) return false

  const { partnerType, name, slug, salesforceCustomerId } = partner

  if (partnerType === Audiences.partnership) {
    return !!name && !!slug
  }
  if (partnerType === Audiences.corporate) {
    return !!name && !!salesforceCustomerId
  }

  return false
}

const getRequiredEligibility = (partner: TPartner | null | undefined) => {
  if (!partner) return false

  const { partnerType, slug, dateFormat, domains, domainOnboarding } = partner

  // rapid partners require no additional eligibility data
  if (partnerType === Audiences.partnership) return true

  // DBO
  if (domainOnboarding) {
    return !!(dateFormat && domains && domains.length)
  }

  // Eligibility File || None
  return dateFormatExists(partner?.dateFormat) || !!slug
}

const getRequiredEmails = (partner: TPartner | null | undefined) => {
  const welcomeEmailEnabled = !partner?.overrideWelcomeEmail
  const reminderEmailEnabled = !partner?.overrideReminderEmail

  // if partner opts out of emails, this step is complete
  let hasRequiredWelcomeEmailData = true
  let hasRequiredReminderEmailData = true

  if (welcomeEmailEnabled) {
    hasRequiredWelcomeEmailData = !!(
      partner?.welcomeEmailTemplate && partner?.welcomeEmailDate
    )
  }

  if (reminderEmailEnabled) {
    hasRequiredReminderEmailData = !!(
      partner?.reminderEmailTemplate &&
      partner?.reminderEmailDays &&
      partner?.reminderEmailDays > 0
    )
  }

  return hasRequiredWelcomeEmailData && hasRequiredReminderEmailData
}
const getRequiredBenefits = (partner: TPartner | null | undefined) => {
  return !!partner?.program?.id
}

const PartnerUpdateContext = React.createContext<TContext | undefined>(undefined)

const PartnerUpdateProvider = ({ children }: TProviderProps) => {
  const { partnerId } = useParams<RouteParams>()
  const { data, loading, error, refetch } = usePartner({ partnerId })

  const partner = data?.partner

  const DETAIL_STEP = {
    name: 'detail',
    path: `${PARTNERS_ROUTE}/${partnerId}/update/details`,
    complete: getRequiredDetails(partner),
  }
  const ELIGIBILITY_STEP = {
    name: 'eligibility',
    path: `${PARTNERS_ROUTE}/${partnerId}/update/eligibility`,
    complete: getRequiredEligibility(partner),
  }
  const EMAIL_STEP = {
    name: 'emails',
    path: `${PARTNERS_ROUTE}/${partnerId}/update/emails`,
    complete: getRequiredEmails(partner),
  }
  const BENEFITS_STEP = {
    name: 'benefits',
    path: `${PARTNERS_ROUTE}/${partnerId}/update/benefits`,
    complete: getRequiredBenefits(partner),
  }

  const corporatePartnerSteps = [DETAIL_STEP, ELIGIBILITY_STEP, EMAIL_STEP, BENEFITS_STEP]
  const rapidPartnerSteps = [DETAIL_STEP, BENEFITS_STEP]

  const isRapidPartnerType = isRapidPartner(partner)

  const steps = isRapidPartnerType ? rapidPartnerSteps : corporatePartnerSteps

  const getPathForPreviousStep = React.useCallback(() => {
    const indexOfCurrentPath = steps.findIndex(
      step => step.path === window.location.pathname,
    )

    const previousStep = steps[indexOfCurrentPath - 1]
    if (previousStep) return previousStep.path

    // if no previous step (i.e. at first step), push to partner details page
    return `${PARTNERS_ROUTE}/${partnerId}`
  }, [partnerId, steps])

  const getPathForNextStep = React.useCallback(() => {
    const indexOfCurrentPath = steps.findIndex(
      step => step.path === window.location.pathname,
    )
    const nextStep = steps[indexOfCurrentPath + 1]
    if (nextStep) return nextStep.path

    // if no next step (i.e. at last step), push to partner details page

    return `${PARTNERS_ROUTE}/${partnerId}`
  }, [partnerId, steps])

  const context = React.useMemo(
    () => ({
      partner,
      refetch,
      loading,
      error,
      steps,
      getPathForNextStep,
      getPathForPreviousStep,
    }),
    [partner, refetch, loading, error, steps, getPathForNextStep, getPathForPreviousStep],
  )

  return (
    <PartnerUpdateContext.Provider value={context}>
      {children}
    </PartnerUpdateContext.Provider>
  )
}

export { PartnerUpdateContext, PartnerUpdateProvider }
