import { useQuery } from '@apollo/client'
import { Partner } from 'data/queries/types/Partner'
import { GET_PARTNER } from '../queries'

type TPartnerProps = {
  partnerId: string
}

const usePartner = ({ partnerId }: TPartnerProps) => {
  const { loading, error, data, refetch } = useQuery<Partner>(GET_PARTNER, {
    variables: { id: partnerId },
  })

  return {
    loading,
    error,
    data,
    refetch,
  }
}

export { usePartner }
