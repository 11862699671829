import { ApolloError } from '@apollo/client'
import { Button } from '@pelotoncycle/design-system'
import {
  usePartnerUpdate,
  usePartnerContactCreate,
  usePartnerContactUpdate,
  usePartnerContactDelete,
} from 'data/hooks'
import { PartnerContactUpdate_contactUpdate_contact as TPartnerContact } from 'data/mutations/types/PartnerContactUpdate'
import { Partner_partner as TPartner } from 'data/queries/types/Partner'
import { Audiences, PartnerInput } from 'data/types/graphql-global-types'
import { BaseSyntheticEvent, useState, useRef } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useAlerts, AlertVariants } from 'ui/components'
import { useHandlePromises } from 'ui/hooks'
import { TPartnerContactVariables, TPartnerContacts } from './Fields'
import { PartnerContactsForm } from './PartnerContactsForm'
import { PartnerForm } from './PartnerForm'
import { StyledForm } from './StyledForm'
import { StyledFormActionsContainer } from './StyledFormActionsContainer'

type TPartnerUpdateFormProps = {
  partner: TPartner
  onSuccess: () => void
}

const PartnerUpdateForm = ({ partner, onSuccess }: TPartnerUpdateFormProps) => {
  const { t } = useTranslation()
  const { handlePromises } = useHandlePromises()
  const { addAlert } = useAlerts()
  const [contactIdsForDelete, setContactIdsForDelete] = useState<string[]>([])

  const {
    id: partnerId,
    name: partnerName,
    partnerType,
    slug,
    salesforceCustomerId,
    contacts,
  } = partner

  const partnerContacts = contacts || []
  const { createPartnerContact, loading: createContactLoading } =
    usePartnerContactCreate()
  const { updatePartnerContact, loading: updateContactLoading } = usePartnerContactUpdate(
    { partnerId },
  )
  const { deletePartnerContact, loading: deleteContactLoading } = usePartnerContactDelete(
    { partnerId },
  )

  // allow-list partner form fields -- exclude typename, id, and contacts
  const partnerFormFields = {
    name: partnerName,
    partnerType,
    slug,
    salesforceCustomerId,
  }

  const {
    control,
    handleSubmit,
    formState: partnerFormState,
    trigger,
  } = useForm<PartnerInput>({
    defaultValues: { ...partnerFormFields },
  })
  const { isDirty: partnerIsDirty, dirtyFields: partnerDirtyFields } = partnerFormState

  const {
    control: contactsControl,
    trigger: contactsTrigger,
    formState: contactsFormState,
    getValues: getContactsValues,
  } = useForm<TPartnerContacts>({
    defaultValues: { contacts: partnerContacts },
  })
  const { isDirty: contactsIsDirty, dirtyFields: contactsDirtyFields } = contactsFormState

  const partnerFormEl = useRef<HTMLFormElement>(null)

  const isCorporatePartnerType = partnerType === Audiences.corporate

  const { updatePartner, loading: updatePartnerLoading } = usePartnerUpdate({ partnerId })

  const isDirty = partnerIsDirty || contactsIsDirty

  const hasContactsToDelete = !!contactIdsForDelete.length
  const isLoading =
    updatePartnerLoading ||
    updateContactLoading ||
    deleteContactLoading ||
    createContactLoading

  const shouldDisableSubmit = (!hasContactsToDelete && !isDirty) || isLoading

  const handleRemoveContact = (id: string) => {
    setContactIdsForDelete(prevState => [...prevState, id])
  }

  const handleUnremoveContact = (id: string) => {
    setContactIdsForDelete(prevState => prevState.filter(item => item !== id))
  }

  const handleDeleteContacts = () => {
    const promises = contactIdsForDelete.map(id => {
      return deletePartnerContact({ contactId: id })
    })

    handlePromises({
      promises,
      errorMessageTranslationKey: 'partner.delete_contacts_failed',
      successMessageTranslationKey: 'partner.delete_contacts_succeeded',
      handleMessages: addAlert,
      handleMessagesVariables: { name: partnerName },
      onSuccess,
    })
  }

  const handleChangeContacts = () => {
    const { contacts: contactFields } = getContactsValues()
    const dirtyContacts = contactsDirtyFields && contactsDirtyFields?.contacts
    if (!dirtyContacts) return

    const initialValue = { creates: [], updates: [] } as {
      creates: Partial<TPartnerContactVariables>[]
      updates: Partial<TPartnerContact>[]
    }

    const recordsToCommit = dirtyContacts.reduce((acc, entry, index) => {
      const contact = contactFields[index]
      if (contact) {
        const hasDirtyFields = Object.keys(entry).some(
          k => !!entry[k as keyof TPartnerContactVariables],
        )

        // determine whether the contact in the form is new (requires create)
        // or existing (has id, requires update)
        if (hasDirtyFields) {
          if (contact?.id) {
            acc.updates.push(contact)
          } else {
            acc.creates.push(contact)
          }
        }
      }

      return acc
    }, initialValue)

    // --- UPDATES ---
    const updatePromises = recordsToCommit.updates.map(contact => {
      /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
      const { __typename, id: contactId, ...rest }: Partial<TPartnerContact> = contact

      if (!contactId) return Promise.reject()

      return updatePartnerContact({ input: { ...rest }, contactId })
    })

    handlePromises({
      promises: updatePromises,
      errorMessageTranslationKey: 'partner.update_contacts_failed',
      successMessageTranslationKey: 'partner.update_contacts_succeeded',
      handleMessages: addAlert,
      handleMessagesVariables: { name: partnerName },
      onSuccess,
    })

    // --- CREATES ---
    const createPromises = recordsToCommit.creates.map(contact => {
      /* eslint-disable @typescript-eslint/no-unused-vars */
      const { __typename, id: contactId, ...rest }: Partial<TPartnerContact> = contact

      return createPartnerContact({ input: { ...rest }, partnerId })
    })

    handlePromises({
      promises: createPromises,
      errorMessageTranslationKey: 'partner.partner.create_contacts_failed',
      successMessageTranslationKey: 'partner.create_contacts_succeeded',
      handleMessages: addAlert,
      handleMessagesVariables: { name: partnerName },
      onSuccess,
    })
  }

  const handlePartnerSubmit: SubmitHandler<PartnerInput> = input => {
    if (partnerIsDirty) {
      const initialValue = {} as { [key: string]: unknown }
      const updateData: Partial<PartnerInput> = Object.keys(partnerDirtyFields).reduce(
        (acc, key) => {
          acc[key] = input[key as keyof typeof input]

          return acc
        },
        initialValue,
      )

      // Update partner
      updatePartner({ input: updateData })
        .then(({ data: responseData }) => {
          if (responseData?.partnerUpdate?.requestOk) {
            addAlert({
              variant: AlertVariants.success,
              message: t('partner.update_succeeded', { name: partnerName }),
              autoClose: 8000,
            })

            if (onSuccess) onSuccess()
          }
        })
        .catch(e => {
          let error = ''
          if (typeof e === 'string') error = e
          if (e instanceof ApolloError && typeof e.message === 'string') error = e.message
          addAlert({
            variant: AlertVariants.error,
            message: t('partner.update_failed', { error }),
            autoClose: 8000,
          })
        })
    }

    // Create or update contacts
    if (isCorporatePartnerType && contactsIsDirty) {
      handleChangeContacts()
    }

    // Delete contacts
    if (hasContactsToDelete) {
      handleDeleteContacts()
    }
  }

  const submitPartner = (e: BaseSyntheticEvent) => {
    // handleSubmit returns a promise, and onSubmit needs a void return
    handleSubmit(handlePartnerSubmit)(e).catch(err => {
      /* eslint-disable-next-line */
      console.log('err ==>', err)
    })
  }

  const handleParnterFormSubmit = async () => {
    if (partnerFormEl.current) {
      // trigger validations manually on both forms - isValid getter from react-hook-form is unreliable/wrong
      const partnerFormValid = await trigger()
      const contactsFormValid = await contactsTrigger()
      if (partnerFormValid && contactsFormValid) {
        partnerFormEl.current.dispatchEvent(
          new Event('submit', { cancelable: true, bubbles: true }),
        )
      }
    }
  }

  const submitPartnerAndContacts = () => {
    handleParnterFormSubmit().catch(e => {
      /* eslint-disable-next-line */
      console.log('e ==>', e)
    })
  }

  return (
    <StyledForm data-testid="partner-details-form">
      <PartnerForm
        ref={partnerFormEl}
        onSubmit={submitPartner}
        control={control}
        isDisabled={!partnerIsDirty}
        partnerType={partner.partnerType}
        buttonText={t('partner.save_and_continue')}
      />

      {isCorporatePartnerType && (
        <>
          <PartnerContactsForm
            control={contactsControl}
            trigger={contactsTrigger}
            getValues={getContactsValues}
            onRemoveContact={handleRemoveContact}
            onUnremoveContact={handleUnremoveContact}
          />

          <StyledFormActionsContainer>
            <Button
              data-testid="partnerContactUpdate"
              isDisabled={shouldDisableSubmit}
              onClick={submitPartnerAndContacts}
            >
              {t('partner.save_and_continue')}
            </Button>
          </StyledFormActionsContainer>
        </>
      )}
    </StyledForm>
  )
}

export { PartnerUpdateForm }
