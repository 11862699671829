import styled from 'styled-components'

const StyledForm = styled.div`
  width: 100%;

  div[class^='StyledWrapper'] {
    margin: 16px 0;
  }
`

export { StyledForm }
