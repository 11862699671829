import { ApolloError } from '@apollo/client'
import { useProgramUpdate } from 'data/hooks'
import { Partner_partner as TPartner } from 'data/queries/types/Partner'
import { ProgramInput } from 'data/types/graphql-global-types'
import { BaseSyntheticEvent } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useAlerts, AlertVariants } from 'ui/components'
import { ProgramForm } from './ProgramForm'

type TProgramFormProps = {
  program: NonNullable<TPartner['program']>
  partner: TPartner
  onSuccess?: () => void
}

const ProgramUpdateForm = ({ program, partner, onSuccess }: TProgramFormProps) => {
  const { t } = useTranslation()
  const { addAlert } = useAlerts()
  const { id: partnerId, hashId: partnerHashId } = partner
  const programId = program.id
  const { updateProgram, loading } = useProgramUpdate({ partnerId })

  const { name, monthsInactiveUntilSnooze } = program

  const { control, formState, handleSubmit } = useForm<ProgramInput>({
    defaultValues: {
      name,
      monthsInactiveUntilSnooze,
      partnerHashId,
    },
  })
  const { isDirty } = formState

  const shouldDisableSubmit = loading || !isDirty

  const handleProgramSubmit: SubmitHandler<ProgramInput> = input => {
    updateProgram({ input, programId })
      .then(({ data: responseData }) => {
        if (responseData?.programUpdate?.requestOk) {
          addAlert({
            variant: AlertVariants.success,
            message: t('partner.program_updated'),
            autoClose: 8000,
          })
          if (onSuccess) onSuccess()
        }
      })
      .catch(e => {
        let error = ''
        if (typeof e === 'string') error = e
        if (e instanceof ApolloError && typeof e.message === 'string') error = e.message
        addAlert({
          variant: AlertVariants.error,
          message: t('partner.update_failed', { error }),
          autoClose: 8000,
        })
      })
  }

  const submitForm = (e: BaseSyntheticEvent) => {
    handleSubmit(handleProgramSubmit)(e).catch(err => {
      /* eslint-disable-next-line */
      console.log('err ==>', err)
    })
  }

  return (
    <ProgramForm
      control={control}
      onSubmit={submitForm}
      isDisabled={shouldDisableSubmit}
      isEdit
    />
  )
}

export { ProgramUpdateForm }
