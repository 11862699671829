import { ApolloError } from '@apollo/client'
import {
  Eyebrow,
  Headline,
  Button,
  LinkButton,
  grey,
  red,
} from '@pelotoncycle/design-system'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  useAccordionItemContext,
} from '@reach/accordion'
import { useBenefits, useProgramBenefitUpdate, useProgramBenefitCreate } from 'data/hooks'
import {
  Partner_partner_program as TProgram,
  Partner_partner as TPartner,
  Partner_partner_program_programBenefits as TProgramBenefit,
} from 'data/queries/types/Partner'
import {
  ProgramBenefitInput,
  ProgramBenefitCountry,
} from 'data/types/graphql-global-types'
import { useState, useEffect, useMemo, BaseSyntheticEvent } from 'react'
import {
  useForm,
  useFieldArray,
  useWatch,
  SubmitHandler,
  FieldError,
  Control,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Box, AccordionChevron, useAlerts } from 'ui/components'
import { usePartnerUpdateContext } from 'ui/components/PartnerUpdateContext'
import { useHandlePromises } from 'ui/hooks'
import { getAltBenefitName } from 'utils'
import { PARTNERS_ROUTE } from 'utils/constants/admin'
import { TProgramBenefits, TProgramBenefitVariables } from './Fields'
import { ProgramBenefitForm } from './ProgramBenefitForm'
import { StyledFormActionsContainer } from './StyledFormActionsContainer'

type TProgramBenefitsFormProps = {
  program: TProgram
  partner: TPartner
}

type TProgramBenefitInput = Omit<ProgramBenefitInput, 'eligibleForActivePay'> & {
  eligibleForActivePay?: boolean
  id: string
}

type TIndexableProgramBenefit = TProgramBenefit & { [key: string]: unknown }
type TPanelHeaderProps = {
  control: Control<TProgramBenefits>
  index: number
}

const ListItemHeader = ({ control, index }: TPanelHeaderProps) => {
  const { t } = useTranslation()
  const { data: benefitsData } = useBenefits()
  const { isExpanded } = useAccordionItemContext()
  // using watch is the only way to stay up to date with changes to these values
  const [watchBenefitId, watchCountry] = useWatch({
    control,
    name: [`programBenefits.${index}.benefit.id`, `programBenefits.${index}.country`],
  })
  const benefits = benefitsData?.benefits || []
  const getBenefitById = benefits.find(benefit => benefit.id === watchBenefitId)
  const benefitName = getBenefitById ? getBenefitById?.name : t('partner.benefit')

  return (
    <StyledPanelHeader>
      <Eyebrow size="large">
        {getAltBenefitName(benefitName)} - {watchCountry}
      </Eyebrow>
      <AccordionButton>
        <AccordionChevron isExpanded={isExpanded} />
      </AccordionButton>
    </StyledPanelHeader>
  )
}

// prettier-ignore
const useToggleListItems = <T,>(onToggleCallback: (arg: T) => Promise<boolean>) => {
  const [indices, setIndices] = useState<number[]>([])

  // when toggling via click, only allow single accordion item to be open
  const toggleListItem = (
    itemIndex: number,
  ) => {
    // validate any open forms before they're closed
    if (!!indices.length && typeof onToggleCallback === 'function') {
      indices.forEach(i => {
        onToggleCallback(`programBenefits.${i}` as unknown as T).catch(e => {
          /* eslint-disable-next-line */
          console.log('- ERR ->', e)
        })
      })
    }

    const itemIndexIsOpen = indices.includes(itemIndex)
    const itemsToOpen = itemIndexIsOpen ? [] : [itemIndex]
    setIndices(itemsToOpen)
  }

  const closeAllListItems = () => {
    setIndices([])
  }

  const openListemItemsInclusive = (itemIndex: number) => {
    setIndices(prevIndices => {
      const previous = [...prevIndices]
      if (previous.indexOf(itemIndex) === -1) {
        previous.push(itemIndex)
      }

      return previous.sort()
    })
  }

  const openListItemsExclusive = (itemIndices: number[]) => {
    setIndices(itemIndices)
  }

  return {
    indices,
    setIndices,
    toggleListItem,
    closeAllListItems,
    openListemItemsInclusive,
    openListItemsExclusive,
  }
}

const ProgramBenefitsForm = ({ partner, program }: TProgramBenefitsFormProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { handlePromises } = useHandlePromises()
  const { addAlert } = useAlerts()
  const { getPathForPreviousStep } = usePartnerUpdateContext()
  const partnerName = partner?.name || ''
  const partnerId = partner.id
  const { createProgramBenefit } = useProgramBenefitCreate({ partnerId })
  const { updateProgramBenefit } = useProgramBenefitUpdate({ partnerId })
  const programEligibleForActivePay = Number(program?.monthsInactiveUntilSnooze) > 0

  const { programBenefits } = program
  const benefits = programBenefits || []
  const defaultProgramBenefit = {
    benefit: { id: undefined },
    subscriptionType: null,
    country: ProgramBenefitCountry.US,
    partnerPaysRaw: null,
    userPaysRaw: null,
    discountRaw: null,
    eligibleForActivePay: false,
    program,
  } as TProgramBenefitVariables

  const programBenefitsWithDefault = [...benefits, defaultProgramBenefit]
  const {
    control,
    handleSubmit,
    reset,
    formState,
    getValues,
    trigger,
    setValue,
    setError,
    clearErrors,
    unregister,
    resetField,
  } = useForm<TProgramBenefits>({
    defaultValues: { programBenefits: programBenefitsWithDefault },
  })

  const {
    indices,
    toggleListItem,
    closeAllListItems,
    openListemItemsInclusive,
    openListItemsExclusive,
  } = useToggleListItems(trigger)

  const totalProgramBenefits = getValues().programBenefits.length
  const indexOfDefaultForm = totalProgramBenefits - 1

  const { dirtyFields, errors, isValid } = formState
  const { programBenefits: programBenefitsErrors } = errors
  const { fields, append } = useFieldArray({
    control,
    name: 'programBenefits',
  })
  const previousStep = getPathForPreviousStep()

  const programBenefitsListErrors = useMemo(() => {
    if (Array.isArray(programBenefitsErrors)) {
      return programBenefitsErrors as FieldError[]
    }

    return []
  }, [programBenefitsErrors])

  const errorsAtListIndices = useMemo(() => {
    return programBenefitsListErrors.reduce((acc, err, idx) => {
      if (err) {
        acc.push(idx)
      }

      return acc
    }, [] as number[])
  }, [programBenefitsListErrors])

  const resetProgramBenefitAtIndex = (index: number) => {
    setValue(`programBenefits.${index}`, defaultProgramBenefit)
    setValue(`programBenefits.${index}.benefit.id`, defaultProgramBenefit.benefit.id)
    clearErrors(`programBenefits.${index}`)
  }

  useEffect(() => {
    if (errorsAtListIndices.length) {
      openListItemsExclusive(errorsAtListIndices)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [errorsAtListIndices])

  useEffect(() => {
    // appending the defaultProgramBenefit to the list on 'add' doesnt trigger
    // updates to control values and stale values need to be manually reset to default
    if (indexOfDefaultForm > 0) {
      resetProgramBenefitAtIndex(indexOfDefaultForm)
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [indexOfDefaultForm])

  const validateBenefit = (index: number) => {
    trigger(`programBenefits.${index}.benefit.id`).catch(e => {
      /* eslint-disable-next-line */
      console.log('e => ', e)
    })
  }
  const validateCountry = (index: number) => {
    trigger(`programBenefits.${index}.country`).catch(e => {
      /* eslint-disable-next-line */
      console.log('e => ', e)
    })
  }

  const validatePricingFields = (index: number) => {
    trigger([
      `programBenefits.${index}.partnerPaysRaw`,
      `programBenefits.${index}.userPaysRaw`,
      `programBenefits.${index}.discountRaw`,
    ]).catch(e => {
      /* eslint-disable-next-line */
      console.log('e => ', e)
    })
  }

  const getDefaultFormIsDirty = () => {
    const defaultFormValues = getValues().programBenefits[indexOfDefaultForm]
    const relevantKeys: Array<keyof TProgramBenefitVariables> = [
      'country',
      'partnerPaysRaw',
      'userPaysRaw',
      'discountRaw',
      'eligibleForActivePay',
    ]
    const defaultFormDirtyValues = relevantKeys.filter(key => {
      return defaultFormValues[key] !== defaultProgramBenefit[key]
    })
    const dirtyBenefit = defaultFormValues.benefit.id !== defaultProgramBenefit.benefit.id

    return !!defaultFormDirtyValues.length || dirtyBenefit
  }

  const resetAll = () => {
    reset(
      {
        programBenefits: programBenefitsWithDefault,
      },
      { keepDirty: true },
    )
  }

  const onSuccess = () => {
    history.push(`${PARTNERS_ROUTE}/${partnerId}`)
  }

  type TCountryBenefitCombos = {
    country: ProgramBenefitCountry
    benefitId: string
    index: number
  }[]

  const countryBenefitCombinations = getValues().programBenefits.reduce(
    (acc, { country, benefit }, index) => {
      if (country && benefit?.id) {
        acc.push({ country, benefitId: benefit.id, index })
      }

      return acc
    },
    [] as TCountryBenefitCombos,
  )

  const handleAdd = () => {
    // validate the program benefit
    trigger(`programBenefits.${indexOfDefaultForm}`)
      .then(success => {
        if (success) {
          append(defaultProgramBenefit)
          closeAllListItems()
        }
      })
      .catch(e => {
        /* eslint-disable-next-line */
        console.log('- ERR ->', e)
      })
  }

  const handleUpdate = (listIndex: number) => {
    if (programBenefitsErrors && programBenefitsErrors[listIndex]) {
      clearErrors(`programBenefits.${listIndex}`)
    }
    // validate the program benefit
    trigger(`programBenefits.${listIndex}`)
      .then(success => {
        if (success) {
          // toggle the form closed
          toggleListItem(listIndex)
        }
      })
      .catch(e => {
        /* eslint-disable-next-line */
        console.log('- ERR ->', e)
      })
  }

  const programBenefitsList = fields.slice(0, fields.length - 1)
  const showList = fields.length > 1

  const itemIsDirty = (index: number) => {
    if (
      dirtyFields &&
      Array.isArray(dirtyFields?.programBenefits) &&
      dirtyFields?.programBenefits[index]
    ) {
      const item = dirtyFields?.programBenefits[index]
      // have to map over the keys and test for truthy values b/c all fields appear "dirty"
      // when element added to the fieldArray

      return Object.keys(item).some(i => !!i)
    }

    return false
  }

  const handleSubmitProgramBenefits: SubmitHandler<TProgramBenefits> = input => {
    /* eslint-disable-next-line */
    const { programBenefits: inputProgramBenefits } = input

    const dirtyProgramBenefits = dirtyFields && dirtyFields?.programBenefits
    if (!dirtyProgramBenefits) return

    const initialValue = { creates: [], updates: [] } as {
      updates: { [key: string]: Partial<TProgramBenefitInput> }[]
      creates: { [key: string]: TProgramBenefitVariables }[]
    }

    const recordsToCommit = dirtyProgramBenefits.reduce((acc, entry, index) => {
      const programBenefit = inputProgramBenefits[index] as TIndexableProgramBenefit
      const { id, benefit } = programBenefit

      if (programBenefit && !programBenefit.id) {
        acc.creates.push({ [`${index}`]: programBenefit })
      } else {
        const startValue = {} as Record<string, unknown>
        const fieldsToUpdate = Object.keys(entry)
          .filter(field => {
            if (field === 'program') return false
            if (field === 'benefit') {
              // b/c benefit data is nested, have to check that that benefit.id is dirty to avoid
              // false positives on dirtyness check from Boolean({id: false})
              return !!entry[field]?.id
            }

            // filter out fields that have dirty values of false (side effect of useFieldArray -- see also itemIsDirty method)
            return Boolean(entry[field as keyof TProgramBenefitVariables])
          })
          .reduce((ac, field) => {
            /* eslint-disable-next-line no-param-reassign */
            ac[field] = programBenefit[field]

            return ac
          }, startValue)

        const hasFieldsToUpdate = !!Object.keys(fieldsToUpdate).length

        if (hasFieldsToUpdate) {
          delete fieldsToUpdate.benefit
          acc.updates.push({
            [`${index}`]: { ...fieldsToUpdate, id, benefitId: benefit.id },
          })
        }
      }

      return acc
    }, initialValue)

    // --- UPDATES ---
    const updatePromises = recordsToCommit.updates.map(item => {
      const listIndex = Number(Object.keys(item)[0])
      const programBenefitInput = item[listIndex]
      const { id: programBenefitId, benefitId, ...rest } = programBenefitInput

      if (!programBenefitId || !benefitId) return Promise.reject()

      return updateProgramBenefit({
        input: { ...rest, benefitId },
        programBenefitId,
      }).catch(e => {
        let errorMessage = t('partner.update_program_benefits_failed_generic')
        if (e instanceof ApolloError && typeof e.message === 'string')
          errorMessage += ` ${e.message}`

        setError(`programBenefits.${listIndex}`, {
          type: 'commitFailed',
          message: errorMessage,
        })
        openListemItemsInclusive(listIndex)
        // throw error, to cause promise to reject
        throw new Error('reject')
      })
    })

    handlePromises({
      promises: updatePromises,
      errorMessageTranslationKey: 'partner.update_program_benefits_failed',
      successMessageTranslationKey: 'partner.update_program_benefits_succeeded',
      handleMessages: addAlert,
      handleMessagesVariables: { name: partnerName },
      onSuccess,
      callOnSuccessIfFailures: false,
    })

    // --- CREATES ---
    const createPromises = recordsToCommit.creates.map(item => {
      const listIndex = Number(Object.keys(item)[0])
      const programBenefit = item[listIndex]
      /* eslint-disable @typescript-eslint/no-unused-vars */
      const {
        partnerPaysRaw,
        userPaysRaw,
        discountRaw,
        country,
        benefit,
        eligibleForActivePay,
      }: TProgramBenefitVariables = programBenefit
      /* eslint-enable @typescript-eslint/no-unused-vars */

      if (!partnerId || !benefit) return Promise.reject()

      return createProgramBenefit({
        input: {
          partnerPaysRaw,
          userPaysRaw,
          discountRaw,
          country,
          eligibleForActivePay,
          benefitId: benefit.id,
        },
        programId: program?.id,
      })
        .then(({ data }) => {
          // if create succeeds, associate the id with benefit at listIndex
          // and reset this programBenefit value in the form to remove dirty and errors
          // this prevents issues where other promises failing to save will cause this part of the form to be
          // resubmitted https://pelotoncycle.atlassian.net/browse/EM-1179
          const createdId = data?.programBenefitCreate?.programBenefit?.id
          const createdProgramBenefit = getValues().programBenefits[listIndex]
          const updatedValues = { ...createdProgramBenefit, id: createdId }
          resetField(`programBenefits.${listIndex}`, {
            defaultValue: updatedValues,
          })
        })
        .catch(e => {
          let errorMessage = t('partner.create_program_benefits_failed_generic')
          if (e instanceof ApolloError && typeof e.message === 'string')
            errorMessage += ` ${e.message}`

          setError(`programBenefits.${listIndex}`, {
            type: 'commitFailed',
            message: errorMessage,
          })
          openListemItemsInclusive(listIndex)
          // throw error, to cause promise to reject
          throw new Error('reject')
        })
    })

    handlePromises({
      promises: createPromises,
      errorMessageTranslationKey: 'partner.create_program_benefits_failed',
      successMessageTranslationKey: 'partner.create_program_benefits_succeeded',
      handleMessages: addAlert,
      handleMessagesVariables: { name: partnerName },
      onSuccess,
      callOnSuccessIfFailures: false,
    })
  }

  const submitForm = (e: BaseSyntheticEvent) => {
    // have to manually check if default form is dirty based on actual values b/c all fields
    // are marked dirty once programBenefits length changes

    const defaultFormIsDirty = getDefaultFormIsDirty()
    const shouldValidateDefaultForm = defaultFormIsDirty || totalProgramBenefits === 1
    if (!shouldValidateDefaultForm) {
      unregister(`programBenefits.${indexOfDefaultForm}`)
      unregister(`programBenefits.${indexOfDefaultForm}.benefit.id`)
    }

    // if a user manually toggles a form closed and it still has errors, we want to force them
    // back open on submit so they can see why the data isn't being submitted and remediate
    // Note: this only works if the forms have triggered validation. The callback
    // is necessary when forms are toggled in order to prevent un-validated changes to a form remaining undetected
    if (!isValid) {
      programBenefitsListErrors.forEach((_, idx) => {
        if (indices.indexOf(idx) === -1) {
          openListemItemsInclusive(idx)
        }
      })
    }

    handleSubmit(handleSubmitProgramBenefits)(e).catch(err => {
      /* eslint-disable-next-line */
      console.log('err ==>', err)
    })
  }

  return (
    <form onSubmit={submitForm}>
      {showList && (
        <>
          <Heading>
            <Headline size="small">{t('partner.list_of_added_benefits')}</Headline>
            <ClearAll onClick={resetAll}>{t('partner.undo_changes')}</ClearAll>
          </Heading>
          <List>
            <Accordion index={indices} onChange={toggleListItem}>
              {programBenefitsList.map((programBenefit, index) => {
                const formError = programBenefitsListErrors[index]

                return (
                  <ListItem
                    key={programBenefit.id}
                    data-testid="program-benefits-list-item"
                  >
                    <AccordionItem>
                      <ListItemHeader control={control} index={index} />

                      <AccordionPanel>
                        <ProgramBenefitForm
                          index={index}
                          countryBenefitCombinations={countryBenefitCombinations}
                          control={control}
                          buttonText={t('partner.update')}
                          isDisabled={!itemIsDirty(index)}
                          error={formError}
                          handleUpdateValues={() => handleUpdate(index)}
                          handleResetValues={() => resetProgramBenefitAtIndex(index)}
                          onBlurPricingField={() => validatePricingFields(index)}
                          onChangeBenefit={() => validateBenefit(index)}
                          onChangeCountry={() => validateCountry(index)}
                          programEligibleForActivePay={programEligibleForActivePay}
                        />
                      </AccordionPanel>
                    </AccordionItem>
                  </ListItem>
                )
              })}
            </Accordion>
          </List>
        </>
      )}

      <Box marginBottom="32px" paddingTop="32px">
        <ProgramBenefitForm
          index={indexOfDefaultForm}
          control={control}
          canReset
          countryBenefitCombinations={countryBenefitCombinations}
          buttonText={t('partner.add_another_benefit')}
          handleUpdateValues={handleAdd}
          handleResetValues={() => resetProgramBenefitAtIndex(indexOfDefaultForm)}
          titleText={t('partner.add_benefit')}
          isDisabled={!itemIsDirty(indexOfDefaultForm)}
          onBlurPricingField={() => validatePricingFields(indexOfDefaultForm)}
          programEligibleForActivePay={programEligibleForActivePay}
        />
      </Box>

      <StyledFormActionsContainer>
        <Box width="126px" marginRight="16px">
          <LinkButton
            color="dark"
            variant="outline"
            component={Link}
            to={previousStep}
            text={t('partner.go_back')}
            width="adaptive"
          />
        </Box>
        <Box width="126px">
          <Button type="submit" width="adaptive" isDisabled={false}>
            {t('partner.save_and_continue')}
          </Button>
        </Box>
      </StyledFormActionsContainer>
    </form>
  )
}

const List = styled.ul`
  background-color: white;
`

const ListItem = styled.li`
  padding: 24px 32px;
  border-bottom: 1px solid ${grey[40]};
`

const StyledPanelHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`

const ClearAll = styled.button.attrs({ type: 'button' })`
  color: ${red[80]};
  text-decoration: underline;
  cursor: pointer;
`

export { ProgramBenefitsForm }
