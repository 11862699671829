import { SingleSelect, Body, white } from '@pelotoncycle/design-system'
import { usePartners } from 'data/hooks'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Page, RequestError, ErrorContainer, EmptyState } from 'ui/components'
import { Dashboard } from 'ui/components/Insights'
import { usePartnerContext } from 'ui/components/PartnerContext'
import { useWindowWidth } from 'ui/hooks'

const Insights = () => {
  const { isMobile } = useWindowWidth()
  const containerWidth = isMobile ? '100%' : '455px'
  const { partnerId, updatePartnerId } = usePartnerContext()
  const [partnerName, setPartnerName] = useState<string | undefined>(undefined)
  const { loading, error, data } = usePartners()
  const { t } = useTranslation()

  const partners = data?.partners
  const hasPartners = partners && !!partners.length

  const handlePartnerChange = (value: string) => {
    if (value) {
      setPartnerName(value)
    }
  }

  useEffect(() => {
    // have to lookup partner id by name :eyeroll:
    // verify that partner name is unique...
    const partner = partners && partners.find(p => p.name === partnerName)
    if (partner) {
      updatePartnerId(partner.id)
    }
  }, [partnerName, partners, updatePartnerId])

  if (!loading && !hasPartners)
    return (
      <ErrorContainer>
        <Body>{t('errors.fetching_partners')}</Body>
      </ErrorContainer>
    )
  if (error) return <RequestError error={error} />

  const dropdownLabel = loading ? t('loading') : t('insights.select_partner')
  const partnerNames = (partners && partners.map(p => p.name)) || []

  return (
    <>
      <StickyDropdown>
        <SingleSelect
          data-testid="partnerSelect"
          disabled={loading}
          label={dropdownLabel}
          items={partnerNames}
          width={containerWidth}
          selectedItem={partnerName}
          handleSelectedItemChange={handlePartnerChange}
        />
      </StickyDropdown>

      <Page
        title={t('insights.title', { partner: partnerName })}
        testId="admin-insights-page"
      >
        {partnerId ? (
          <Dashboard />
        ) : (
          <EmptyState message={t('insights.make_selection')} />
        )}
      </Page>
    </>
  )
}

const StickyDropdown = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: center;
  padding: 20px 0;
  width: 100%;
  background-color: ${white};
  z-index: 3;

  li:first-child {
    display: none;
  }
`

export { Insights }
