import { useMutation } from '@apollo/client'
import { TReportsVariables } from 'data/hooks/useEnrollmentReportsForDateRange'
import { APPROVE_REPORTS } from '../mutations'
import { GET_REPORTS_BY_DATE } from '../queries/reports'

const useApproveReports = (refetchQueryVariables: TReportsVariables) => {
  const [approveReports, { error, loading }] = useMutation(APPROVE_REPORTS, {
    refetchQueries: [
      {
        query: GET_REPORTS_BY_DATE,
        variables: refetchQueryVariables,
      },
    ],
  })

  return { approveReports, error, loading }
}
export { useApproveReports }
