import { useMutation } from '@apollo/client'
import { PartnerCreate } from 'data/mutations/types/PartnerCreate'
import { CREATE_PARTNER } from '../mutations'
import { PartnerCreateVariables } from '../mutations/types/PartnerCreate'
import { GET_PARTNERS } from '../queries'

const usePartnerCreate = () => {
  const [mutate, { loading, error }] = useMutation<PartnerCreate>(CREATE_PARTNER, {
    refetchQueries: [{ query: GET_PARTNERS, variables: { orderBy: 'name' } }],
  })

  const createPartner = ({ input }: PartnerCreateVariables) => {
    return mutate({
      variables: { input },
    })
  }

  return { createPartner, error, loading }
}

export { usePartnerCreate }
