import { IconProps } from './IconTypes'

const Edit = ({ width = '14px', height = '14px' }: IconProps) => (
  <svg width={width} height={height} viewBox="0 0 14 14" fill="none">
    <path
      d="M6.59109 1.8179H2.24246C1.91294 1.8179 1.59692 1.9488 1.36391 2.18181C1.1309 2.41482 1 2.73084 1 3.06036V11.7576C1 12.0871 1.1309 12.4032 1.36391 12.6362C1.59692 12.8692 1.91294 13.0001 2.24246 13.0001H10.9397C11.2692 13.0001 11.5853 12.8692 11.8183 12.6362C12.0513 12.4032 12.1822 12.0871 12.1822 11.7576V7.40899"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.2495 0.886045C11.4966 0.638904 11.8318 0.500061 12.1813 0.500061C12.5309 0.500061 12.866 0.638904 13.1132 0.886045C13.3603 1.13319 13.4992 1.46838 13.4992 1.81789C13.4992 2.1674 13.3603 2.5026 13.1132 2.74974L7.21149 8.65144L4.72656 9.27267L5.34779 6.78775L11.2495 0.886045Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export { Edit }
