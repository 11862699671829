import { ProgramBenefitCountry } from 'data/types/graphql-global-types'

export type CurrencyAbbreviations = 'AUD' | 'CAD' | 'EUR' | 'GBP' | 'USD'

const DEFAULT_CURRENCY = 'USD'
const DOLLAR = '$'
const POUND = '£'
const EURO = '€'

// Regex matching pattern 1.00 or $1.00
// supported currency locales are valid (optional) prefixes, e.g. CA$1.00 or £1.00
const PRICE_FORMAT = /(CA\$|AU\$|\$|£|€)?[0-9]+(\.{1}[0-9]{2})$/g

const hasValue = (value: unknown) => typeof value !== 'undefined' && value !== null

/* Return the currency symbol for the given currency code.
 * Default to US dollars
 * */
const getCurrencySymbol = (currency: string) => {
  let currencySymbol = DOLLAR
  if (currency === 'GBP') currencySymbol = POUND
  if (currency === 'EUR') currencySymbol = EURO

  return currencySymbol
}

/* Converts cents number to equivalent dollar value
 * centsToDollars(100) // 1
 * centsToDollars(115) // 1.15
 * centsToDollars("")  // null
 * */
const centsToDollars = (cents: number | null) => {
  if (typeof cents === 'number') {
    return cents / 100
  }

  return null
}

/* Converts dollars string to equivalent cents number value
 * dollarsToCents("1.00") // 100
 * dollarsToCents("0")    // 0
 * dollarsToCents("")     // null
 * */
const dollarsToCents = (dollars: string | null) => {
  // dont coerce null or "" with Number
  const dollarsNum = dollars && Number(dollars)
  if (typeof dollarsNum === 'number' && !Number.isNaN(dollarsNum)) {
    return Number((dollarsNum * 100).toFixed())
  }

  return null
}

/* Takes cents value and returns currency string
 * formatCentsAsCurrencyString(100, 'USD')   // "$1.00"
 * formatCentsAsCurrencyString(15000, 'GBP') // "£150.00"
 * */
const formatCentsAsCurrencyString = (value: number | null, currency: string) => {
  const dollars = centsToDollars(value)

  if (typeof dollars === 'number') {
    return dollars.toLocaleString('en-US', { style: 'currency', currency })
  }

  return value
}

/* Takes currency string and returns cents value
 * formatCurrencyStringAsCents("$1.00")   // 100
 * formatCurrencyStringAsCents("£150.00") // 15000
 * */
const formatCurrencyStringAsCents = (currencyString: string) => {
  const currencySymbol = /\$|£|€/g
  const match = currencyString.match(PRICE_FORMAT)
  if (match) {
    const parts = match.join().split(currencySymbol)
    const amountString = parts[parts.length - 1]

    return dollarsToCents(amountString)
  }

  return null
}

const getCurrencyForCountry = (country: ProgramBenefitCountry | null) => {
  const CURRENCY_TO_COUNTRY_MAPPING: Record<ProgramBenefitCountry, string | undefined> = {
    AU: 'AUD',
    CA: 'CAD',
    DE: 'EUR',
    GB: 'GBP',
    US: 'USD',
  }

  let currency
  if (country) {
    currency = CURRENCY_TO_COUNTRY_MAPPING[country]
  }

  return currency || DEFAULT_CURRENCY
}

export {
  hasValue,
  centsToDollars,
  dollarsToCents,
  formatCentsAsCurrencyString,
  formatCurrencyStringAsCents,
  getCurrencySymbol,
  getCurrencyForCountry,
  PRICE_FORMAT,
  DEFAULT_CURRENCY,
}
