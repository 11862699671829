import { useAuth0 } from '@auth0/auth0-react'
import { Body, Display, Button, Headline, Label, Icon } from '@pelotoncycle/design-system'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Box } from 'ui/components'
import { LOGOUT_ROUTE } from 'utils/constants/admin'
import { Modal, ModalProps } from './Modal'

const Loading = () => {
  const { t } = useTranslation()

  return (
    <LoadingContainer>
      <Headline size="small">{t('modals.logging_in')}</Headline>
      <Body size="medium">{t('modals.logging_in_message')}</Body>
      <Icon name="spinner" height={32} />
    </LoadingContainer>
  )
}

const LogoutWarningModal = ({ isOpen, handleClose }: Partial<ModalProps>) => {
  const history = useHistory()
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  const handleLogout = () => {
    history.push(LOGOUT_ROUTE)
    if (handleClose) {
      handleClose()
    }
  }

  const handleTokenRefreshAttempt = () => {
    setIsLoading(true)
    getAccessTokenSilently({ ignoreCache: true })
      .then(() => {
        setIsLoading(false)
        if (typeof handleClose === 'function') handleClose()
      })
      .catch(() => {
        const { search } = history.location
        const returnTo = window.location.pathname + search
        loginWithRedirect({
          appState: { returnTo },
          connection: 'pelo-okta',
        }).catch(() => {
          handleLogout()
        })
      })
  }

  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      {isLoading ? (
        <Loading />
      ) : (
        <Box textAlign="center">
          <Display size="small">{t('modals.session_will_expire')}</Display>
          <Box marginTop="32px" marginBottom="40px" textAlign="center">
            <Body size="medium">{t('modals.session_will_expire')}</Body>
          </Box>
          <Button onClick={handleTokenRefreshAttempt} size="medium">
            {t('modals.stay_logged_in')}
          </Button>
          <Box textAlign="center" marginTop="20px">
            <StyledLabel size="large" onClick={handleLogout}>
              {t('logout')}
            </StyledLabel>
          </Box>
        </Box>
      )}
    </Modal>
  )
}

const StyledLabel = styled(Label)`
  cursor: pointer;
  border-bottom: 1px solid black;
`

const LoadingContainer = styled.div`
  height: 224px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-around;
`

export { LogoutWarningModal }
