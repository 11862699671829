import { useMutation } from '@apollo/client'
import { PartnerUpdate } from 'data/mutations/types/PartnerUpdate'
import { PartnerInput } from 'data/types/graphql-global-types'
import { UPDATE_PARTNER } from '../mutations'
import { GET_PARTNER } from '../queries'

type TID = {
  partnerId: string
}

type TUpdatePartner = {
  input: PartnerInput
}

const usePartnerUpdate = ({ partnerId }: TID) => {
  const [mutate, { loading, error }] = useMutation<PartnerUpdate>(UPDATE_PARTNER, {
    refetchQueries: [{ query: GET_PARTNER, variables: { id: partnerId } }],
  })

  const updatePartner = ({ input }: TUpdatePartner) => {
    return mutate({
      variables: { partnerId, input },
    })
  }

  return { updatePartner, error, loading }
}

export { usePartnerUpdate }
