import { useAuth0 } from '@auth0/auth0-react'
import { white, black } from '@pelotoncycle/design-system'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { DEFAULT_REDIRECT } from 'utils/constants/admin'
import { Box } from '../..'
import { LogoAdmin } from '../../svg'
import { AuthButton } from '../AuthButton'

const Login = () => {
  const { isAuthenticated } = useAuth0()

  // Push authenticated users from /login -> /
  if (isAuthenticated) {
    return <Redirect to={DEFAULT_REDIRECT} />
  }

  return (
    <main>
      <Section>
        <LogoAdmin fill={black} width="184px" height="53px" />
        <Box marginTop="36px">
          <AuthButton color="dark" variant="solid" />
        </Box>
      </Section>
    </main>
  )
}

const Section = styled.section`
  width: 476px;
  height: 308px;
  margin: 228px auto 0;
  background-color: ${white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
`

export { Login }
