import { useQuery } from '@apollo/client'
import { GET_REPORTS_BY_DATE } from 'data/queries'
import {
  ReportsByDate,
  ReportsByDateVariables,
} from '../../data/queries/types/ReportsByDate'

// date is a custom scalar, which gets typed as any during introspection
// have to override the generic any type to string
export interface TReportsVariables extends ReportsByDateVariables {
  date: string
}

const useEnrollmentReportsForDateRange = ({ date, reportType }: TReportsVariables) => {
  const { loading, error, data } = useQuery<ReportsByDate>(GET_REPORTS_BY_DATE, {
    variables: { date, reportType },
  })

  return {
    loading,
    error,
    data,
  }
}

export { useEnrollmentReportsForDateRange }
