import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { AuthProvider } from 'ui/components/AuthProvider'
import { ApolloClientProvider } from './ApolloClientProvider'
import { App } from './App'
// import { reportWebVitals } from './reportWebVitals'

const container = document.getElementById('root')
/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
const root = createRoot(container!)

// storing access_token in localStorage prevents requiring login on every page refresh
root.render(
  <Router>
    <AuthProvider cacheLocation="localstorage">
      <ApolloClientProvider>
        <App />
      </ApolloClientProvider>
    </AuthProvider>
  </Router>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
