import { useMutation } from '@apollo/client'
import {
  PartnerContactDelete,
  PartnerContactDeleteVariables,
} from 'data/mutations/types/PartnerContactDelete'
import { DELETE_PARTNER_CONTACT } from '../mutations'
import { GET_PARTNER } from '../queries'

type TID = {
  partnerId: string
}

const usePartnerContactDelete = ({ partnerId }: TID) => {
  const [mutate, { loading, error }] = useMutation<PartnerContactDelete>(
    DELETE_PARTNER_CONTACT,
    {
      refetchQueries: [{ query: GET_PARTNER, variables: { id: partnerId } }],
    },
  )

  const deletePartnerContact = ({ contactId }: PartnerContactDeleteVariables) => {
    return mutate({
      variables: { contactId },
    })
  }

  return { deletePartnerContact, error, loading }
}

export { usePartnerContactDelete }
