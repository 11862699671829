/* ROUTES */
export const LOGIN_ROUTE = '/login'
export const LOGOUT_ROUTE = '/logout'
export const REPORTING_ROUTE = '/reporting'
export const ELIGIBLE_PERSON_ROUTE = '/eligible-person/:id'
export const REPORTING_EEL_ROUTE = `${REPORTING_ROUTE}/eel-reports`
export const REPORTING_CHECK_ROUTE = `${REPORTING_ROUTE}/check`
export const INSIGHTS_ROUTE = '/insights'
export const PARTNERS_ROUTE = '/partners'
export const PARTNER_DETAIL_ROUTE = `${PARTNERS_ROUTE}/:partnerId`
export const PARTNER_CREATE_ROUTE = `${PARTNERS_ROUTE}/create`
export const PARTNER_UPDATE_ROUTE = `${PARTNERS_ROUTE}/:partnerId/update`
export const PARTNER_UPDATE_DETAILS_ROUTE = `${PARTNER_UPDATE_ROUTE}/details`
export const PARTNER_UPDATE_ELIGIBILITY_ROUTE = `${PARTNER_UPDATE_ROUTE}/eligibility`
export const PARTNER_UPDATE_EMAILS_ROUTE = `${PARTNER_UPDATE_ROUTE}/emails`
export const PARTNER_UPDATE_BENEFITS_ROUTE = `${PARTNER_UPDATE_ROUTE}/benefits`

export const DEFAULT_REDIRECT = REPORTING_ROUTE
export const ROOT = '/'

/* GENERAL */
export const REDIRECT_PARAM = 'returnTo'
export const EMAIL_REGEX = /^(.+)@(.+)$/g
export const EMAIL_DOMAIN_REGEX = /[\S]+[.][\w.]{2,}/
