import { usePartnerTransferUsers } from 'data/hooks'
import { useTranslation } from 'react-i18next'
import { useHistory, Redirect } from 'react-router-dom'
import { Page, EmptyState, LoadingScreen, RequestError } from 'ui/components'
import { usePartnerUpdateContext } from 'ui/components/PartnerUpdateContext'
import { isRapidPartner } from 'utils'
import { PartnerEligibilityForm, PartnerTransferUsersForm } from '../forms'

const PartnerUpdateEligibility = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { partner, loading, error, getPathForPreviousStep, getPathForNextStep } =
    usePartnerUpdateContext()

  const {
    data,
    loading: partnerTransferUsersLoading,
    error: partnerTransferUsersError,
  } = usePartnerTransferUsers({ partnerId: partner?.id ?? '' })
  const partnerTransferUsers = data?.partnerTransferUsers

  if (loading || partnerTransferUsersLoading) return <LoadingScreen />
  if (error) return <RequestError error={error} />
  if (!partner) return <EmptyState message={t('partner.not_found')} />
  const previousStep = getPathForPreviousStep()

  // Links to this route should only show for Corporate partner types, but a
  // user can still get here by manual URL input. Boot 'em if they do.
  if (isRapidPartner(partner)) {
    return <Redirect to={previousStep} />
  }

  const onSuccess = () => {
    const nextStep = getPathForNextStep()
    history.push(nextStep)
  }

  return (
    <Page title={t('partner.update_eligibility_title')} maxWidth="600px">
      <PartnerEligibilityForm
        partner={partner}
        previousStep={previousStep}
        onSuccess={onSuccess}
      />
      {partnerTransferUsersError ? (
        <RequestError error={partnerTransferUsersError} />
      ) : (
        <PartnerTransferUsersForm
          partner={partner}
          partnerTransferUsers={partnerTransferUsers}
        />
      )}
    </Page>
  )
}

export { PartnerUpdateEligibility }
