import { Eyebrow } from '@pelotoncycle/design-system'
import { useEligiblePerson } from 'data/hooks'
import { format } from 'date-fns'
import DOMPurify from 'dompurify'
import { useTranslation } from 'react-i18next'
import { useParams, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Page, LoadingScreen, NotFoundPage, LinkBackTo, Field } from 'ui/components'
import { REPORTING_CHECK_ROUTE } from 'utils/constants/admin'

interface RouteParams {
  id: string
}

const formatDateRange = (start: string | null, end: string | null) => {
  if (!start || !end) return ''

  return `${format(new Date(`${start}T00:00:00`), 'MMM d, y')} - ${format(
    new Date(`${end}T00:00:00`),
    'MMM d, y',
  )}`
}

const EligiblePerson = () => {
  const { t } = useTranslation()
  const { id } = useParams<RouteParams>()
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const partnerHashId = queryParams.get('hash')
  const { loading, error, data } = useEligiblePerson({ id, partnerHashId })
  const person = data?.eligiblePersonById

  let content
  if (loading) {
    content = <LoadingScreen />
  } else if (error || !person) {
    content = <NotFoundPage />
  } else {
    let eligiblePeriods

    if (person.eligibilityPeriods?.length) {
      eligiblePeriods = person.eligibilityPeriods?.map((period, i) => (
        <Field
          key={period.id}
          label={i === 0 ? t('reporting_check.eligibility_periods') : ''}
          value={formatDateRange(
            period.effectiveDate as string | null,
            period.terminationDate as string | null,
          )}
        />
      ))
    } else {
      eligiblePeriods = (
        <Field label={t('reporting_check.eligibility_periods')} value="--" />
      )
    }

    let cmsRiderLink = person.enrollment?.cmsRiderLink || ''
    if (cmsRiderLink) {
      cmsRiderLink = DOMPurify.sanitize(cmsRiderLink)
    }

    content = (
      <>
        <Back to={REPORTING_CHECK_ROUTE} title={t('reporting_check.back_to_results')} />
        <Title size="small">{t('reporting_check.employee_info')}</Title>
        <Field label={t('reporting_check.first_name')} value={person.firstName} />
        <Field label={t('reporting_check.last_name')} value={person.lastName} />
        {eligiblePeriods}
        <Field
          label={t('reporting_check.current_eligibility')}
          value={formatDateRange(
            person.currentEligibility?.effectiveDate as string,
            person.currentEligibility?.terminationDate as string,
          )}
        />
        <Field
          label={t('reporting_check.is_claimed')}
          value={person.enrollment?.accessToken ? 'Yes' : 'No'}
        />
        <Field
          label={t('reporting_check.cms_rider_links')}
          value={
            cmsRiderLink ? (
              // eslint-disable-next-line react/no-danger
              <div dangerouslySetInnerHTML={{ __html: cmsRiderLink }} />
            ) : (
              '--'
            )
          }
        />
        <Field label={t('reporting_check.partner')} value={person.partner?.name} />
        <Field label={t('reporting_check.sponsor')} value={person.sponsor?.name} />
        <Field label={t('reporting_check.id')} value={person.id} />
      </>
    )
  }

  return <Page>{content}</Page>
}

const Back = styled(LinkBackTo)`
  margin-bottom: 3rem;
`

const Title = styled(Eyebrow)`
  display: block;
  margin-bottom: 1rem;
`

export { EligiblePerson }
