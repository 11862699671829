import React from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Switch, Redirect } from 'react-router-dom'
import {
  REPORTING_ROUTE as BASE_PATH,
  REPORTING_EEL_ROUTE,
  REPORTING_CHECK_ROUTE,
} from 'utils/constants/admin'
import { Page, SubNav } from '../..'
import { ReportingCheck } from './ReportingCheck'
import { ReportingEEL } from './ReportingEEL'

const Reporting = () => {
  const { t } = useTranslation()

  const SUBPATHS = [
    {
      path: REPORTING_EEL_ROUTE,
      displayName: t('reporting.eel'),
    },
    {
      path: REPORTING_CHECK_ROUTE,
      displayName: t('reporting.eligibility_and_enrollment_check'),
    },
  ]

  return (
    <Page title={t('reporting.title')} testId="reporting-page">
      <SubNav paths={SUBPATHS} />

      <Switch>
        <Route exact path={REPORTING_EEL_ROUTE}>
          <ReportingEEL />
        </Route>
        <Route exact path={REPORTING_CHECK_ROUTE}>
          <ReportingCheck />
        </Route>
        <Redirect from={BASE_PATH} to={REPORTING_EEL_ROUTE} />
      </Switch>
    </Page>
  )
}

export { Reporting }
