import { Label, grey } from '@pelotoncycle/design-system'
import { Partner_partner as TPartner } from 'data/queries/types/Partner'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { getItemTextForValue, Flex, Box } from 'ui/components'
import { Edit } from 'ui/components/svg'
import { useMonthsInactive } from 'ui/hooks'
import { FormSection } from './FormSection'
import { ProgramBenefitsForm } from './ProgramBenefitsForm'
import { ProgramCreateForm } from './ProgramCreateForm'
import { ProgramUpdateForm } from './ProgramUpdateForm'
import { StyledForm } from './StyledForm'

type TBenefitsFormProps = {
  partner: TPartner
}

type TCollapsedProgramProps = {
  program: TPartner['program']
  onClick: () => void
}

const CollapsedProgram = ({ program, onClick }: TCollapsedProgramProps) => {
  const { t } = useTranslation()
  const MONTHS_INACTIVE = useMonthsInactive()

  const activePayText = getItemTextForValue(
    MONTHS_INACTIVE,
    program?.monthsInactiveUntilSnooze,
  )

  return (
    <Box marginBottom="32px" data-testid="collapsed-program">
      <FormSection title={t('partner.program_name')} borderBottom>
        <Flex justifyContent="space-between">
          <Flex>
            <Box marginRight="12px">
              <Label weight="medium" size="extraLarge">
                {program?.name}
              </Label>
            </Box>
            <Grey>
              <Label size="extraLarge">{activePayText}</Label>
            </Grey>
          </Flex>
          <button type="button" onClick={onClick}>
            <Edit />
          </button>
        </Flex>
      </FormSection>
    </Box>
  )
}

const BenefitsForm = ({ partner }: TBenefitsFormProps) => {
  const { program } = partner
  const hasProgram = program?.id
  const [showProgramForm, setShowProgramForm] = useState(!hasProgram)
  const onSuccess = () => {
    setShowProgramForm(false)
  }

  let programContent = <ProgramCreateForm partner={partner} onSuccess={onSuccess} />

  if (!showProgramForm) {
    programContent = (
      <CollapsedProgram program={program} onClick={() => setShowProgramForm(true)} />
    )
  } else if (hasProgram) {
    programContent = (
      <ProgramUpdateForm program={program} partner={partner} onSuccess={onSuccess} />
    )
  }

  return (
    <StyledForm>
      {programContent}
      {hasProgram && partner && (
        <ProgramBenefitsForm program={program} partner={partner} />
      )}
    </StyledForm>
  )
}

const Grey = styled.div`
  color: ${grey[70]};
`

export { BenefitsForm }
