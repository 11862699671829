import { useTranslation } from 'react-i18next'
import { useHistory, Redirect } from 'react-router-dom'
import { Page, EmptyState, LoadingScreen, RequestError } from 'ui/components'
import { usePartnerUpdateContext } from 'ui/components/PartnerUpdateContext'
import { isRapidPartner } from 'utils'
import { PartnerEmailsForm } from '../forms'

const PartnerUpdateEmails = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { partner, loading, error, getPathForPreviousStep, getPathForNextStep } =
    usePartnerUpdateContext()

  if (loading) return <LoadingScreen />
  if (error) return <RequestError error={error} />
  if (!partner) return <EmptyState message={t('partner.not_found')} />
  const previousStep = getPathForPreviousStep()

  // Links to this route should only show for Corporate partner types, but a
  // user can still get here by manual URL input. Boot 'em if they do.
  if (isRapidPartner(partner)) {
    return <Redirect to={previousStep} />
  }

  const onSuccess = () => {
    const nextStep = getPathForNextStep()
    history.push(nextStep)
  }

  return (
    <Page title={t('partner.update_emails_title')} maxWidth="600px">
      <PartnerEmailsForm
        partner={partner}
        previousStep={previousStep}
        onSuccess={onSuccess}
      />
    </Page>
  )
}

export { PartnerUpdateEmails }
