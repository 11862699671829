import { RadioBox, Body } from '@pelotoncycle/design-system'
import { Audiences } from 'data/types/graphql-global-types'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { SplitRow } from './SplitRow'

// type unknown more better
type TProps = {
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  partnerType: string
  disabled?: boolean
}

const PartnerTypeRadioSelect = ({ onChange, partnerType, disabled }: TProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Body size="small">{t('partner.partner_type_locked')}</Body>
      <fieldset>
        <SplitRow>
          <RadioBox
            name="partnerType"
            value={Audiences.partnership}
            labelText={t('partner.partner_type_rapid')}
            handleChange={onChange}
            checked={partnerType === Audiences.partnership}
            disabled={disabled}
          />
          <RadioBox
            name="partnerType"
            value={Audiences.corporate}
            labelText={t('partner.partner_type_cw')}
            handleChange={onChange}
            checked={partnerType === Audiences.corporate}
            disabled={disabled}
          />
        </SplitRow>
      </fieldset>
    </>
  )
}

export { PartnerTypeRadioSelect }
