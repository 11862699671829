import { ApolloError } from '@apollo/client'
import { useProgramCreate } from 'data/hooks'
import { Partner_partner as TPartner } from 'data/queries/types/Partner'
import { ProgramInput } from 'data/types/graphql-global-types'
import { BaseSyntheticEvent } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useAlerts, AlertVariants } from 'ui/components'
import { formatProgramName } from 'utils'
import { ProgramForm } from './ProgramForm'

type TProgramFormProps = {
  partner: TPartner
  onSuccess?: () => void
}

const ProgramCreateForm = ({ partner, onSuccess }: TProgramFormProps) => {
  const { t } = useTranslation()
  const { addAlert } = useAlerts()
  const { name: partnerName, id: partnerId, hashId: partnerHashId } = partner

  const { createProgram, loading } = useProgramCreate({
    partnerId,
  })

  const defaultProgramName = formatProgramName(partnerName)

  const { control, handleSubmit } = useForm<ProgramInput>({
    defaultValues: {
      name: defaultProgramName,
      monthsInactiveUntilSnooze: null,
      partnerHashId,
    },
  })

  const handleProgramSubmit: SubmitHandler<ProgramInput> = input => {
    createProgram({ input })
      .then(({ data: responseData }) => {
        if (responseData?.programCreate?.requestOk) {
          addAlert({
            variant: AlertVariants.success,
            message: t('partner.program_created'),
            autoClose: 8000,
          })
          if (onSuccess) onSuccess()
        }
      })
      .catch(e => {
        let error = ''
        if (typeof e === 'string') error = e
        if (e instanceof ApolloError && typeof e.message === 'string') error = e.message
        addAlert({
          variant: AlertVariants.error,
          message: t('parter.program_create_failed', { error }),
          autoClose: 8000,
        })
      })
  }

  const submitForm = (e: BaseSyntheticEvent) => {
    handleSubmit(handleProgramSubmit)(e).catch(err => {
      /* eslint-disable-next-line */
      console.log('err ==>', err)
    })
  }

  return <ProgramForm control={control} onSubmit={submitForm} isDisabled={loading} />
}

export { ProgramCreateForm }
