import { Eyebrow, Body, grey } from '@pelotoncycle/design-system'
import { ReactNode } from 'react'
import { Box } from 'ui/components'

type TSectionProps = {
  children: ReactNode
  title?: string
  subtext?: ReactNode
  borderBottom?: boolean
  testId?: string
}

const FormSection = ({
  title,
  subtext,
  children,
  borderBottom,
  testId,
}: TSectionProps) => {
  return (
    <Box
      marginBottom="32px"
      paddingBottom={borderBottom ? '32px' : ''}
      borderBottom={borderBottom ? `1px solid ${grey[50]}` : ''}
      data-testid={testId}
    >
      {title && (
        <Box marginBottom="8px">
          <Eyebrow size="large">{title}</Eyebrow>
        </Box>
      )}
      {subtext && (
        <Box marginBottom="8px">
          <Body size="small">{subtext}</Body>
        </Box>
      )}
      {children}
    </Box>
  )
}

export { FormSection }
