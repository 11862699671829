import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '@pelotoncycle/design-system'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { getQueryParamValue } from 'utils'
import { ROOT, LOGOUT_ROUTE, REDIRECT_PARAM } from 'utils/constants/admin'

type TVariantProps = 'outline' | 'solid'
type TColorProps = 'primary' | 'light' | 'dark'
type TButtonProps = {
  color?: TColorProps
  variant?: TVariantProps
}

const AuthButton = ({ color = 'primary', variant = 'outline' }: TButtonProps) => {
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0()
  const { t } = useTranslation()
  const history = useHistory()

  const text = isAuthenticated ? t('logout') : t('login')

  const handleClick = () => {
    const { search } = history.location
    const returnTo =
      getQueryParamValue({ queryParams: search, redirectParam: REDIRECT_PARAM }) || ROOT

    if (isAuthenticated) {
      history.push(LOGOUT_ROUTE)
    } else {
      loginWithRedirect({
        appState: { returnTo },
        connection: 'pelo-okta',
      }).catch(err => {
        /* eslint-disable-next-line no-console */
        console.log('loginWithRedirect attempt failed: ', err)
      })
    }
  }

  return (
    <Button
      data-testid="auth-btn"
      text={text}
      onClick={handleClick}
      size="small"
      color={color}
      variant={variant}
      isLoading={isLoading}
    />
  )
}

export { AuthButton }
