import { useTranslation } from 'react-i18next'
import { Page, EmptyState, LoadingScreen, RequestError } from 'ui/components'
import { usePartnerUpdateContext } from 'ui/components/PartnerUpdateContext'
import { BenefitsForm } from '../forms'

const PartnerUpdateBenefits = () => {
  const { t } = useTranslation()
  const { partner, loading, error } = usePartnerUpdateContext()

  if (loading) return <LoadingScreen />
  if (error) return <RequestError error={error} />
  if (!partner) return <EmptyState message={t('partner.not_found')} />

  return (
    <Page title={t('partner.update_benefits_title')} maxWidth="600px">
      <BenefitsForm partner={partner} />
    </Page>
  )
}

export { PartnerUpdateBenefits }
