import { ApolloError } from '@apollo/client'
import { Button } from '@pelotoncycle/design-system'
import { usePartnerCreate, usePartnerContactCreate } from 'data/hooks'
import { Audiences, PartnerInput } from 'data/types/graphql-global-types'
import { useEffect, useRef, BaseSyntheticEvent } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useAlerts, AlertVariants } from 'ui/components'
import { useHandlePromises } from 'ui/hooks'
import { TPartnerContacts } from './Fields'
import { PartnerContactsForm, PARTNER_CONTACT_DEFAULTS } from './PartnerContactsForm'
import { PartnerForm } from './PartnerForm'
import { StyledForm } from './StyledForm'
import { StyledFormActionsContainer } from './StyledFormActionsContainer'

type TPartnerCreateFormProps = {
  onSuccess: () => void
}

const CORPORATE_PARTNER_DEFAULTS = {
  partnerType: Audiences.corporate,
  name: '',
  salesforceCustomerId: '',
}

const RAPID_PARTNER_DEFAULTS = {
  partnerType: Audiences.partnership,
  name: '',
  slug: '',
}

const PartnerCreateForm = ({ onSuccess }: TPartnerCreateFormProps) => {
  const { t } = useTranslation()
  const { addAlert } = useAlerts()
  const { handlePromises } = useHandlePromises()

  const { createPartnerContact, loading: createContactLoading } =
    usePartnerContactCreate()

  const { control, trigger, handleSubmit, watch, formState, reset } =
    useForm<PartnerInput>({
      defaultValues: {},
    })
  const { isDirty: partnerIsDirty } = formState

  const {
    control: contactsControl,
    trigger: contactsTrigger,
    formState: conctactsFormState,
    getValues: getContactsValues,
    reset: resetContacts,
  } = useForm<TPartnerContacts>({
    defaultValues: { contacts: [{ ...PARTNER_CONTACT_DEFAULTS }] },
  })
  const { isDirty: contactsIsDirty } = conctactsFormState

  const isDirty = partnerIsDirty || contactsIsDirty

  const partnerFormEl = useRef<HTMLFormElement>(null)

  const watchPartnerType = watch('partnerType')
  const hasRapidPartnerType = watchPartnerType === Audiences.partnership
  const hasCorporatePartnerType = watchPartnerType === Audiences.corporate

  useEffect(() => {
    // if partner type changes, reset all form field values (except partnerType)
    if (hasRapidPartnerType) {
      reset({ ...RAPID_PARTNER_DEFAULTS })
    } else if (hasCorporatePartnerType) {
      reset({ ...CORPORATE_PARTNER_DEFAULTS })
      resetContacts({ contacts: [{ ...PARTNER_CONTACT_DEFAULTS }] })
    }
  }, [
    watchPartnerType,
    hasRapidPartnerType,
    hasCorporatePartnerType,
    reset,
    resetContacts,
  ])

  const { createPartner, loading: createPartnerLoading } = usePartnerCreate()

  const isLoading = createPartnerLoading || createContactLoading
  const isDisabled = !isDirty || isLoading

  const handleContactCreate = ({
    partnerId,
    partnerName,
  }: {
    partnerId: string
    partnerName: string
  }) => {
    const { contacts } = getContactsValues()

    const promises = contacts.map(contact => {
      return createPartnerContact({ input: contact, partnerId })
    })

    handlePromises({
      promises,
      errorMessageTranslationKey: 'partner.create_contacts_failed',
      successMessageTranslationKey: 'partner.create_contacts_succeeded',
      handleMessages: addAlert,
      handleMessagesVariables: { name: partnerName },
    })
  }

  const handlePartnerSubmit: SubmitHandler<PartnerInput> = input => {
    createPartner({ input })
      .then(({ data }) => {
        if (data && data?.partnerCreate?.requestOk && data?.partnerCreate?.partner?.id) {
          const { partner } = data.partnerCreate
          const { id: partnerId } = partner
          const name = partner?.name || t('partner.partner')

          addAlert({
            variant: AlertVariants.success,
            message: t('partner.create_succeeded', { name }),
            autoClose: 8000,
          })

          // if corporate partner saves, create contacts
          if (hasCorporatePartnerType) {
            handleContactCreate({ partnerId, partnerName: name })
          }

          if (onSuccess) onSuccess()
        }
      })
      .catch(e => {
        let error = ''
        if (typeof e === 'string') error = e
        if (e instanceof ApolloError && typeof e.message === 'string') error = e.message

        addAlert({
          variant: AlertVariants.error,
          message: t('partner.create_failed', { error }),
          autoClose: 8000,
        })
      })
  }

  const submitPartner = (e: BaseSyntheticEvent) => {
    // handleSubmit returns a promise, and onSubmit needs a void return
    handleSubmit(handlePartnerSubmit)(e).catch(err => {
      /* eslint-disable-next-line */
      console.log('err ==>', err)
    })
  }

  const handleParnterFormSubmit = async () => {
    if (partnerFormEl.current) {
      // trigger validations manually on both forms - isValid getter is unreliable/wrong
      const partnerFormValid = await trigger()
      const contactsFormValid = await contactsTrigger()
      if (partnerFormValid && contactsFormValid) {
        partnerFormEl.current.dispatchEvent(
          new Event('submit', { cancelable: true, bubbles: true }),
        )
      }
    }
  }

  const submitPartnerAndContacts = () => {
    handleParnterFormSubmit().catch(e => {
      /* eslint-disable-next-line */
      console.log('e ==>', e)
    })
  }

  return (
    <StyledForm>
      <PartnerForm
        ref={partnerFormEl}
        onSubmit={submitPartner}
        control={control}
        isDisabled={isDisabled}
        partnerType={watchPartnerType}
        buttonText={t('partner.create_partner')}
        allowChangePartnerType
      />

      {hasCorporatePartnerType && (
        <>
          <PartnerContactsForm
            control={contactsControl}
            trigger={contactsTrigger}
            getValues={getContactsValues}
          />

          <StyledFormActionsContainer>
            <Button
              isDisabled={isDisabled}
              onClick={submitPartnerAndContacts}
              data-testid="partnerContactSubmit"
            >
              {t('partner.create_partner')}
            </Button>
          </StyledFormActionsContainer>
        </>
      )}
    </StyledForm>
  )
}

export { PartnerCreateForm }
