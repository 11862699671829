import { useQuery } from '@apollo/client'
import { GET_BENEFITS } from 'data/queries'
import { Benefits } from '../../data/queries/types/Benefits'

const useBenefits = () => {
  const { loading, error, data } = useQuery<Benefits>(GET_BENEFITS)

  return {
    loading,
    error,
    data,
  }
}

export { useBenefits }
