import { SingleSelect } from '@pelotoncycle/design-system'

export type TSelectFromMappedValuesItem = { value: string | number | null; text: string }
export type TSelectFromMappedValuesItems = TSelectFromMappedValuesItem[]

type TProps = {
  label: string
  items: TSelectFromMappedValuesItems
  value: unknown
  defaultValue?: unknown
  onChange: (arg: string | number | null) => void
  disabled?: boolean
}

export const getTextForItems = (items: TSelectFromMappedValuesItems) =>
  items.map(i => i.text)

export const getItemTextForValue = (
  items: TSelectFromMappedValuesItems,
  value: unknown,
) => {
  const selectedItem = items.find(i => i.value === value)
  const selectedItemText = selectedItem && selectedItem?.text

  return selectedItemText || ''
}

const getItemValueForText = (items: TSelectFromMappedValuesItems, text: string) => {
  const selectedText = items.find(i => i.text === text)
  const selectedTextValue = selectedText && selectedText.value

  return selectedTextValue || null
}

const SelectFromMappedValues = ({
  label,
  items,
  value,
  defaultValue,
  onChange,
  disabled,
}: TProps) => {
  const itemsText = getTextForItems(items)
  const valueOrDefault = defaultValue && !value ? defaultValue : value
  const selectedItemText = getItemTextForValue(items, valueOrDefault)

  return (
    <SingleSelect
      label={label}
      items={itemsText}
      selectedItem={selectedItemText}
      handleSelectedItemChange={(selected: string) =>
        onChange(getItemValueForText(items, selected))
      }
      disabled={disabled}
    />
  )
}

export { SelectFromMappedValues }
