import { ApolloError } from '@apollo/client'
import {
  Input,
  TextArea,
  Body,
  Button,
  Support,
  ErrorMsg,
} from '@pelotoncycle/design-system'
import type { TextAreaProps } from '@pelotoncycle/design-system'
import {
  usePartnerTransferUserUpdate,
  usePartnerTransferUserCreate,
  usePartnerTransferUserDelete,
  useTransferUserKeyCreate,
  useTransferUserKeyDelete,
} from 'data/hooks'
import { Partner_partner as TPartner } from 'data/queries/types/Partner'
import {
  PartnerTransferUsers_partnerTransferUsers as PartnerTransferUsers,
  PartnerTransferUsers_partnerTransferUsers_transferUserKeys as TTransferUserKeys,
} from 'data/queries/types/PartnerTransferUsers'
import { TransferUserKeyInput } from 'data/types/graphql-global-types'
import { useState, useEffect, useMemo, BaseSyntheticEvent, FocusEvent } from 'react'
import {
  SubmitHandler,
  useForm,
  FieldErrors,
  useFieldArray,
  UseFormTrigger,
  Controller,
  Control,
  UseFormGetValues,
  UseFormStateReturn,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useAlerts, Box, Flex } from 'ui/components'
import { StyledHeadline } from 'ui/components/Page'
import { useHandlePromises, useDebouncedCallback } from 'ui/hooks'
import { get } from 'utils'
import { FormSection } from './FormSection'
import { useErrorMessageForField } from './helpers'
import { StyledForm } from './StyledForm'
import { StyledFormActionsContainer } from './StyledFormActionsContainer'
import { StyledMultiFieldContainer } from './StyledMultiFieldContainer'
import { StyledRemoveButton } from './StyledRemoveButton'

type TPartnerTransferUser = Pick<
  PartnerTransferUsers,
  'id' | 'userName' | 'notes' | 'transferUserKeys'
>
type TPartnerTransferUsers = TPartnerTransferUser[]
type TPartnerTransferUsersFormProps = {
  partner: TPartner
  partnerTransferUsers: TPartnerTransferUsers | null | undefined
}
type TFormValues = { partnerTransferUsers: TPartnerTransferUsers }

type TTransferUserKeysProps = {
  parentIndex: number
  parentId: string | undefined
  control: Control<TFormValues>
  trigger: UseFormTrigger<TFormValues>
  getValues: UseFormGetValues<TFormValues>
  handleScheduleForDelete: (id: string) => void
  handleUnscheduleForDelete: (id: string) => void
  getIsScheduledforDelete: (id: string) => boolean
  getParentIsScheduledforDelete: (id: string) => boolean
}

type TransferKeyKeySshFieldName =
  `partnerTransferUsers.${number}.transferUserKeys.${number}.sshPublicKeyBody`

const USERNAME_REGEX = /^[\w][\w@.-]{2,99}$/
const DEFAULT_TEXTAREA_HEIGHT = '88px'
const maxLength = 500
const EMPTY_PARTNER_TRANSFER_USER = {
  id: '',
  userName: '',
  notes: '',
  transferUserKeys: [],
}

const EMPTY_TRANSFER_USER_KEY = {
  id: '',
  sshPublicKeyBody: '',
} as TTransferUserKeys

const DynamicTextArea = (props: TextAreaProps) => {
  const { onInput, onFocus, onBlur, label } = props
  const [isScrolled, setIsScrolled] = useState(false)

  const setTextAreaHeight = (e: BaseSyntheticEvent, padding = 0) => {
    const target = e.target as HTMLTextAreaElement
    const { scrollHeight } = target
    target.style.height = 'auto'
    target.style.height = `${scrollHeight + padding}px`
  }

  const resetTextAreaHeight = (e: BaseSyntheticEvent) => {
    const target = e.target as HTMLTextAreaElement
    target.style.height = 'auto'
    target.style.height = `${DEFAULT_TEXTAREA_HEIGHT}px`
  }

  const handleInput = (e: FocusEvent<HTMLTextAreaElement>) => {
    setTextAreaHeight(e)
    if (onInput) onInput(e)
  }

  const handleFocus = (e: FocusEvent<HTMLTextAreaElement>) => {
    setTextAreaHeight(e, 15)
    if (onFocus) onFocus(e)
  }

  const handleBlur = (e: FocusEvent<HTMLTextAreaElement>) => {
    resetTextAreaHeight(e)
    if (onBlur) onBlur(e)
  }

  const handleScroll = (e: BaseSyntheticEvent) => {
    const target = e.target as HTMLTextAreaElement
    if (target.scrollTop > 0) {
      setIsScrolled(true)
    } else {
      setIsScrolled(false)
    }
  }
  const debouncedScrollHandler = useDebouncedCallback(handleScroll, 20)

  return (
    <TextArea
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      label={isScrolled ? '' : label}
      onInput={handleInput}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onScroll={debouncedScrollHandler}
    />
  )
}

const ErrorContainer = ({ errorMessage }: { errorMessage: string }) => {
  if (!errorMessage) return null

  return (
    <Box marginBottom="12px">
      <ErrorMsg marginTop="-0.5rem">{errorMessage}</ErrorMsg>
    </Box>
  )
}

const TransferUserKeys = ({
  parentIndex,
  parentId,
  control,
  getValues,
  trigger,
  handleScheduleForDelete,
  handleUnscheduleForDelete,
  getIsScheduledforDelete,
  getParentIsScheduledforDelete,
}: TTransferUserKeysProps) => {
  const { t } = useTranslation()
  const { getErrorMessage } = useErrorMessageForField()
  const transferUserKeys = `partnerTransferUsers.${parentIndex}.transferUserKeys` as const
  const { fields, append, remove } = useFieldArray({
    control,
    name: transferUserKeys,
  })
  const fieldsCount = fields.length
  const parentIsScheduledForDelete = !!(
    parentId && getParentIsScheduledforDelete(parentId)
  )

  const labelText = t('transferUserKeys.ssh_public_key')

  useEffect(() => {
    if (!fieldsCount) {
      append({ ...EMPTY_TRANSFER_USER_KEY })
    }
  }, [fieldsCount, append])

  const handleAdd = () => {
    trigger(transferUserKeys)
      .then(isValid => {
        if (isValid) append({ ...EMPTY_TRANSFER_USER_KEY })
      })
      .catch(e => {
        /* eslint-disable-next-line */
        console.log('e ==>', e)
      })
  }

  const handleRemove = (e: BaseSyntheticEvent, index: number, id: string) => {
    e.preventDefault()

    if (!id) {
      // pop the unpersisted record from the form
      remove(index)
    } else {
      // stage the record id for deletion
      handleScheduleForDelete(id)
    }
  }

  const handleRestore = (e: BaseSyntheticEvent, index: number, id: string) => {
    e.preventDefault()
    // unstage the record id from deletion
    handleUnscheduleForDelete(id)
  }

  return (
    <FormSection title={t('partnerTransferUsers.ssh_keys_title')}>
      {fields.map((field, index) => {
        const id =
          `partnerTransferUsers.${parentIndex}.transferUserKeys.${index}.id` as const
        const fieldName =
          `partnerTransferUsers.${parentIndex}.transferUserKeys.${index}.sshPublicKeyBody` as const
        const originalId = getValues(id)
        const isScheduledForDelete = getIsScheduledforDelete(originalId)
        const canRemove = originalId || fieldsCount > 1
        const isLastField = fieldsCount === index + 1
        const shouldDisable = !!originalId || !parentId || parentIsScheduledForDelete
        const canAdd = !!originalId && !parentIsScheduledForDelete && isLastField
        const required = !(isScheduledForDelete || parentIsScheduledForDelete)

        if (!parentId) {
          // rendering the empty field here means it won't be registered and wont interfere with validation
          // of the parent unnecessarily
          return (
            <div key={field.id}>
              <TextArea
                name="empty"
                disabled
                label={labelText}
                height={DEFAULT_TEXTAREA_HEIGHT}
              />
              <Support>{t('partnerTransferUsers.ssh_public_key_support')}</Support>
            </div>
          )
        }

        return (
          <div key={field.id}>
            <Controller
              name={fieldName}
              control={control}
              rules={{ required }}
              render={({ field: { onChange, value }, formState: { errors } }) => {
                const fieldErrors = errors.partnerTransferUsers as
                  | FieldErrors<TPartnerTransferUsers>
                  | undefined
                const transferUserKeysErrors =
                  fieldErrors?.[parentIndex]?.transferUserKeys
                const error = get(transferUserKeysErrors, index, 'sshPublicKeyBody')
                const errorMessage = getErrorMessage({
                  translationKey: 'transferUserKeys',
                  error,
                  errorDetails: { name: t('transferUserKeys.ssh_public_key') },
                })

                return (
                  <>
                    <DynamicTextArea
                      label={labelText}
                      name={fieldName}
                      handleChange={onChange}
                      disabled={shouldDisable}
                      value={value}
                      height={DEFAULT_TEXTAREA_HEIGHT}
                    />
                    <ErrorContainer errorMessage={errorMessage} />

                    {!parentId && (
                      <Box marginBottom="24px">
                        <Support>
                          {t('partnerTransferUsers.ssh_public_key_support')}
                        </Support>
                      </Box>
                    )}
                  </>
                )
              }}
            />

            <Flex
              justifyContent={canRemove ? 'space-between' : 'flex-end'}
              alignItems="flex-start"
            >
              {canRemove &&
                (isScheduledForDelete ? (
                  <StyledRemoveButton onClick={e => handleRestore(e, index, originalId)}>
                    {t('transferUserKeys.undelete_ssh_key')}
                  </StyledRemoveButton>
                ) : (
                  <StyledRemoveButton onClick={e => handleRemove(e, index, originalId)}>
                    {originalId
                      ? t('transferUserKeys.delete_ssh_key')
                      : t('partnerTransferUsers.remove')}
                  </StyledRemoveButton>
                ))}

              {canAdd && (
                <Button
                  variant="outline"
                  color="dark"
                  onClick={handleAdd}
                  isDisabled={parentIsScheduledForDelete}
                >
                  {t('partnerTransferUsers.add_another_ssh_key')}
                </Button>
              )}
            </Flex>
          </div>
        )
      })}
    </FormSection>
  )
}

const PartnerTransferUsersForm = ({
  partner,
  partnerTransferUsers,
}: TPartnerTransferUsersFormProps) => {
  const { t } = useTranslation()
  const { getErrorMessage } = useErrorMessageForField()
  const [idsToDelete, setIdsToDelete] = useState<string[]>([])
  const [transferUserKeyIdsToDelete, setTransferUserKeyIdsToDelete] = useState<string[]>(
    [],
  )
  const [submitComplete, setSubmitComplete] = useState(false)

  const handleSubmitComplete = () => {
    setIdsToDelete([])
    setTransferUserKeyIdsToDelete([])
    setSubmitComplete(true)
  }

  useEffect(() => {
    const handler = setTimeout(() => {
      if (submitComplete) {
        setSubmitComplete(false)
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    }, 1200)

    return () => {
      clearTimeout(handler)
    }
  }, [submitComplete])

  const hasTransferUsersToDelete = !!idsToDelete.length
  const hasTransferUserKeysToDelete = !!transferUserKeyIdsToDelete.length
  const { id: partnerId, name: partnerName } = partner

  const { createPartnerTransferUser, loading: createPartnerTransferUserLoading } =
    usePartnerTransferUserCreate({
      partnerId,
    })

  const { updatePartnerTransferUser, loading: updatePartnerTransferUserLoading } =
    usePartnerTransferUserUpdate({
      partnerId,
    })

  const { deletePartnerTransferUser, loading: deletePartnerTransferUserLoading } =
    usePartnerTransferUserDelete({
      partnerId,
    })

  const { createTransferUserKey, loading: createTransferUserKeyLoading } =
    useTransferUserKeyCreate({
      partnerId,
    })

  const { deleteTransferUserKey, loading: deleteTransferUserKeyLoading } =
    useTransferUserKeyDelete({ partnerId })

  const { addAlert } = useAlerts()
  const { handlePromises } = useHandlePromises()

  const partnerTransferUsersData = useMemo(
    () => partnerTransferUsers || [],
    [partnerTransferUsers],
  )

  const defaultValues = useMemo(
    () => ({
      partnerTransferUsers: partnerTransferUsersData?.length
        ? partnerTransferUsersData
        : [EMPTY_PARTNER_TRANSFER_USER],
    }),
    [partnerTransferUsersData],
  )

  const { control, formState, getValues, trigger, handleSubmit, reset, setError, watch } =
    useForm<TFormValues>({
      defaultValues,
    })

  const { dirtyFields } = formState

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'partnerTransferUsers',
  })

  const getDirtyTransferUsers = (
    data: Partial<UseFormStateReturn<TFormValues>['dirtyFields']>,
  ) => {
    if (!data || !Array.isArray(data.partnerTransferUsers)) {
      return false
    }

    return data.partnerTransferUsers.some(transferUser => {
      return ['userName', 'notes'].some(fieldName => {
        return !!transferUser[fieldName as keyof TPartnerTransferUser]
      })
    })
  }

  // have to derive a more accurate isDirty check than formState.isDirty provides
  const watchedFields = watch('partnerTransferUsers')
  const getDirtyTransferKeys = watchedFields
    .reduce((acc, transferUser) => {
      if (!transferUser?.transferUserKeys) return acc

      transferUser?.transferUserKeys.forEach(transferUserKey => {
        acc.push(transferUserKey)
      })
      return acc
    }, [] as TTransferUserKeys[])
    .filter(transferKey => {
      return !transferKey.id && !!transferKey.sshPublicKeyBody
    })

  const hasDirtyTransferUsers = getDirtyTransferUsers(dirtyFields)
  const hasDirtyTransferKeys = getDirtyTransferKeys.length > 0
  const hasDirtyValues = hasDirtyTransferUsers || hasDirtyTransferKeys

  const isLoading =
    updatePartnerTransferUserLoading ||
    createPartnerTransferUserLoading ||
    deletePartnerTransferUserLoading ||
    createTransferUserKeyLoading ||
    deleteTransferUserKeyLoading

  const shouldDisableSftpSave =
    (!hasTransferUsersToDelete && !hasTransferUserKeysToDelete && !hasDirtyValues) ||
    isLoading

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues, reset])

  const handleAdd = () => {
    trigger()
      .then(isValid => {
        if (isValid) append(EMPTY_PARTNER_TRANSFER_USER)
      })
      .catch(e => {
        /* eslint-disable-next-line */
        console.log('e ==>', e)
      })
  }

  const handleRemove = (e: BaseSyntheticEvent, index: number, id: string) => {
    e.preventDefault()
    // see comment in ParnterContactsForm handleRemoveContact

    if (!id) {
      // pop the unpersisted record from the form
      remove(index)
    } else {
      // stage the record id for deletion
      setIdsToDelete(prevState => {
        return [...prevState, id]
      })
    }
  }

  const handleUnremove = (id: string) => {
    setIdsToDelete(prevState => prevState.filter(entry => entry !== id))
  }

  const handleDeleteTransferUserKeys = () => {
    const promises = transferUserKeyIdsToDelete.map(id => {
      return deleteTransferUserKey({ transferUserKeyId: id })
    })

    handlePromises({
      promises,
      errorMessageTranslationKey: 'transferUserKeys.delete_failed',
      successMessageTranslationKey: 'transferUserKeys.delete_succeeded',
      handleMessages: addAlert,
      handleMessagesVariables: { name: partnerName },
    })
  }

  const handleDeletePartnerTransferUsers = () => {
    const promises = idsToDelete.map(id => {
      return deletePartnerTransferUser({ partnerTransferUserId: id })
    })

    handlePromises({
      promises,
      errorMessageTranslationKey: 'partnerTransferUsers.delete_failed',
      successMessageTranslationKey: 'partnerTransferUsers.delete_succeeded',
      handleMessages: addAlert,
      handleMessagesVariables: { name: partnerName },
    })
  }

  const handleCreateTransferUserKeys = () => {
    const { partnerTransferUsers: partnerTransferUsersFields } = getValues()
    const dirtyRecords = dirtyFields && dirtyFields.partnerTransferUsers

    if (!dirtyRecords) return

    const initialValue = [] as Partial<
      TransferUserKeyInput & { fieldName: TransferKeyKeySshFieldName }
    >[]
    const dataToCommit = dirtyRecords.reduce((acc, entry, index) => {
      // typeguard needed because getValues return types all fields as possibly boolean
      const isTransferUserKeyArray = (
        value: unknown,
      ): value is TPartnerTransferUser['transferUserKeys'] => Array.isArray(value)

      if (isTransferUserKeyArray(entry?.transferUserKeys)) {
        entry?.transferUserKeys?.forEach((transferUserKey, transferUserKeyIndex) => {
          if (transferUserKey?.sshPublicKeyBody) {
            const transferUserId = partnerTransferUsersFields[index]?.id

            const fieldName =
              /* eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion */
              `partnerTransferUsers.${index}.transferUserKeys.${transferUserKeyIndex}.sshPublicKeyBody` as TransferKeyKeySshFieldName
            const data =
              partnerTransferUsersFields?.[index]?.transferUserKeys?.[
                transferUserKeyIndex
              ]
            if (data) acc.push({ ...data, transferUserId, fieldName })
          }
        })
      }

      return acc
    }, initialValue)

    const promises = dataToCommit
      .map(transferUserKeyData => {
        const {
          sshPublicKeyBody,
          transferUserId,
          fieldName: errorFieldName,
        } = transferUserKeyData
        const hasRequiredFields = !!sshPublicKeyBody && !!transferUserId

        // not rejecting promise here so we dont throw an error on promises we shouldnt be sending at all
        if (hasRequiredFields) {
          return createTransferUserKey({
            transferUserKeyInput: { sshPublicKeyBody, transferUserId },
          }).catch(err => {
            const error = err as ApolloError
            const message = error?.message
            if (err && message && errorFieldName) {
              setError(errorFieldName, {
                type: 'api',
                message,
              })
              throw error // propogate the error
            }
          })
        }

        return undefined
      })
      .filter((promise): promise is ReturnType<typeof createTransferUserKey> => !!promise)

    if (promises.length) {
      handlePromises({
        promises,
        errorMessageTranslationKey: 'transferUserKeys.create_failed',
        successMessageTranslationKey: 'transferUserKeys.create_succeeded',
        handleMessages: addAlert,
      })
    }
  }

  // TECH-ENABLEMENT - DRY up this shared logic in other array forms
  const handleChangePartnerTransferUsers = () => {
    const { partnerTransferUsers: partnerTransferUsersFields } = getValues()
    const dirtyRecords = dirtyFields && dirtyFields?.partnerTransferUsers

    if (!dirtyRecords) return

    const initialValue = { creates: [], updates: [] } as {
      creates: Partial<TPartnerTransferUser>[]
      updates: Partial<TPartnerTransferUser>[]
    }

    const recordsToCommit = dirtyRecords.reduce((acc, entry, index) => {
      const record = partnerTransferUsersFields[index]
      if (record) {
        // blank forms will pass the dirtiness check b/c of nested data appearing truthy
        // so only check that the fields we care about are dirty
        const editableFields = ['userName', 'notes']
        const hasDirtyFields = editableFields.some(
          field => !!entry[field as keyof TPartnerTransferUser],
        )

        if (hasDirtyFields) {
          if (record?.id) {
            acc.updates.push(record)
          } else {
            acc.creates.push(record)
          }
        }
      }

      return acc
    }, initialValue)

    // --- UPDATES ---
    const updatePromises = recordsToCommit.updates.map(record => {
      /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
      const { id: partnerTransferUserId, notes } = record
      const hasRequiredFields = !!partnerTransferUserId && !!notes

      if (!hasRequiredFields) return Promise.reject()

      return updatePartnerTransferUser({
        partnerTransferUserUpdateInput: { notes },
        partnerTransferUserId,
      })
    })

    handlePromises({
      promises: updatePromises,
      errorMessageTranslationKey: 'partnerTransferUsers.update_failed',
      successMessageTranslationKey: 'partnerTransferUsers.update_succeeded',
      handleMessages: addAlert,
    })

    // --- CREATES ---
    const createPromises = recordsToCommit.creates.map(transferUserData => {
      /* eslint-disable @typescript-eslint/no-unused-vars */
      const { notes, userName } = transferUserData
      const hasRequiredFields = !!userName

      if (!hasRequiredFields) return Promise.reject()

      return createPartnerTransferUser({ notes, userName })
    })

    handlePromises({
      promises: createPromises,
      errorMessageTranslationKey: 'partnerTransferUsers.create_failed',
      successMessageTranslationKey: 'partnerTransferUsers.create_succeeded',
      handleMessages: addAlert,
    })
  }
  const handlePartnerTransferUsersSubmit: SubmitHandler<{
    partnerTransferUsers: TPartnerTransferUsers
  }> = () => {
    if (hasTransferUserKeysToDelete) {
      handleDeleteTransferUserKeys()
    }
    handleCreateTransferUserKeys()
    handleChangePartnerTransferUsers()
    if (hasTransferUsersToDelete) {
      handleDeletePartnerTransferUsers()
    }
    handleSubmitComplete()
  }

  const submitForm = (e: BaseSyntheticEvent) => {
    // how to only validate ssh keys if parent is not scheduled for delete?
    handleSubmit(handlePartnerTransferUsersSubmit)(e).catch(err => {
      /* eslint-disable-next-line */
      console.log('err ==>', err)
    })
  }

  return (
    <Box marginTop="48px">
      <StyledHeadline>{t('partnerTransferUsers.section_title')}</StyledHeadline>
      <StyledForm data-testid="partner-transfer-users-form">
        <form onSubmit={submitForm}>
          <FormSection>
            {fields.map((field, index) => {
              const { id: fieldId } = field
              const id = `partnerTransferUsers.${index}.id` as const
              const originalId = getValues(id)
              const isScheduledForRemove = idsToDelete.indexOf(originalId) > -1
              const canRemove = originalId || fields.length > 1

              return (
                <StyledMultiFieldContainer key={fieldId} data-testid="formsection">
                  <FormSection
                    title={t('partnerTransferUsers.transfer_user_section_title')}
                  >
                    <Controller
                      name={`partnerTransferUsers.${index}.userName`}
                      control={control}
                      rules={{ required: true, pattern: USERNAME_REGEX }}
                      render={({ field: { onChange, value }, formState: { errors } }) => {
                        const error = get(errors?.partnerTransferUsers, index, 'userName')
                        const errorMessage = getErrorMessage({
                          translationKey: 'partnerTransferUsers',
                          error,
                          errorDetails: {
                            name: t('partnerTransferUsers.username'),
                            details: t(
                              'partnerTransferUsers.errors.username_format_details',
                            ),
                          },
                        })

                        return (
                          <>
                            <Body size="small">
                              {t('partnerTransferUsers.username_locked')}
                            </Body>
                            <Input
                              name={`partnerTransferUsers.${index}.userName`}
                              onChange={onChange}
                              disabled={!!originalId || isScheduledForRemove}
                              value={value}
                              label={t('partnerTransferUsers.username_required')}
                            />
                            <ErrorContainer errorMessage={errorMessage} />
                          </>
                        )
                      }}
                    />
                    <Controller
                      name={`partnerTransferUsers.${index}.notes`}
                      control={control}
                      rules={{ maxLength }}
                      render={({ field: { onChange, value }, formState: { errors } }) => {
                        const error = get(errors?.partnerTransferUsers, index, 'notes')
                        const errorMessage = getErrorMessage({
                          translationKey: 'partnerTransferUsers',
                          error,
                          errorDetails: {
                            name: t('partnerTransferUsers.notes'),
                            maxLength,
                          },
                        })

                        return (
                          <>
                            <DynamicTextArea
                              label={t('partnerTransferUsers.notes_placeholder')}
                              name={`partnerTransferUsers.${index}.notes`}
                              handleChange={onChange}
                              disabled={isScheduledForRemove}
                              value={value}
                              height={DEFAULT_TEXTAREA_HEIGHT}
                            />
                            <ErrorContainer errorMessage={errorMessage} />
                          </>
                        )
                      }}
                    />
                  </FormSection>

                  <TransferUserKeys
                    parentIndex={index}
                    parentId={originalId}
                    control={control}
                    getValues={getValues}
                    trigger={trigger}
                    handleScheduleForDelete={idToDelete =>
                      setTransferUserKeyIdsToDelete(prevState => {
                        return [...prevState, idToDelete]
                      })
                    }
                    handleUnscheduleForDelete={idToRestore =>
                      setTransferUserKeyIdsToDelete(prevState =>
                        prevState.filter(keyId => keyId !== idToRestore),
                      )
                    }
                    getParentIsScheduledforDelete={partnerTransferUserId =>
                      idsToDelete.indexOf(partnerTransferUserId) !== -1
                    }
                    getIsScheduledforDelete={userKeyId =>
                      transferUserKeyIdsToDelete.indexOf(userKeyId) !== -1
                    }
                  />

                  <FormSection>
                    {isScheduledForRemove && (
                      <StyledRemoveButton onClick={() => handleUnremove(originalId)}>
                        {t('partnerTransferUsers.undo')}
                      </StyledRemoveButton>
                    )}

                    {!isScheduledForRemove && canRemove && (
                      <StyledRemoveButton
                        onClick={e => handleRemove(e, index, originalId)}
                        data-testid={`removePartnerTransferUsers${index + 1}`}
                      >
                        {t('partnerTransferUsers.remove_partner_transfer_user')}
                      </StyledRemoveButton>
                    )}
                  </FormSection>
                </StyledMultiFieldContainer>
              )
            })}
          </FormSection>

          <StyledFormActionsContainer>
            <Flex marginTop="16px" justifyContent="flex-end">
              <Box marginRight="16px">
                <Button variant="outline" color="dark" onClick={handleAdd}>
                  {t('partnerTransferUsers.add_another_partner_transfer_user')}
                </Button>
              </Box>
              <Button
                type="submit"
                isDisabled={shouldDisableSftpSave}
                isLoading={isLoading}
              >
                {t('partnerTransferUsers.save_sftp')}
              </Button>
            </Flex>
          </StyledFormActionsContainer>
        </form>
      </StyledForm>
    </Box>
  )
}

export { PartnerTransferUsersForm }
