import { useMutation } from '@apollo/client'
import {
  PartnerTransferUserUpdate,
  PartnerTransferUserUpdateVariables,
} from 'data/mutations/types/PartnerTransferUserUpdate'
import { UPDATE_PARTNER_TRANSFER_USER } from '../mutations'
import { GET_PARTNER_TRANSFER_USERS } from '../queries'

type TID = {
  partnerId: string
}

const usePartnerTransferUserUpdate = ({ partnerId }: TID) => {
  const [mutate, { loading, error }] = useMutation<PartnerTransferUserUpdate>(
    UPDATE_PARTNER_TRANSFER_USER,
    {
      refetchQueries: [{ query: GET_PARTNER_TRANSFER_USERS, variables: { partnerId } }],
    },
  )

  const updatePartnerTransferUser = ({
    partnerTransferUserId,
    partnerTransferUserUpdateInput,
  }: PartnerTransferUserUpdateVariables) => {
    return mutate({
      variables: {
        partnerTransferUserId,
        partnerTransferUserUpdateInput,
      },
    })
  }

  return { updatePartnerTransferUser, error, loading }
}

export { usePartnerTransferUserUpdate }
