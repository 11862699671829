import { useAuth0 } from '@auth0/auth0-react'
import { Redirect, useHistory } from 'react-router-dom'
import {
  LOGIN_ROUTE,
  DEFAULT_REDIRECT,
  ROOT,
  REDIRECT_PARAM,
} from 'utils/constants/admin'

const Home = () => {
  const { isAuthenticated } = useAuth0()
  const history = useHistory()
  const { pathname } = history.location
  const hasPathname = pathname.length > 1
  const search = hasPathname ? `?${REDIRECT_PARAM}=${pathname}` : ''

  const loginWithRouteData = {
    pathname: LOGIN_ROUTE,
    search,
  }

  return (
    <Redirect
      exact
      from={ROOT}
      to={isAuthenticated ? DEFAULT_REDIRECT : loginWithRouteData}
    />
  )
}

export { Home }
