import { Button, Tooltip, grey } from '@pelotoncycle/design-system'
import { useUploadLogo, usePartnerUpdate } from 'data/hooks'
import { Partner_partner as TPartner } from 'data/queries/types/Partner'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Flex } from 'ui/components'
import { StyledHeadline } from 'ui/components/Page'
import { usePartnerUpdateContext } from 'ui/components/PartnerUpdateContext'
import { FormSection } from './forms'
import { LogoUploader } from './LogoUploader'

type PartnerLogoProps = {
  partner: TPartner
}

type LogoDeleteButtonProps = {
  onClick: () => void
  isLoading: boolean
  children: React.ReactNode
}

const LogoDeleteButton = ({ onClick, isLoading, children }: LogoDeleteButtonProps) => {
  return (
    <Button size="small" onClick={onClick} isLoading={isLoading}>
      {children}
    </Button>
  )
}

const PartnerLogoManager = ({ partner }: PartnerLogoProps) => {
  const { refetch: refetchPartner } = usePartnerUpdateContext()
  const { id, logo } = partner
  const { t } = useTranslation()

  const {
    uploadFile,
    error: uploadLogoError,
    newFileUploading,
  } = useUploadLogo({
    partnerId: id,
    onSuccess: refetchPartner,
  })

  const { updatePartner, loading } = usePartnerUpdate({
    partnerId: id,
  })

  const handleDelete = () => {
    updatePartner({ input: { logo: null } }).catch(e => {
      /* eslint-disable-next-line no-console */
      console.log('error deleting logo ', e)
    })
  }

  return (
    <Box marginTop="48px">
      <StyledHeadline>{t('partner.additional_details')}</StyledHeadline>
      <FormSection
        title={t('partner.logo')}
        subtext={
          <Flex alignItems="center">
            {t('partner.logo_subtext')}
            <Tooltip textColor={grey[80]} margin="0 8px" position="right">
              {t('partner.logo_tooltip')}
            </Tooltip>
          </Flex>
        }
      >
        <LogoUploader
          logo={logo}
          onUpload={uploadFile}
          isUploading={newFileUploading}
          errorMessage={uploadLogoError}
        >
          <LogoDeleteButton onClick={handleDelete} isLoading={loading}>
            {t('partner.delete_logo')}
          </LogoDeleteButton>
        </LogoUploader>
      </FormSection>
    </Box>
  )
}

export { PartnerLogoManager }
