import { Button, LinkButton } from '@pelotoncycle/design-system'
import { ProgramInput } from 'data/types/graphql-global-types'
import { BaseSyntheticEvent } from 'react'
import { Control } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Box, Flex } from 'ui/components'
import { usePartnerUpdateContext } from 'ui/components/PartnerUpdateContext'
import { ProgramNameInput, MonthsInactiveUntilSnoozeSelect } from './Fields'
import { FormSection } from './FormSection'
import { SplitRow } from './SplitRow'
import { StyledFormActionsContainer } from './StyledFormActionsContainer'

type TProgramFormProps = {
  onSubmit: (e: BaseSyntheticEvent) => void
  control: Control<ProgramInput>
  isDisabled: boolean
  isEdit?: boolean
}

const ProgramForm = ({ onSubmit, control, isDisabled, isEdit }: TProgramFormProps) => {
  const { t } = useTranslation()
  const { getPathForPreviousStep } = usePartnerUpdateContext()
  const previousStep = getPathForPreviousStep()

  return (
    <form onSubmit={onSubmit}>
      <SplitRow>
        <FormSection
          title={t('partner.program_details')}
          subtext={t('partner.program_details_subtext')}
        >
          <Flex flexFlow="column">
            <ProgramNameInput control={control} />
          </Flex>
        </FormSection>
        <FormSection
          title={t('partner.active_pay')}
          subtext={t('partner.active_pay_subtext')}
        >
          <Flex flexFlow="column">
            <MonthsInactiveUntilSnoozeSelect control={control} />
          </Flex>
        </FormSection>
      </SplitRow>

      {isEdit && (
        <Box width="126px" marginBottom="32px" marginTop="-16px">
          <Button type="submit" width="adaptive" isDisabled={isDisabled}>
            {t('partner.save')}
          </Button>
        </Box>
      )}

      {!isEdit && (
        <StyledFormActionsContainer>
          <Box width="126px" marginRight="16px">
            <LinkButton
              color="dark"
              variant="outline"
              component={Link}
              to={previousStep}
              text={t('partner.go_back')}
              width="adaptive"
            />
          </Box>
          <Box width="126px">
            <Button type="submit" width="adaptive" isDisabled={isDisabled}>
              {t('partner.continue')}
            </Button>
          </Box>
        </StyledFormActionsContainer>
      )}
    </form>
  )
}

export { ProgramForm }
