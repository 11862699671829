import { useQuery } from '@apollo/client'
import { Partners } from 'data/queries/types/Partners'
import { GET_PARTNERS } from '../queries'
import { PartnersVariables } from '../queries/types/Partners'

type TParams = PartnersVariables & {
  readFromCache?: boolean
}

const defaultOrderBy = 'name'

const usePartners = (params: TParams = {}) => {
  const { orderBy = defaultOrderBy, readFromCache } = params
  const fetchPolicy = readFromCache ? 'cache-first' : 'network-only'

  const { loading, error, data } = useQuery<Partners>(GET_PARTNERS, {
    fetchPolicy,
    variables: { orderBy },
  })

  return {
    loading,
    error,
    data,
  }
}

export { usePartners }
