import { Button } from '@pelotoncycle/design-system'
import { Audiences, PartnerInput } from 'data/types/graphql-global-types'
import { BaseSyntheticEvent, forwardRef } from 'react'
import { Control } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  PartnerTypeSelect,
  PartnerNameInput,
  PartnerSalesforceIdInput,
  PartnerSlugInput,
} from './Fields'
import { FormSection } from './FormSection'
import { StyledFormActionsContainer } from './StyledFormActionsContainer'

type TPartnerFormProps = {
  onSubmit: (e: BaseSyntheticEvent) => void
  control: Control<PartnerInput>
  isDisabled: boolean
  partnerType?: string | null
  buttonText: string
  allowChangePartnerType?: boolean
}

const PartnerForm = forwardRef<HTMLFormElement, TPartnerFormProps>(
  (
    { onSubmit, control, isDisabled, partnerType, buttonText, allowChangePartnerType },
    ref,
  ) => {
    const { t } = useTranslation()
    const isRapidPartner = partnerType && partnerType === Audiences.partnership
    const isCorporatePartner = partnerType && partnerType === Audiences.corporate

    return (
      <form onSubmit={onSubmit} ref={ref}>
        <FormSection title={t('partner.partner_type')}>
          <PartnerTypeSelect control={control} disabled={!allowChangePartnerType} />
        </FormSection>

        {isRapidPartner && (
          <>
            <FormSection title={t('partner.info')}>
              <PartnerNameInput control={control} />
              <PartnerSlugInput control={control} />
            </FormSection>

            <StyledFormActionsContainer>
              <Button type="submit" isDisabled={isDisabled}>
                {buttonText}
              </Button>
            </StyledFormActionsContainer>
          </>
        )}

        {isCorporatePartner && (
          <FormSection title={t('partner.info')}>
            <PartnerNameInput control={control} />
            <PartnerSalesforceIdInput control={control} />
          </FormSection>
        )}
      </form>
    )
  },
)

export { PartnerForm }
