import { useMutation } from '@apollo/client'
import { ProgramUpdate } from 'data/mutations/types/ProgramUpdate'
import { UPDATE_PROGRAM } from '../mutations'
import { ProgramUpdateVariables } from '../mutations/types/ProgramUpdate'
import { GET_PARTNER } from '../queries'

type TProgramUpdateProps = {
  partnerId: string
}

const useProgramUpdate = ({ partnerId }: TProgramUpdateProps) => {
  const [mutate, { loading, error }] = useMutation<ProgramUpdate>(UPDATE_PROGRAM, {
    refetchQueries: [{ query: GET_PARTNER, variables: { id: partnerId } }],
  })

  const updateProgram = ({ input, programId }: ProgramUpdateVariables) => {
    return mutate({
      variables: { programId, input },
    })
  }

  return { updateProgram, error, loading }
}

export { useProgramUpdate }
