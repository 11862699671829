import { useAuth0 } from '@auth0/auth0-react'
import { Headline } from '@pelotoncycle/design-system'
import { useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { REDIRECT_PARAM } from 'utils/constants/admin'
import { Page, Flex, LoadingEllipsis } from '../..'

const Logout = () => {
  const { logout } = useAuth0()
  const { t } = useTranslation()
  const LOGOUT_WAIT = 1000

  const logoutUser = useCallback(() => {
    logout({ [REDIRECT_PARAM]: window.location.origin })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      logoutUser()
    }, LOGOUT_WAIT)

    return () => clearTimeout(timer)
  }, [logoutUser])

  return (
    <Page>
      <Flex alignItems="baseline">
        <Headline size="small">{t('logging_out')}</Headline>
        <LoadingEllipsis />
      </Flex>
    </Page>
  )
}

export { Logout }
