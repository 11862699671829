import { Benefits } from '../../data/queries/types/Benefits'

type TGetyByIdProps = {
  benefits?: Benefits['benefits']
  id?: string
}

const getBenefitById = ({ benefits, id }: TGetyByIdProps) => {
  if (!id || !Array.isArray(benefits)) return null

  return benefits.find(benefit => benefit.id === id) || null
}

export { getBenefitById }
