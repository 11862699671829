import { useTranslation } from 'react-i18next'

type TGetErrorMessage = {
  translationKey: string
  error?: Record<string, unknown>
  errorDetails: Record<string, unknown>
}

// TODO - update other forms to use this
export const useErrorMessageForField = () => {
  const { t } = useTranslation()

  const getErrorMessage = ({ translationKey, error, errorDetails }: TGetErrorMessage) => {
    let errorMessage = ''
    if (error) {
      const { message, type: errorType } = error
      if (message && typeof message === 'string') {
        errorMessage = message
      } else if (errorType && typeof errorType === 'string') {
        errorMessage = t(`${translationKey}.errors.${errorType}`, { ...errorDetails })
      } else {
        errorMessage = t(`${translationKey}.errors.generic`, { ...errorDetails })
      }
    }

    return errorMessage
  }

  return { getErrorMessage }
}
