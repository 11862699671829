import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'
import { NotFoundPage } from 'ui/components'
import { ProtectedRoute } from 'ui/components/@admin'
import {
  Reporting,
  Insights,
  Login,
  Logout,
  Home,
  EligiblePerson,
  Partners,
  PartnerDetail,
  PartnerCreate,
  PartnerUpdate,
} from 'ui/components/@admin/pages'
import { PartnerProvider } from 'ui/components/PartnerContext'
import { PartnerUpdateProvider } from 'ui/components/PartnerUpdateContext'
import { isBrowser } from 'utils'
import {
  LOGIN_ROUTE,
  LOGOUT_ROUTE,
  REPORTING_ROUTE,
  ELIGIBLE_PERSON_ROUTE,
  INSIGHTS_ROUTE,
  PARTNERS_ROUTE,
  PARTNER_DETAIL_ROUTE,
  PARTNER_CREATE_ROUTE,
  PARTNER_UPDATE_ROUTE,
  ROOT,
} from 'utils/constants/admin'

const Routes = () => {
  const { isAuthenticated } = useAuth0()
  const history = useHistory()

  useEffect(() => {
    const unlisten = history.listen(() => {
      if (isBrowser) {
        window.scrollTo(0, 0)
      }
    })

    return unlisten
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Switch>
      <Route exact path={LOGOUT_ROUTE}>
        <Logout />
      </Route>
      <Route exact path={LOGIN_ROUTE}>
        <Login />
      </Route>
      <Route exact path={ELIGIBLE_PERSON_ROUTE}>
        <EligiblePerson />
      </Route>
      <ProtectedRoute exact path={PARTNERS_ROUTE}>
        <Partners />
      </ProtectedRoute>
      <ProtectedRoute exact path={PARTNER_CREATE_ROUTE}>
        <PartnerCreate />
      </ProtectedRoute>
      <ProtectedRoute exact path={PARTNER_DETAIL_ROUTE}>
        <PartnerDetail />
      </ProtectedRoute>
      <ProtectedRoute path={`${PARTNER_UPDATE_ROUTE}`}>
        <PartnerUpdateProvider>
          <PartnerUpdate />
        </PartnerUpdateProvider>
      </ProtectedRoute>
      <ProtectedRoute path={REPORTING_ROUTE}>
        <Reporting />
      </ProtectedRoute>
      <ProtectedRoute path={INSIGHTS_ROUTE}>
        <PartnerProvider>
          <Insights />
        </PartnerProvider>
      </ProtectedRoute>
      <Route exact path={ROOT}>
        <Home />
      </Route>
      <Route>{isAuthenticated ? <NotFoundPage /> : <Home />}</Route>
    </Switch>
  )
}

export { Routes }
