import { useAuth0 } from '@auth0/auth0-react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { PARTNERS_ROUTE, REPORTING_ROUTE, INSIGHTS_ROUTE } from 'utils/constants/admin'
import { HeaderNavLink, Flex } from '..'
import { media } from '../../responsive'
import { LogoAdmin } from '../svg'
import { AuthButton } from './AuthButton'

const Header = () => {
  const { t } = useTranslation()
  const { isAuthenticated } = useAuth0()
  const buttonVariant = isAuthenticated ? 'outline' : 'solid'
  const location = useLocation()
  const isLoginRoute = location.pathname === '/login'

  return (
    <StyledHeader>
      <Link to="/">
        <LogoAdmin />
      </Link>

      {isAuthenticated && (
        <Nav>
          <HeaderNavLink to={PARTNERS_ROUTE}>{t('partners.title')}</HeaderNavLink>
          <HeaderNavLink to={REPORTING_ROUTE}>{t('reporting.title')}</HeaderNavLink>
          <HeaderNavLink to={INSIGHTS_ROUTE}>{t('insights.insights')}</HeaderNavLink>
        </Nav>
      )}
      {!isLoginRoute && (
        <Flex marginLeft="16px" width="118px">
          <AuthButton color="light" variant={buttonVariant} />
        </Flex>
      )}
    </StyledHeader>
  )
}

const StyledHeader = styled.header`
  margin: 0 auto;
  background-color: var(--theme-primary);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--header-height);
  padding: 0 24px 0 32px;

  a {
    white-space: nowrap;

    &:first-child {
      margin-right: 40px;
    }
  }
`

const Nav = styled.nav`
  display: flex;
  align-items: center;
  padding: 0 16px;

  & a:first-child {
    margin-bottom: 0;
  }

  ${media.atTablet`
    flex-direction: row;
    width: 100%;
  `}
`

export { Header }
