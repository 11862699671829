import { useMutation } from '@apollo/client'
import { ProgramBenefitCreate } from 'data/mutations/types/ProgramBenefitCreate'
import { CREATE_PROGRAM_BENEFIT } from '../mutations'
import { ProgramBenefitCreateVariables } from '../mutations/types/ProgramBenefitCreate'
import { GET_PARTNER } from '../queries'

type TProgramCreateProps = {
  partnerId: string
}

const useProgramBenefitCreate = ({ partnerId }: TProgramCreateProps) => {
  const [mutate, { loading, error }] = useMutation<ProgramBenefitCreate>(
    CREATE_PROGRAM_BENEFIT,
    {
      refetchQueries: [{ query: GET_PARTNER, variables: { id: partnerId } }],
    },
  )

  const createProgramBenefit = ({ input, programId }: ProgramBenefitCreateVariables) => {
    return mutate({
      variables: { input, programId },
    })
  }

  return { createProgramBenefit, error, loading }
}

export { useProgramBenefitCreate }
