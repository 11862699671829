type TParams = {
  queryParams: string
  redirectParam: string
}
const getQueryParamValue = ({ queryParams, redirectParam }: TParams): string => {
  const params = new URLSearchParams(queryParams)
  const redirect = params.get(redirectParam)

  return redirect || ''
}

export { getQueryParamValue }
