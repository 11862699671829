import { useMutation } from '@apollo/client'
import { PartnerTransferUserCreate } from 'data/mutations/types/PartnerTransferUserCreate'
import { PartnerTransferUserInput } from 'data/types/graphql-global-types'
import { CREATE_PARTNER_TRANSFER_USER } from '../mutations'
import { GET_PARTNER_TRANSFER_USERS } from '../queries'

type TID = {
  partnerId: string
}

type PartnerTransferUserCreateInputVariables = Pick<
  PartnerTransferUserInput,
  'notes' | 'userName'
>

const usePartnerTransferUserCreate = ({ partnerId }: TID) => {
  const [mutate, { loading, error }] = useMutation<PartnerTransferUserCreate>(
    CREATE_PARTNER_TRANSFER_USER,
    {
      refetchQueries: [{ query: GET_PARTNER_TRANSFER_USERS, variables: { partnerId } }],
    },
  )

  const createPartnerTransferUser = (input: PartnerTransferUserCreateInputVariables) => {
    return mutate({
      variables: {
        partnerTransferUserInput: { ...input, partnerId },
      },
    })
  }

  return { createPartnerTransferUser, error, loading }
}

export { usePartnerTransferUserCreate }
