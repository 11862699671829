import { grey } from '@pelotoncycle/design-system'
import styled from 'styled-components'

const StyledMultiFieldContainer = styled.section`
  padding: 24px 0;
  border-bottom: 1px solid ${grey[50]};

  &:first-of-type {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
`

export { StyledMultiFieldContainer }
