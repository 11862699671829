import { useMutation } from '@apollo/client'
import { ProgramCreate } from 'data/mutations/types/ProgramCreate'
import { CREATE_PROGRAM } from '../mutations'
import { ProgramCreateVariables } from '../mutations/types/ProgramCreate'
import { GET_PARTNER } from '../queries'

const useProgramCreate = ({
  partnerId,
}: {
  partnerId: ProgramCreateVariables['partnerId']
}) => {
  const [mutate, { loading, error }] = useMutation<ProgramCreate>(CREATE_PROGRAM, {
    refetchQueries: [{ query: GET_PARTNER, variables: { id: partnerId } }],
  })

  const createProgram = ({ input }: { input: ProgramCreateVariables['input'] }) => {
    return mutate({
      variables: { partnerId, input },
    })
  }

  return { createProgram, error, loading }
}

export { useProgramCreate }
