import { useTranslation } from 'react-i18next'
import { AlertVariants } from 'ui/components'
import { TAlert } from 'ui/components/Alerts/AlertsContext'

type THandlePromiseParams = {
  promises: Promise<unknown>[]
  handleMessages: (args: TAlert) => void
  successMessageTranslationKey: string
  errorMessageTranslationKey: string
  handleMessagesVariables?: Record<string, unknown>
  onSuccess?: () => void
  callOnSuccessIfFailures?: boolean
}

// fulfill a list of promises, count success/failure for
// each and handle messaging based on results
const useHandlePromises = () => {
  const { t } = useTranslation()

  const handlePromises = ({
    promises,
    handleMessages,
    successMessageTranslationKey,
    errorMessageTranslationKey,
    handleMessagesVariables = {},
    onSuccess,
    callOnSuccessIfFailures = true,
  }: THandlePromiseParams) => {
    if (promises.length) {
      let errorCount = 0
      let successCount = 0

      Promise.allSettled(promises)
        .then(results => {
          results.forEach(({ status }) => {
            if (status === 'rejected') errorCount += 1
            if (status === 'fulfilled') successCount += 1
          })

          const hasErrors = errorCount > 0
          if (hasErrors) {
            handleMessages({
              message: t(errorMessageTranslationKey, {
                count: errorCount,
                ...handleMessagesVariables,
              }),
              variant: AlertVariants.error,
              autoClose: 8000,
            })
          } else {
            handleMessages({
              variant: AlertVariants.success,
              message: t(successMessageTranslationKey, {
                count: successCount,
                ...handleMessagesVariables,
              }),
              autoClose: 8000,
            })
            const shouldCallOnSuccess =
              !hasErrors || (hasErrors && callOnSuccessIfFailures)
            if (onSuccess && shouldCallOnSuccess) onSuccess()
          }
        })
        .catch(e => {
          /* eslint-disable-next-line */
          console.log('e ==>', e)
        })
    }
  }

  return { handlePromises }
}

export { useHandlePromises }
