import { useMutation } from '@apollo/client'
import { ProgramBenefitUpdate } from 'data/mutations/types/ProgramBenefitUpdate'
import { UPDATE_PROGRAM_BENEFIT } from '../mutations'
import { ProgramBenefitUpdateVariables } from '../mutations/types/ProgramBenefitUpdate'
import { GET_PARTNER } from '../queries'

type TProgramUpdateProps = {
  partnerId: string
}

const useProgramBenefitUpdate = ({ partnerId }: TProgramUpdateProps) => {
  const [mutate, { loading, error }] = useMutation<ProgramBenefitUpdate>(
    UPDATE_PROGRAM_BENEFIT,
    {
      refetchQueries: [{ query: GET_PARTNER, variables: { id: partnerId } }],
    },
  )

  const updateProgramBenefit = ({
    input,
    programBenefitId,
  }: ProgramBenefitUpdateVariables) => {
    return mutate({
      variables: { input, programBenefitId },
    })
  }

  return { updateProgramBenefit, error, loading }
}

export { useProgramBenefitUpdate }
