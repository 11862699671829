import { Eyebrow, Label, grey, Icon } from '@pelotoncycle/design-system'
import { useEligiblePersonLookup } from 'data/hooks'
import { EligiblePersonLookup_eligiblePersonLookup as TEligiblePerson } from 'data/queries/types/EligiblePersonLookup'
import { EligiblePersonSearchFields } from 'data/types/graphql-global-types'
import { useState, useEffect, useCallback, useRef, ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { QueryResultTable, EmptyState, RenderResponse } from 'ui/components'
import { useDebounce } from 'ui/hooks'

const EligiblePersonRow = ({ person }: { person: TEligiblePerson | undefined }) => {
  if (!person) {
    return null
  }

  const hashId = person?.partner?.hashId || ''

  return (
    <LinkStyled to={`/eligible-person/${person.id}?hash=${hashId}`}>
      {`${person.firstName} ${person.lastName || ''}`}
    </LinkStyled>
  )
}

const customTransforms = {
  firstName: (arg: string, row: TEligiblePerson | undefined) => {
    return <EligiblePersonRow person={row} />
  },
}

const ReportingCheck = () => {
  const { t } = useTranslation()
  const searchRef = useRef<HTMLInputElement>(null)
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce<string>(search, 300)
  const [eligiblePersonLookup, { loading, error, data }] = useEligiblePersonLookup()
  const results = data?.eligiblePersonLookup
  const hasSearch = !!debouncedSearch
  const hasResults = !!results?.length

  const lookup = useCallback(
    (searchTerm: string) => {
      setSearch(searchTerm)
      eligiblePersonLookup({
        variables: {
          searchTerm,
          searchFields: [
            EligiblePersonSearchFields.FIRST_NAME,
            EligiblePersonSearchFields.LAST_NAME,
            EligiblePersonSearchFields.EMAIL,
          ],
          numOfResults: 25,
        },
      }).catch(e => {
        /* eslint-disable-next-line no-console */
        console.log('lookupCallback search error -> ', e)
      })
    },
    [setSearch, eligiblePersonLookup],
  )

  useEffect(() => {
    if (debouncedSearch) {
      lookup(debouncedSearch)
    }
  }, [debouncedSearch, lookup])

  return (
    <>
      <SearchContainer>
        <SearchLabel size="small">{t('reporting_check.search_label')}</SearchLabel>
        <SearchField>
          <Search
            ref={searchRef}
            value={search}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setSearch(event.target.value)
            }
          />
          <Close
            name={loading || search !== debouncedSearch ? 'spinner' : 'close'}
            display={search ? 'inline-block' : 'none'}
            onClick={() => {
              setSearch('')
              searchRef.current?.focus()
            }}
          />
        </SearchField>
      </SearchContainer>
      {hasSearch && hasResults && (
        <ResultLabel>
          {t('reporting_check.results', {
            count: results.length,
            query: debouncedSearch,
          })}
        </ResultLabel>
      )}
      {hasSearch && (
        <RenderResponse {...{ loading, error }}>
          {hasResults ? (
            <QueryResultTable
              variant="solid"
              data={results}
              columns={['firstName', 'emailAddress']}
              customHeaders={{
                firstName: 'fullName',
              }}
              transforms={customTransforms}
            />
          ) : (
            <EmptyState
              message={t('reporting_check.no_eligible_people', {
                query: debouncedSearch,
              })}
            />
          )}
        </RenderResponse>
      )}
    </>
  )
}

const LinkStyled = styled(Link)`
  &:hover {
    text-decoration: underline;
  }
`

const SearchContainer = styled.div`
  max-width: 500px;
`

const SearchLabel = styled(Eyebrow)`
  margin-bottom: 0.5rem;
`

const SearchField = styled.div`
  position: relative;
`

const Search = styled.input`
  height: 56px;
  width: 100%;
  border: 1px solid ${grey[70]};
  border-radius: 2px;
  background: white;
  margin-right: 24px;
  padding: 0 3rem 0 1rem;
  margin-bottom: 1rem;
`

const Close = styled(Icon)`
  position: absolute;
  height: 1.5rem;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
`

const ResultLabel = styled(Label)`
  margin: 1.5rem 0;
  color: ${grey[70]};
`

export { ReportingCheck }
