import { useQuery } from '@apollo/client'
import { PartnerTransferUsers } from 'data/queries/types/PartnerTransferUsers'
import { GET_PARTNER_TRANSFER_USERS } from '../queries'

type TPartnerTransferUserProps = {
  partnerId: string
}

const usePartnerTransferUsers = ({ partnerId }: TPartnerTransferUserProps) => {
  const { loading, error, data } = useQuery<PartnerTransferUsers>(
    GET_PARTNER_TRANSFER_USERS,
    {
      variables: { partnerId },
    },
  )

  return {
    loading,
    error,
    data,
  }
}

export { usePartnerTransferUsers }
