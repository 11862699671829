import { useTranslation } from 'react-i18next'
import { Switch, Redirect, useParams } from 'react-router-dom'
import { StepNavigation, EmptyState, LoadingScreen, RequestError } from 'ui/components'
import { ProtectedRoute } from 'ui/components/@admin'
import {
  PARTNER_UPDATE_DETAILS_ROUTE,
  PARTNER_UPDATE_ELIGIBILITY_ROUTE,
  PARTNER_UPDATE_EMAILS_ROUTE,
  PARTNER_UPDATE_BENEFITS_ROUTE,
} from 'utils/constants/admin'
import { usePartnerUpdateContext } from '../../PartnerUpdateContext'
import {
  PartnerUpdateDetails,
  PartnerUpdateEligibility,
  PartnerUpdateEmails,
  PartnerUpdateBenefits,
} from '../pages'

type RouteParams = {
  partnerId: string
}

const PartnerUpdate = () => {
  const { partnerId } = useParams<RouteParams>()
  const { t } = useTranslation()
  const { partner, loading, error, steps } = usePartnerUpdateContext()

  if (loading) return <LoadingScreen />
  if (error) return <RequestError error={error} />
  if (!partner) return <EmptyState message={t('partner.not_found')} />
  if (!partner.partnerType)
    return <EmptyState message={t('partner.partner_type_error')} />

  return (
    <>
      <StepNavigation steps={steps} />

      <Switch>
        <ProtectedRoute exact path={PARTNER_UPDATE_DETAILS_ROUTE}>
          <PartnerUpdateDetails />
        </ProtectedRoute>
        <ProtectedRoute exact path={PARTNER_UPDATE_ELIGIBILITY_ROUTE}>
          <PartnerUpdateEligibility />
        </ProtectedRoute>
        <ProtectedRoute exact path={PARTNER_UPDATE_EMAILS_ROUTE}>
          <PartnerUpdateEmails />
        </ProtectedRoute>
        <ProtectedRoute exact path={PARTNER_UPDATE_BENEFITS_ROUTE}>
          <PartnerUpdateBenefits />
        </ProtectedRoute>
        <ProtectedRoute>
          {/* default to partner update details page */}
          <Redirect to={`/partners/${partnerId}/update/details`} />
        </ProtectedRoute>
      </Switch>
    </>
  )
}

export { PartnerUpdate }
