import { useAuth0 } from '@auth0/auth0-react'
import { ReactNode } from 'react'
import { Route, RouteProps } from 'react-router-dom'
import { Home } from './pages/Home'

type ProtectedRouteProps = RouteProps & {
  children: ReactNode
}

const ProtectedRoute = ({ children, ...rest }: ProtectedRouteProps) => {
  const { isAuthenticated } = useAuth0()

  /* eslint-disable react/jsx-props-no-spreading */
  return <Route {...rest}>{isAuthenticated ? children : <Home />}</Route>
}

export { ProtectedRoute }
