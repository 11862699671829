import { yellow, Button, SingleSelect } from '@pelotoncycle/design-system'
import {
  useEnrollmentReportSummary,
  useEnrollmentReportsForDateRange,
  useApproveReports,
} from 'data/hooks'
import { ReportsByDate_reportsByDate as TReport } from 'data/queries/types/ReportsByDate'
import { ReportTypes } from 'data/types/graphql-global-types'
import { format } from 'date-fns'
import sub from 'date-fns/sub'
import { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Box, RenderResponse, EmptyState } from 'ui/components'
import { useAlerts, AlertVariants } from 'ui/components/Alerts'
import { useDownloadInBrowser } from 'ui/hooks'
import { formatMonthYear, formatDatepickerDate, MONTHS } from 'utils'
import { QueryResultTable, InlineStatus } from '../..'
import { media } from '../../../responsive'
import { BangCircle, CheckCircleFill } from '../../svg'

type TArrayOfDataKeys<T> = (keyof T)[]

// TODO
// - get some questions answered= can we get the data we need for Partners table?
//    - account_manager?
//    - setup_status (computed off of...what values and is this also determined by partner_type?)
//    - enrolled/eligible?
// enforce that column values must be keys of data
// - make a 'variant' prop for the QueryResultTable so you can style it like the mock and not have to use the old Table? e.g. variant=outline|solid
const columns: TArrayOfDataKeys<TReport> = [
  'partnerName',
  'startDate',
  'rowCount',
  'approved',
]

const customTransforms = {
  // have to cast param as string instead of deriving value from TReport['startDate'] b/c startDate is a custom date scalar, which gets typed as any
  startDate: (value: string) => {
    return `${formatMonthYear(value)}`
  },

  approved: (value: TReport['approved']) => {
    return value ? (
      <InlineStatus text="Approved">
        <CheckCircleFill />
      </InlineStatus>
    ) : (
      <InlineStatus text="Not Approved">
        <BangCircle fill={yellow[80]} />
      </InlineStatus>
    )
  },
}

const now = new Date()
const thisYear = now.getUTCFullYear()
const lastYear = `${thisYear - 1}`
const nextYear = `${thisYear + 1}`
const currentYear = `${thisYear}`
const YEARS = [lastYear, currentYear, nextYear]

type Month = typeof MONTHS[number]

const ReportingEEL = () => {
  const { t } = useTranslation()
  const { addAlert } = useAlerts()
  const lastMonth = sub(new Date(), { months: 1 })
  const lastMonthName = format(lastMonth, 'MMMM')
  const lastYearName = format(lastMonth, 'yyyy')
  const [month, setMonth] = useState(lastMonthName)
  const [year, setYear] = useState(lastYearName)

  const date = formatDatepickerDate(new Date(`${month} 01, ${year}`))

  const queryVariables = {
    date,
    reportType: ReportTypes.EER,
  }
  const { loading, error, data } = useEnrollmentReportsForDateRange(queryVariables)

  const {
    getReport,
    reset,
    data: reportData,
    loading: isDownloadingReport,
    error: reportDownloadError,
  } = useEnrollmentReportSummary()
  const { url } = reportData

  const {
    approveReports,
    error: reportApprovalError,
    loading: approveLoading,
  } = useApproveReports(queryVariables)

  useDownloadInBrowser({ url, error: reportDownloadError })

  const reportsData = data?.reportsByDate || []
  const noResults = !month || !year || !reportsData.length

  const handleMonthChange = (value: Month) => {
    if (value) {
      setMonth(value)
    }
  }

  const handleYearChange = (value: string) => {
    if (value) {
      setYear(value)
    }
  }

  const approveReportsById = () => {
    const reportIds = reportsData.map(report => report.id)
    approveReports({ variables: { reportIds } }).catch(err => {
      /* eslint-disable-next-line no-console */
      console.log('Report approval error: ', err)
    })
  }

  const downloadSummaryReport = () => {
    getReport({ date })
      .catch(err => {
        /* eslint-disable-next-line no-console */
        console.log('Report download error: ', err)
      })
      .finally(() => {
        setTimeout(reset, 0)
      })
  }

  const addErrorAlert = useCallback((message: string) => {
    addAlert({
      variant: AlertVariants.error,
      message,
      autoClose: 8000,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const downloadError = t('reporting.download_error')
  useEffect(() => {
    if (reportDownloadError) {
      addErrorAlert(downloadError)
    }
  }, [reportDownloadError, addErrorAlert, downloadError])

  const approvalError = t('reporting.approval_error')
  useEffect(() => {
    if (reportApprovalError) {
      addErrorAlert(approvalError)
    }
  }, [reportApprovalError, addErrorAlert, approvalError])

  return (
    <div data-testid="reporting-eel">
      <ActionsContainer>
        <FlexTop>
          {/* DS SingleSelects are throwing 'Cant perform React state update on an unmounted component' warnings */}
          <SingleSelect
            data-testid="monthSelect"
            label={t('reporting.month')}
            items={MONTHS}
            width="168px"
            selectedItem={month}
            handleSelectedItemChange={handleMonthChange}
          />
          <SingleSelect
            data-testid="yearSelect"
            label={t('reporting.year')}
            items={YEARS}
            width="168px"
            selectedItem={year}
            handleSelectedItemChange={handleYearChange}
          />
        </FlexTop>
        <FlexBottom>
          <Box minWidth="323px">
            <Button
              data-testid="generate-report"
              width="adaptive"
              text={t('reporting.generate_reconciliation_report')}
              isDisabled={noResults || isDownloadingReport}
              isLoading={isDownloadingReport}
              onClick={downloadSummaryReport}
            />
          </Box>
          <Floater>
            <Button
              data-testid="approve-reports"
              variant="outline"
              color="dark"
              isDisabled={noResults}
              isLoading={approveLoading}
              text={t('reporting.mark_all_approved')}
              onClick={approveReportsById}
            />
          </Floater>
        </FlexBottom>
      </ActionsContainer>
      <RenderResponse {...{ loading, error }}>
        {reportsData.length ? (
          <QueryResultTable
            testId="reporting-eel-table"
            data={reportsData}
            columns={columns}
            transforms={customTransforms}
          />
        ) : (
          <EmptyState message={t('reporting.no_reports')} />
        )}
      </RenderResponse>
    </div>
  )
}

const FlexTop = styled.div`
  display: flex;
  margin-bottom: 16px;
  & > .singleSelectWithError {
    margin-right: 16px;
  }
`

const FlexBottom = styled.div`
  display: flex;
  width: 100%;
`

const ActionsContainer = styled.div`
  margin-bottom: 48px;

  ${media.atDesktop`
    display: flex;
    width: 100%;
  `}
`

const Floater = styled.div`
  margin-left: 16px;

  // TBD - Design decision
  // only want to do this if we break Page comp. out to wider
  // max-width (1200px), which will probs break most snaps and need
  // padding updates in Page comp as well
  // @media (min-width: 1400px) {
  //   margin-left: auto;
  // }
`

export { ReportingEEL }
