import { Eyebrow, Label, LinkButton, grey } from '@pelotoncycle/design-system'
import { usePartner, usePartnerTransferUsers } from 'data/hooks'
import { Partner_partner_program as TProgram } from 'data/queries/types/Partner'
import { sortProgramBenefitsByCountry } from 'data/selectors'
import { ProgramBenefitCountry } from 'data/types/graphql-global-types'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, Link } from 'react-router-dom'
import styled from 'styled-components'
import {
  Box,
  EmptyState,
  RenderResponse,
  Page,
  LinkBackTo,
  Field,
  Flex,
  Number,
} from 'ui/components'
import { Edit } from 'ui/components/svg'
import {
  deserializePartner,
  utcDate,
  formatDate,
  formatUtcDate,
  formatTime,
  getAltBenefitName,
} from 'utils'
import { PARTNERS_ROUTE } from 'utils/constants/admin'

type RouteParams = {
  partnerId: string
}

type TSectionContainerProps = {
  stepNumber: string
  title: string
  linkTo: string
  children: ReactNode
  testId?: string
  disableEditing?: boolean
}

type TInfoSectionProps = {
  title: string
  children?: ReactNode
  testId?: string
}

type TProgramBenefits = TProgram['programBenefits']

const SectionContainer = ({
  stepNumber,
  title,
  linkTo,
  children,
  testId,
  disableEditing,
}: TSectionContainerProps) => {
  return (
    <StyledSectionContainer data-testid={testId}>
      <Flex alignItems="baseline">
        <Number color={grey[90]} borderColor={grey[90]}>
          {stepNumber}
        </Number>
        <Box margin="0 16px 0 8px">
          <Eyebrow size="large">{title}</Eyebrow>
        </Box>
        {!disableEditing && (
          <Link to={linkTo}>
            <Edit />
          </Link>
        )}
      </Flex>
      {children}
    </StyledSectionContainer>
  )
}

const InfoSection = ({ title, testId, children }: TInfoSectionProps) => {
  return (
    <StyledInfoContainer data-testid={testId}>
      <Eyebrow size="small">{title}</Eyebrow>
      {children}
    </StyledInfoContainer>
  )
}

const ProgramBenefitsDisplay = ({
  programBenefits,
}: {
  programBenefits?: TProgramBenefits
}) => {
  const { t } = useTranslation()
  if (!Array.isArray(programBenefits) || programBenefits.length === 0)
    return (
      <Box>
        <Label size="large">{t('partner.no_program_benefits')}</Label>
      </Box>
    )

  const sortedProgramBenefits = sortProgramBenefitsByCountry(programBenefits)
  const countries = Object.keys(sortedProgramBenefits) as ProgramBenefitCountry[]

  return (
    <>
      {countries.map(country => {
        const programBenefitsForCountry = sortedProgramBenefits[country]

        const displayValue = programBenefitsForCountry
          .map(pb => getAltBenefitName(pb.benefit.name) || '')
          .sort()
          .join(', ')

        return <Field key={country} label={country} value={displayValue} />
      })}
    </>
  )
}

const PartnerDetail = () => {
  const { t } = useTranslation()
  const { partnerId } = useParams<RouteParams>()
  const { data, loading, error } = usePartner({ partnerId })
  const partner = data?.partner
  const contacts = partner?.contacts || []
  const { data: partnerTransferUsersData } = usePartnerTransferUsers({ partnerId })
  const partnerTransferUsers = partnerTransferUsersData?.partnerTransferUsers || []
  const sftpUsernames = partnerTransferUsers.map(user => user.userName).join(', ')

  const welcomeEmailDate = partner?.welcomeEmailDate as string
  const terminationDate = partner?.terminationDate
  let formattedWelcomeEmailDate
  let formattedTerminationDate

  if (welcomeEmailDate) {
    formattedWelcomeEmailDate = `${formatDate(utcDate(welcomeEmailDate))} ${formatTime(
      utcDate(welcomeEmailDate),
    )} UTC`
  }
  if (terminationDate) {
    formattedTerminationDate = formatUtcDate(new Date(terminationDate))
  }

  let enrolledEngaged = '--'
  if (partner?.eligibleTotal) {
    enrolledEngaged = `${partner?.enrolledTotal || '0'} / ${partner?.eligibleTotal}`
  }

  return (
    <>
      <Box margin="32px auto -16px" maxWidth="1000px">
        <LinkBackTo to={PARTNERS_ROUTE} title={t('partner.back_to_partners')} />
      </Box>
      <Page title={partner?.name} testId="partner-detail-page">
        <RenderResponse {...{ loading, error }}>
          {partner ? (
            <PageContainer>
              <SectionContainer
                testId="details-section"
                stepNumber="1"
                title={t('partner.details')}
                linkTo={`${PARTNERS_ROUTE}/${partner.id}/update/details`}
              >
                <InfoSection title={t('partner.partner_type')}>
                  <Field label={t('partner.type')} value={partner?.partnerType} />
                </InfoSection>

                <InfoSection title={t('partner.info')}>
                  <Field label={t('partner.name')} value={partner?.name} />
                  <Field
                    label={t('partner.salesforce_customer_id')}
                    value={partner?.salesforceCustomerId}
                  />
                  <Field label={t('partner.identifier_slug')} value={partner?.slug} />
                  <Field label={t('partner.enrolled')} value={enrolledEngaged} />
                </InfoSection>

                <InfoSection title={t('partner.contact_info')} testId="contactInfo">
                  {contacts.length ? (
                    contacts.map((contact, index) => {
                      const { firstName, lastName, emailAddress } = contact
                      const isLastChild = contacts.length === index + 1

                      return (
                        <Box key={emailAddress} marginBottom={isLastChild ? '0' : '24px'}>
                          <Field label={t('partner.first_name')} value={firstName} />
                          <Field label={t('partner.last_name')} value={lastName} />
                          <Field label={t('partner.email')} value={emailAddress} />
                        </Box>
                      )
                    })
                  ) : (
                    <Box margin="8px 0">
                      <Label size="large">{t('partner.no_contacts')}</Label>
                    </Box>
                  )}
                </InfoSection>
              </SectionContainer>

              <SectionContainer
                testId="eligibility-section"
                stepNumber="2"
                title={t('partner.eligibility')}
                linkTo={`${PARTNERS_ROUTE}/${partner.id}/update/eligibility`}
              >
                <InfoSection title={t('partner.eligibility')}>
                  <Field
                    label={t('partner.eligibility_method')}
                    value={deserializePartner.eligibilityMethod(partner)}
                  />
                  <Field
                    label={t('partner.date_format')}
                    value={deserializePartner.dateFormat(partner?.dateFormat)}
                  />
                  <Field
                    label={t('partner.dbo_domains')}
                    value={deserializePartner.domains(partner?.domains)}
                  />
                  <Field label={t('partner.identifier_slug')} value={partner?.slug} />
                  <Field
                    label={t('partnerTransferUsers.sftp_usernames')}
                    value={sftpUsernames}
                  />
                  <Field
                    label={t('partner.enrollment_optimization_opt_in')}
                    value={
                      partner.enrollmentOptimization ? t('partner.yes') : t('partner.no')
                    }
                  />
                </InfoSection>
              </SectionContainer>

              <SectionContainer
                testId="emails-section"
                stepNumber="3"
                title={t('partner.email_configuration')}
                linkTo={`${PARTNERS_ROUTE}/${partner.id}/update/emails`}
              >
                <InfoSection title={t('partner.welcome_email')}>
                  <Field
                    label={t('partner.welcome_email_enabled')}
                    value={
                      partner.overrideWelcomeEmail ? t('partner.no') : t('partner.yes')
                    }
                  />
                  <Field
                    label={t('partner.welcome_email_template')}
                    value={partner?.welcomeEmailTemplate?.name}
                  />
                  <Field
                    label={t('partner.welcome_email_date')}
                    value={formattedWelcomeEmailDate}
                  />
                </InfoSection>
                <InfoSection title={t('partner.reminder_email')}>
                  <Field
                    label={t('partner.reminder_email_enabled')}
                    value={
                      partner.overrideReminderEmail ? t('partner.no') : t('partner.yes')
                    }
                  />
                  <Field
                    label={t('partner.reminder_email_template')}
                    value={partner?.reminderEmailTemplate?.name}
                  />
                  <Field
                    label={t('partner.reminder_email_days')}
                    value={partner?.reminderEmailDays}
                  />
                </InfoSection>
                <InfoSection title={t('partner.termination_date')}>
                  <Field
                    label={t('partner.termination_date')}
                    value={formattedTerminationDate}
                  />
                </InfoSection>
              </SectionContainer>

              <SectionContainer
                testId="benefits-section"
                stepNumber="4"
                title={t('partner.benefits')}
                linkTo={`${PARTNERS_ROUTE}/${partner.id}/update/benefits`}
              >
                <InfoSection title={t('partner.program')}>
                  <Field
                    label={t('partner.program_name')}
                    value={partner.program?.name}
                  />
                  <Field
                    label={t('partner.active_pay_duration')}
                    value={
                      partner.program?.monthsInactiveUntilSnooze
                        ? `${partner.program?.monthsInactiveUntilSnooze} ${t('months')}`
                        : t('partner.no_active_pay')
                    }
                  />
                </InfoSection>

                <InfoSection
                  title={t('partner.program_benefits')}
                  testId="programBenefits"
                >
                  <ProgramBenefitsDisplay
                    programBenefits={partner?.program?.programBenefits}
                  />
                </InfoSection>
              </SectionContainer>

              <Box marginTop="48px">
                <LinkButton
                  component={Link}
                  to={`${PARTNERS_ROUTE}/${partner?.id}/update`}
                  size="medium"
                  text={t('partner.update_partner')}
                />
              </Box>
            </PageContainer>
          ) : (
            <EmptyState message={`${t('partner.not_found')} for ${partnerId}`} />
          )}
        </RenderResponse>
      </Page>
    </>
  )
}

const PageContainer = styled.div`
  width: 50vw;
  max-width: 1000px;
`

const StyledSectionContainer = styled.section`
  padding: 32px 0;
  border-bottom: 1px solid ${grey[60]};

  &:first-child {
    padding-top: 0;
  }
`

const StyledInfoContainer = styled.div`
  margin-top: 32px;
`

export { PartnerDetail }
