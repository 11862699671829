import { Button } from '@pelotoncycle/design-system'
import { BaseSyntheticEvent, useState, useEffect } from 'react'
import { useFieldArray, Control, UseFormTrigger, UseFormGetValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Flex, Box } from 'ui/components'
import {
  PartnerContactFirstNameInput,
  PartnerContactLastNameInput,
  PartnerContactEmailInput,
  PartnerContactSftpNotificationInput,
  TPartnerContacts,
} from './Fields'
import { FormSection } from './FormSection'
import { SplitRow } from './SplitRow'
import { StyledMultiFieldContainer } from './StyledMultiFieldContainer'
import { StyledRemoveButton } from './StyledRemoveButton'

type TFormProps = {
  control: Control<TPartnerContacts>
  trigger: UseFormTrigger<TPartnerContacts>
  getValues: UseFormGetValues<TPartnerContacts>
  onRemoveContact?: (id: string) => void
  onUnremoveContact?: (id: string) => void
}

const PARTNER_CONTACT_DEFAULTS = {
  firstName: '',
  lastName: '',
  emailAddress: '',
  sftpNotification: false,
}

const PartnerContactsForm = ({
  control,
  trigger,
  getValues,
  onRemoveContact,
  onUnremoveContact,
}: TFormProps) => {
  const { t } = useTranslation()
  const [indecesForRemove, setIndecesForRemove] = useState<number[]>([])

  const {
    fields: contactsFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'contacts',
    rules: { required: true },
  })

  const contactsCount = contactsFields.length

  // force existing corporate partners with no contacts to add one by appending
  // and empty contact to the form
  useEffect(() => {
    if (contactsCount === 0) {
      append(PARTNER_CONTACT_DEFAULTS)
    }
    // only want to run this on mount
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  const totalScheduledFoSoftRemove = indecesForRemove.length
  const canRemove = contactsCount > 1 && contactsCount - totalScheduledFoSoftRemove > 1

  const handleAddContact = () => {
    trigger('contacts')
      .then(isValid => {
        if (isValid) append(PARTNER_CONTACT_DEFAULTS)
      })
      .catch(e => {
        /* eslint-disable-next-line */
        console.log('e ==>', e)
      })
  }

  const handleUnremoveContact = (contactIndex: number) => {
    const fieldArrayId = `contacts.${contactIndex}.id` as const
    const realId = getValues(fieldArrayId) as string
    if (onUnremoveContact) onUnremoveContact(realId)
    setIndecesForRemove(prevState => prevState.filter(item => item !== contactIndex))
  }

  const handleRemoveContact = (e: BaseSyntheticEvent, contactIndex: number) => {
    e.preventDefault()
    // useFieldArray provides a facade around form values, and it overwrites the actual object id
    // with a hashed id. You have to get the true object id using getValues.
    //
    // Store contactId(s) in parent for deletion on parent form submit
    if (canRemove) {
      // removing a contact shifts the fieldArray values, which causes
      // all items to be marked as dirty b/c their id has changed from defaultValues
      // for create, you can remove the form w/o impacting other fields b/c they'll all be dirty anyway

      const fieldArrayId = `contacts.${contactIndex}.id` as const
      const realId = getValues(fieldArrayId) as string
      if (!realId) {
        remove(contactIndex)
      } else {
        if (onRemoveContact) onRemoveContact(realId)
        setIndecesForRemove(prevState => {
          return [...prevState, contactIndex]
        })
      }
    }
  }

  // Using react-hook-form here for validations and state management. The submit
  // is handled transactionally because contacts need to be created
  // transactionally, e.g. only submitted if partner create succeeds
  return (
    <form data-testid="contactsForm">
      <FormSection
        title={t('partner.contact_info')}
        subtext={t('partner.contact_info_subtext')}
      >
        <div>
          {contactsFields.map((item, index) => {
            const isScheduledForRemove = indecesForRemove.indexOf(index) > -1

            return (
              <StyledMultiFieldContainer key={item.id}>
                <SplitRow>
                  <PartnerContactFirstNameInput
                    control={control}
                    index={index}
                    isRequired
                    disabled={isScheduledForRemove}
                  />

                  <PartnerContactLastNameInput
                    control={control}
                    index={index}
                    isRequired
                    disabled={isScheduledForRemove}
                  />
                </SplitRow>

                <PartnerContactEmailInput
                  control={control}
                  index={index}
                  isRequired
                  disabled={isScheduledForRemove}
                />

                <Box marginBottom="24px">
                  <PartnerContactSftpNotificationInput
                    control={control}
                    index={index}
                    disabled={isScheduledForRemove}
                  />
                </Box>

                {!isScheduledForRemove && canRemove && (
                  <StyledRemoveButton
                    onClick={e => handleRemoveContact(e, index)}
                    data-testid={`removeContact${index + 1}`}
                  >
                    {t('partner.remove')}
                  </StyledRemoveButton>
                )}

                {isScheduledForRemove && (
                  <StyledRemoveButton onClick={() => handleUnremoveContact(index)}>
                    {t('partner.undo')}
                  </StyledRemoveButton>
                )}

                {contactsCount === index + 1 && (
                  <Flex marginTop="16px" justifyContent="flex-end">
                    <Button variant="outline" color="dark" onClick={handleAddContact}>
                      {t('partner.add_another_contact')}
                    </Button>
                  </Flex>
                )}
              </StyledMultiFieldContainer>
            )
          })}
        </div>
      </FormSection>
    </form>
  )
}

export { PartnerContactsForm, PARTNER_CONTACT_DEFAULTS }
