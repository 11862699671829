import { useMutation } from '@apollo/client'
import {
  PartnerContactCreate,
  PartnerContactCreateVariables,
} from 'data/mutations/types/PartnerContactCreate'
import { CREATE_PARTNER_CONTACT } from '../mutations'
import { GET_PARTNER } from '../queries'

const usePartnerContactCreate = () => {
  const [mutate, { loading, error }] = useMutation<PartnerContactCreate>(
    CREATE_PARTNER_CONTACT,
    {
      refetchQueries: ({ data }) => {
        if (data?.contactCreate?.contact?.partner?.id) {
          return [
            {
              query: GET_PARTNER,
              variables: { id: data.contactCreate.contact.partner.id },
            },
          ]
        }

        return []
      },
    },
  )

  const createPartnerContact = ({ input, partnerId }: PartnerContactCreateVariables) => {
    return mutate({
      variables: { input, partnerId },
    })
  }

  return { createPartnerContact, error, loading }
}

export { usePartnerContactCreate }
