import { ErrorMessage } from '@hookform/error-message'
import {
  grey,
  Button,
  Input,
  ErrorMsg,
  SingleSelect,
  Checkbox,
  Body,
} from '@pelotoncycle/design-system'
import { useBenefits } from 'data/hooks'
import {
  Partner_partner_contacts as TContacts,
  Partner_partner_program_programBenefits as TProgramBenefit,
  Partner_partner_program_programBenefits_benefit as TProgramBenefitBenefit,
} from 'data/queries/types/Partner'
import {
  Audiences,
  PartnerInput,
  ProgramInput,
  ProgramBenefitCountry,
} from 'data/types/graphql-global-types'
import { forwardRef, ComponentPropsWithRef, FocusEvent } from 'react'
import DatePicker from 'react-datepicker'
import { Controller, Control, useFieldArray, UseFormTrigger } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Box, Flex, SelectContainer, SelectFromMappedValues } from 'ui/components'
import { Calendar, Clock } from 'ui/components/svg'
import { useMonthsInactive } from 'ui/hooks'
import {
  hasValue,
  deserializePartner,
  TDeserializedPartner,
  DATE_FORMAT_LOOKUP,
  TIMEZONES,
  formatCentsAsCurrencyString,
  formatCurrencyStringAsCents,
  getAltBenefitName,
  PRICE_FORMAT,
} from 'utils'
import { EMAIL_REGEX, EMAIL_DOMAIN_REGEX } from 'utils/constants/admin'
import { PartnerTypeRadioSelect } from './PartnerTypeRadioSelect'
import { StyledRemoveButton } from './StyledRemoveButton'
import 'react-datepicker/dist/react-datepicker.css'

type OptionalId = { id?: string }
type Disabled = { disabled?: boolean }
type IsRequired = { isRequired?: boolean }

type TDomains = { domain: string }[] | null | undefined

type TDomainsInput = { domains?: TDomains }

type DomainsTrigger = {
  trigger: UseFormTrigger<TDomainsInput>
}

type TDomainInput = { index: number; canRemove: boolean; remove: (arg: number) => void }

type TInput = {
  control: Control<Partial<PartnerInput>>
}
type TPartner = {
  control: Control<TDeserializedPartner>
}

type PartnerFormInput = Omit<PartnerInput, 'domains'> & TDomainsInput

export type FormInput = Partial<PartnerFormInput>

type TEligibilityInput = {
  control: Control<FormInput>
}

export type TPartnerContactVariables = Omit<TContacts, '__typename' | 'id'> & OptionalId
type PartialBenefit = Partial<TProgramBenefitBenefit>

export type TProgramBenefitVariables = Omit<
  TProgramBenefit,
  '__typename' | 'id' | 'currency' | 'benefit' | 'program'
> & {
  benefit: PartialBenefit
  program: { id?: string; monthsInactiveUntilSnooze?: number | null }
} & OptionalId

export type TPartnerContacts = {
  contacts: TPartnerContactVariables[]
}

export type TProgramBenefits = {
  programBenefits: TProgramBenefitVariables[]
}

type TPartnerContactProps = IsRequired & {
  control: Control<TPartnerContacts>
  index: number
  disabled?: boolean
}

type TProgramBenefitProps = {
  control: Control<TProgramBenefits>
  index: number
}

type TPartnerContactInput = {
  control: Control<TPartnerContacts>
  index: number
  fieldName: Exclude<keyof TPartnerContactVariables, 'sftpNotification'>
  displayName: string
  disabled?: boolean
  rules: { [key: string]: string | RegExp | boolean | undefined }
}

type TProgramInput = {
  control: Control<ProgramInput>
}

type TDateDisplayProps = {
  date?: Date | string | null
} & ComponentPropsWithRef<'input'>

type TEmailTemplateSelect = TPartner &
  IsRequired & {
    name: 'welcomeEmailTemplate.name' | 'reminderEmailTemplate.name'
    label: string
    items: string[]
    disabled: boolean
  }

type TAfterNDaysSelect = TPartner &
  IsRequired & {
    name: 'reminderEmailDays'
    total?: number
  }

type TDateSelect = TPartner &
  IsRequired & {
    name: 'terminationDate' | 'welcomeEmailDateDate'
    minDate: Date
  }

type TTimeSelect = TPartner & IsRequired & { name: 'welcomeEmailTime' }

type TTimezoneSelect = TPartner &
  IsRequired & {
    name: 'welcomeEmailTimezone'
  }

type TEnableEmailCheckbox = TPartner & {
  name: 'overrideWelcomeEmail' | 'overrideReminderEmail'
  text: string
}

const DOMAIN_DEFAULT = { domain: '' }

const validations = {
  isNotBlank: (val: unknown, errorMessage: string) => {
    if (typeof val === 'string' && val.trim().length > 0) return true

    return errorMessage
  },
  isNotNone: (val: unknown, errorMessage: string) => {
    if (val !== 'NONE') return true

    return errorMessage
  },
  isNotDuplicate: (
    val: string | null | undefined,
    existingVals: string[],
    errorMessage: string,
  ) => {
    if (val && existingVals.indexOf(val) !== -1) {
      return errorMessage
    }

    return true
  },
  validDomain: (domain: unknown, errorMessage: string) => {
    if (typeof domain === 'string' && EMAIL_DOMAIN_REGEX.test(domain)) return true

    return errorMessage
  },
  validPrice: (val: unknown, errorMessage: string) => {
    if (typeof val === 'number' && (val >= 100 || val === 0)) return true

    return errorMessage
  },
  dependentsHaveValue: (
    value: unknown,
    dependentValues: unknown[],
    errorMessage: string,
  ) => {
    const othersFilled = dependentValues.every(hasValue)
    if (hasValue(value) && othersFilled) {
      return errorMessage
    }

    return true
  },
}

const errMarginTop = '-0.5rem'

/* eslint-disable react/jsx-props-no-spreading */
const PartnerNameInput = ({ control }: TInput) => {
  const { t } = useTranslation()

  return (
    <Controller
      name="name"
      defaultValue=""
      control={control}
      rules={{
        validate: val =>
          validations.isNotBlank(
            val,
            t('partner.errors.isBlank', { name: 'Partner name' }),
          ),
      }}
      render={({ field, formState: { errors: fieldErrors } }) => {
        const fieldValue = field.value || ''

        return (
          <>
            <Input {...field} value={fieldValue} label={t('partner.name_required')} />
            <ErrorMessage
              name={field.name}
              errors={fieldErrors}
              render={({ message }) =>
                message && <ErrorMsg marginTop={errMarginTop}>{message}</ErrorMsg>
              }
            />
          </>
        )
      }}
    />
  )
}

const ProgramNameInput = ({ control }: TProgramInput) => {
  const { t } = useTranslation()
  const maxLength = 35

  return (
    <Controller
      name="name"
      defaultValue=""
      control={control}
      rules={{
        validate: val =>
          validations.isNotBlank(
            val,
            t('partner.errors.isBlank', { name: 'Program name' }),
          ),
        maxLength,
      }}
      render={({ field, formState: { errors: fieldErrors } }) => {
        const fieldValue = field.value || ''
        const errorType = fieldErrors?.name?.type

        return (
          <>
            <Input
              {...field}
              value={fieldValue}
              label={t('partner.program_name_required')}
            />
            <ErrorMessage
              name={field.name}
              errors={fieldErrors}
              render={({ message }) => {
                let errorMessage = message
                if (!message && errorType === 'maxLength') {
                  errorMessage = t('partner.errors.maxLength', {
                    name: t('partner.program_name'),
                    maxLength,
                  })
                }

                return (
                  errorMessage && (
                    <ErrorMsg marginTop={errMarginTop}>{errorMessage}</ErrorMsg>
                  )
                )
              }}
            />
          </>
        )
      }}
    />
  )
}

// If we lived in a just world, the SlugInput component would accept a generic type, e.g.
// type TInput<T> = { control: Control<T> }
// SlugInput = <T,>({ control }: TInput<T>)
//
// But that breaks due to react-hook-form type bugs/shortcomings:
// https://github.com/react-hook-form/react-hook-form/issues/6726

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const SlugInput = ({ control }: { control?: any }) => {
  const { t } = useTranslation()

  return (
    <Controller
      name="slug"
      defaultValue=""
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
      control={control}
      rules={{
        validate: val =>
          validations.isNotBlank(
            val,
            t('partner.errors.isBlank', { name: 'Partner slug' }),
          ),
      }}
      render={({ field, formState: { errors: fieldErrors } }) => {
        return (
          <>
            <Input {...field} label={t('partner.identifier_required')} />
            <ErrorMessage
              name={field.name}
              errors={fieldErrors}
              render={({ message }) =>
                message && <ErrorMsg marginTop={errMarginTop}>{message}</ErrorMsg>
              }
            />
          </>
        )
      }}
    />
  )
}
const PartnerSlugInput = ({ control }: TInput) => {
  return <SlugInput control={control} />
}

const PartnerSlugInput2 = ({ control }: TEligibilityInput) => {
  return <SlugInput control={control} />
}

const PartnerSalesforceIdInput = ({ control }: TInput) => {
  const { t } = useTranslation()

  return (
    <Controller
      name="salesforceCustomerId"
      defaultValue=""
      control={control}
      rules={{
        validate: val =>
          validations.isNotBlank(
            val,
            t('partner.errors.isBlank', { name: 'Salesforce ID' }),
          ),
      }}
      render={({ field, formState: { errors: fieldErrors } }) => {
        const fieldValue = field.value || ''

        return (
          <>
            <Input
              {...field}
              value={fieldValue}
              label={t('partner.salesforce_customer_id_required')}
            />
            <ErrorMessage
              name={field.name}
              errors={fieldErrors}
              render={({ message }) =>
                message && <ErrorMsg marginTop={errMarginTop}>{message}</ErrorMsg>
              }
            />
          </>
        )
      }}
    />
  )
}

const PartnerContactFirstNameInput = ({
  control,
  index,
  isRequired,
  disabled,
}: TPartnerContactProps) => {
  const { t } = useTranslation()

  return (
    <PartnerContactInput
      control={control}
      index={index}
      fieldName="firstName"
      displayName={t('partner.first_name_required')}
      disabled={disabled}
      rules={{
        required:
          isRequired &&
          t('partner.errors.required', {
            name: t('partner.first_name_label'),
          }),
      }}
    />
  )
}

const PartnerContactLastNameInput = ({
  control,
  index,
  isRequired,
  disabled,
}: TPartnerContactProps) => {
  const { t } = useTranslation()

  return (
    <PartnerContactInput
      control={control}
      index={index}
      fieldName="lastName"
      displayName={t('partner.last_name_required')}
      disabled={disabled}
      rules={{
        required:
          isRequired &&
          t('partner.errors.required', {
            name: t('partner.last_name_label'),
          }),
      }}
    />
  )
}

const PartnerContactEmailInput = ({
  control,
  index,
  isRequired,
  disabled,
}: TPartnerContactProps) => {
  const { t } = useTranslation()

  return (
    <PartnerContactInput
      control={control}
      index={index}
      fieldName="emailAddress"
      displayName={t('partner.email_required')}
      disabled={disabled}
      rules={{
        required:
          isRequired &&
          t('partner.errors.required', {
            name: t('partner.email_label'),
          }),
        pattern: EMAIL_REGEX,
      }}
    />
  )
}

const PartnerContactSftpNotificationInput = ({
  control,
  index,
  disabled,
}: Omit<TPartnerContactProps, 'isRequired'>) => {
  const { t } = useTranslation()
  const resource = 'contacts'
  const fieldName = 'sftpNotification'
  const name = `${resource}.${index}.${fieldName}` as const
  const text = t('partner.sftp_notification')

  return (
    <Controller
      name={name}
      defaultValue={false}
      control={control}
      render={({ field }) => {
        const { onChange, value } = field
        const fieldValue = !!value
        let errorMessage

        return (
          <div>
            <Checkbox
              id={name}
              name={name}
              handleChange={() => onChange(!fieldValue)}
              checked={fieldValue}
              labelText={<Body size="small">{text}</Body>}
              disabled={disabled}
            />
            {errorMessage && (
              <Box marginBottom="12px">
                <ErrorMsg marginTop={errMarginTop}>{errorMessage}</ErrorMsg>
              </Box>
            )}
          </div>
        )
      }}
    />
  )
}

const PartnerContactInput = ({
  control,
  index,
  fieldName,
  displayName,
  rules,
  disabled,
}: TPartnerContactInput) => {
  const { t } = useTranslation()
  const resource = 'contacts'
  const name = `${resource}.${index}.${fieldName}` as const

  return (
    <Controller
      name={name}
      defaultValue=""
      control={control}
      rules={rules}
      render={({ field, formState: { errors: fieldErrors } }) => {
        let errorMessage
        const errAtIndex = fieldErrors?.contacts && fieldErrors.contacts[index]
        const err = errAtIndex && errAtIndex[fieldName]

        if (err) {
          const { message, type } = err
          if (message) {
            errorMessage = message
          } else if (type && typeof type === 'string') {
            errorMessage = t(`partner.errors.${type}`, {
              name: displayName,
            })
          } else {
            errorMessage = t('partner.errors.generic', { name })
          }
        }

        return (
          <div>
            <Input {...field} label={displayName} disabled={disabled} />
            {errorMessage && (
              <Box marginBottom="12px">
                <ErrorMsg marginTop={errMarginTop}>{errorMessage}</ErrorMsg>
              </Box>
            )}
          </div>
        )
      }}
    />
  )
}

const PartnerTypeSelect = ({ control, disabled }: TInput & Disabled) => {
  return (
    <Controller
      name="partnerType"
      defaultValue=""
      control={control}
      rules={{ required: true }}
      render={({ field }) => {
        const { onChange, value } = field
        let fieldValue = ''
        if (value && value in Audiences) fieldValue = value

        return (
          <PartnerTypeRadioSelect
            onChange={onChange}
            partnerType={fieldValue}
            disabled={disabled}
          />
        )
      }}
    />
  )
}

const DateFormatSelect = ({ control, disabled }: TEligibilityInput & Disabled) => {
  const { t } = useTranslation()

  const dateFormats = Object.values(DATE_FORMAT_LOOKUP)

  return (
    <Controller
      name="dateFormat"
      control={control}
      rules={{
        // have to deal with string value of NONE as the default value from BE on this field
        validate: {
          isNotNone: val =>
            validations.isNotNone(
              val,
              t('partner.errors.required', { name: 'Date format' }),
            ),
        },
      }}
      render={({ field, formState: { errors: fieldErrors } }) => {
        const { onChange, value } = field
        const fieldValue = deserializePartner.dateFormat(value)

        return (
          <SelectContainer data-testid="dateFormatSelect">
            <SingleSelect
              label={t('partner.date_format_required')}
              items={dateFormats}
              selectedItem={fieldValue}
              handleSelectedItemChange={onChange}
              disabled={disabled}
            />
            <ErrorMessage
              name={field.name}
              errors={fieldErrors}
              render={({ message }) =>
                message && <ErrorMsg marginTop={errMarginTop}>{message}</ErrorMsg>
              }
            />
          </SelectContainer>
        )
      }}
    />
  )
}

const DomainInput = ({
  control,
  index,
  canRemove,
  remove,
}: TEligibilityInput & TDomainInput) => {
  const { t } = useTranslation()

  return (
    <Controller
      name={`domains.${index}.domain`}
      control={control}
      rules={{
        validate: {
          isNotBlank: val =>
            validations.isNotBlank(val, t('partner.errors.isBlank', { name: 'Domain' })),
          validDomain: val => validations.validDomain(val, t('partner.errors.domain')),
        },
      }}
      render={({ field, formState: { errors: fieldErrors } }) => {
        return (
          <Container>
            <Input {...field} label={t('partner.domain_required')} />
            <ErrorMessage
              name={field.name}
              errors={fieldErrors}
              render={({ message }) =>
                message && <ErrorMsg marginTop={errMarginTop}>{message}</ErrorMsg>
              }
            />

            {canRemove && (
              <StyledRemoveButton type="button" onClick={() => remove(index)}>
                {t('partner.remove')}
              </StyledRemoveButton>
            )}
          </Container>
        )
      }}
    />
  )
}

const DomainInputsSection = ({
  control,
  trigger,
}: TEligibilityInput & DomainsTrigger) => {
  const { t } = useTranslation()
  const { fields, append, remove } = useFieldArray({ control, name: 'domains' })
  const domainsCount = fields.length
  const canRemoveDomain = domainsCount > 1

  const handleAddDomain = () => {
    trigger('domains')
      .then(isValid => {
        if (isValid) append(DOMAIN_DEFAULT)
      })
      .catch(e => {
        /* eslint-disable-next-line */
        console.log('e ==>', e)
      })
  }

  return (
    <div>
      {fields.map((item, index) => {
        return (
          <DomainInput
            key={item.id}
            control={control}
            index={index}
            remove={remove}
            canRemove={canRemoveDomain}
          />
        )
      })}

      <Flex marginTop="16px" justifyContent="flex-end">
        <Button variant="outline" color="dark" onClick={handleAddDomain}>
          {t('partner.add_another_domain')}
        </Button>
      </Flex>
    </div>
  )
}

const DateDisplay = forwardRef<
  HTMLButtonElement,
  TDateDisplayProps & { testId?: string }
>(({ value, onClick, date, testId }, ref) => {
  const { t } = useTranslation()

  return (
    <StyledDateContainer onClick={onClick} data-testid={testId}>
      <button type="button" ref={ref}>
        {date ? value : t('partner.date')}
      </button>
      <Calendar fill={grey[90]} />
    </StyledDateContainer>
  )
})

const TimeDisplay = forwardRef<HTMLButtonElement, TDateDisplayProps>(
  ({ value, onClick, date }, ref) => {
    const { t } = useTranslation()

    return (
      <StyledDateContainer onClick={onClick}>
        <button type="button" ref={ref}>
          {date ? value : t('partner.time')}
        </button>
        <Clock fill={grey[90]} />
      </StyledDateContainer>
    )
  },
)

const DateSelect = ({ control, name, minDate, isRequired }: TDateSelect) => {
  const { t } = useTranslation()

  return (
    <Controller
      name={name}
      rules={{
        required:
          isRequired &&
          t('partner.errors.required', {
            name: `Email send date`,
          }),
      }}
      control={control}
      render={({ field, formState: { errors: fieldErrors } }) => {
        const { onChange, value } = field

        return (
          <>
            <DatePicker
              minDate={minDate}
              selected={value}
              customInput={<DateDisplay date={value} testId={`${name}-datepicker`} />}
              onChange={onChange}
            />

            <Box marginBottom="12px">
              <ErrorMessage
                name={field.name}
                errors={fieldErrors}
                render={({ message }) =>
                  message && <ErrorMsg marginTop={errMarginTop}>{message}</ErrorMsg>
                }
              />
            </Box>
          </>
        )
      }}
    />
  )
}

const TimeSelect = ({ control, name, isRequired }: TTimeSelect) => {
  const { t } = useTranslation()

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required:
          isRequired &&
          t('partner.errors.required', {
            name: `Email send time`,
          }),
      }}
      render={({ field, formState: { errors: fieldErrors } }) => {
        const { onChange, value } = field

        return (
          <>
            <DatePicker
              selected={value}
              customInput={<TimeDisplay date={value} />}
              onChange={onChange}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={60}
              dateFormat="h:mm aa"
              timeCaption={t('partner.time')}
            />

            <Box marginBottom="12px">
              <ErrorMessage
                name={field.name}
                errors={fieldErrors}
                render={({ message }) =>
                  message && <ErrorMsg marginTop={errMarginTop}>{message}</ErrorMsg>
                }
              />
            </Box>
          </>
        )
      }}
    />
  )
}

const EmailTemplateSelect = ({
  control,
  name,
  label,
  disabled,
  items,
  isRequired,
}: TEmailTemplateSelect) => {
  const { t } = useTranslation()

  return (
    <Controller
      name={name}
      defaultValue=""
      control={control}
      rules={{
        required:
          isRequired &&
          t('partner.errors.required', {
            name: `Email template name`,
          }),
      }}
      render={({ field, formState: { errors: fieldErrors } }) => {
        const { onChange, value } = field
        const fieldValue = value || ''

        return (
          <SelectContainer data-testid={`${name}.select`}>
            <SingleSelect
              label={label}
              disabled={disabled}
              items={items}
              selectedItem={fieldValue}
              handleSelectedItemChange={onChange}
            />
            <ErrorMessage
              name={field.name}
              errors={fieldErrors}
              render={({ message }) =>
                message && <ErrorMsg marginTop={errMarginTop}>{message}</ErrorMsg>
              }
            />
          </SelectContainer>
        )
      }}
    />
  )
}

const AfterNDaysSelect = ({
  name,
  total = 90,
  control,
  isRequired,
}: TAfterNDaysSelect) => {
  const { t } = useTranslation()
  const items = Array.from({ length: total }, (_, i) => String(i + 1))

  return (
    <Controller
      name={name}
      defaultValue={null}
      control={control}
      rules={{
        required:
          isRequired &&
          t('partner.errors.required', {
            name: t('partner.field'),
          }),
      }}
      render={({ field, formState: { errors: fieldErrors } }) => {
        const { onChange, value } = field
        const fieldValue = value || ''

        return (
          <>
            <SelectContainer>
              <SingleSelect
                label={t('partner.reminder_email_label')}
                items={items}
                selectedItem={String(fieldValue)}
                handleSelectedItemChange={(v: string) => onChange(Number(v))}
              />
            </SelectContainer>
            <ErrorMessage
              name={field.name}
              errors={fieldErrors}
              render={({ message }) =>
                message && <ErrorMsg marginTop={errMarginTop}>{message}</ErrorMsg>
              }
            />
          </>
        )
      }}
    />
  )
}

const TimezoneSelect = ({ control, name, isRequired }: TTimezoneSelect) => {
  const { t } = useTranslation()

  return (
    <Controller
      name={name}
      defaultValue=""
      control={control}
      rules={{
        required:
          isRequired &&
          t('partner.errors.required', {
            name: `Email send timezone`,
          }),
      }}
      render={({ field, formState: { errors: fieldErrors } }) => {
        const { onChange, value } = field
        const fieldValue = value || ''

        return (
          <>
            <SelectContainer data-testid="timezoneSelect">
              <SingleSelect
                label={t('partner.welcome_email_timezone')}
                items={TIMEZONES}
                selectedItem={fieldValue}
                handleSelectedItemChange={onChange}
              />
            </SelectContainer>

            <Box marginBottom="12px">
              <ErrorMessage
                name={field.name}
                errors={fieldErrors}
                render={({ message }) =>
                  message && <ErrorMsg marginTop={errMarginTop}>{message}</ErrorMsg>
                }
              />
            </Box>
          </>
        )
      }}
    />
  )
}

const EnableEmailCheckbox = ({ control, name, text }: TEnableEmailCheckbox) => {
  return (
    <Controller
      name={name}
      defaultValue={false}
      control={control}
      render={({ field }) => {
        const { onChange, value } = field
        const fieldValue = !value // using overide=false but displaying as enable=true

        return (
          <Checkbox
            id={name}
            name={name}
            handleChange={() => onChange(fieldValue)}
            checked={fieldValue}
            labelText={<Body size="small">{text}</Body>}
          />
        )
      }}
    />
  )
}

const MonthsInactiveUntilSnoozeSelect = ({
  control,
  isRequired,
}: TProgramInput & IsRequired) => {
  const { t } = useTranslation()
  const items = useMonthsInactive()

  return (
    <Controller
      name="monthsInactiveUntilSnooze"
      defaultValue={null}
      control={control}
      rules={{
        required:
          isRequired &&
          t('partner.errors.required', {
            name: t('partner.field'),
          }),
      }}
      render={({ field, formState: { errors: fieldErrors } }) => {
        const { onChange, value } = field

        return (
          <>
            <SelectContainer>
              <SelectFromMappedValues
                label={t('partner.select_active_pay')}
                items={items}
                value={value}
                onChange={onChange}
              />
            </SelectContainer>
            <ErrorMessage
              name={field.name}
              errors={fieldErrors}
              render={({ message }) =>
                message && <ErrorMsg marginTop={errMarginTop}>{message}</ErrorMsg>
              }
            />
          </>
        )
      }}
    />
  )
}

const CountrySelect = ({
  control,
  index,
  isRequired,
  existingCountriesForBenefit,
  handleChange,
}: TProgramBenefitProps &
  IsRequired & { existingCountriesForBenefit: string[]; handleChange?: () => void }) => {
  const { t } = useTranslation()

  const items = [
    { value: ProgramBenefitCountry.US, text: t('united_states') },
    { value: ProgramBenefitCountry.GB, text: t('united_kingdom') },
    { value: ProgramBenefitCountry.AU, text: t('australia') },
    { value: ProgramBenefitCountry.CA, text: t('canada') },
    { value: ProgramBenefitCountry.DE, text: t('germany') },
  ]
  const defaultValue = items[0].value
  const resource = 'programBenefits'
  const name = `${resource}.${index}.country` as const

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required:
          isRequired &&
          t('partner.errors.required', {
            name: t('partner.field'),
          }),
        validate: {
          isNotDuplicate: v =>
            validations.isNotDuplicate(
              v,
              existingCountriesForBenefit,
              t('partner.errors.duplicate_country_benefit_combination'),
            ),
        },
      }}
      render={({ field, formState: { errors: fieldErrors } }) => {
        const { onChange, value } = field

        const doChange = (e: string | number | null) => {
          onChange(e)
          if (handleChange) handleChange()
        }

        return (
          <>
            <SelectContainer data-testid="country-select">
              <SelectFromMappedValues
                label={t('partner.select_country')}
                items={items}
                value={value}
                defaultValue={defaultValue}
                onChange={doChange}
              />
            </SelectContainer>
            <ErrorMessage
              name={field.name}
              errors={fieldErrors}
              render={({ message }) =>
                message && <ErrorMsg marginTop={errMarginTop}>{message}</ErrorMsg>
              }
            />
          </>
        )
      }}
    />
  )
}

const BenefitSelect = ({
  control,
  index,
  isRequired,
  existingBenefitsForCountry,
  handleChange,
}: TProgramBenefitProps &
  IsRequired & { existingBenefitsForCountry: string[]; handleChange?: () => void }) => {
  const { t } = useTranslation()
  const { loading, error, data } = useBenefits()
  const benefits = (data && data?.benefits) || []

  const resource = 'programBenefits'
  const name = `${resource}.${index}.benefit.id` as const

  let disabled = false
  let label = t('partner.select_benefit')
  if (loading) {
    label = t('loading')
    disabled = true
  } else if (error) {
    label = t('partner.benefits_fetch_error')
    disabled = true
  }

  let items: { value: string; text: string }[] = []
  if (benefits) {
    items = benefits
      .map(b => {
        const text = getAltBenefitName(b.name)

        return { value: b.id, text }
      })
      .sort((a, b) => {
        const nameA = a.text.toLowerCase()
        const nameB = b.text.toLowerCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }

        return 0
      })
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required:
          isRequired &&
          t('partner.errors.required', {
            name: `Benefit name`,
          }),

        validate: {
          isNotDuplicate: v =>
            validations.isNotDuplicate(
              v,
              existingBenefitsForCountry,
              t('partner.errors.duplicate_country_benefit_combination'),
            ),
        },
      }}
      render={({ field, formState: { errors: fieldErrors } }) => {
        const { onChange, value } = field

        const doChange = (e: string | number | null) => {
          onChange(e)
          if (handleChange) handleChange()
        }

        return (
          <SelectContainer data-testid="benefit-select">
            <SelectFromMappedValues
              label={label}
              items={items}
              value={value}
              onChange={doChange}
              disabled={disabled}
            />
            <ErrorMessage
              name={field.name}
              errors={fieldErrors}
              render={({ message }) =>
                message && <ErrorMsg marginTop={errMarginTop}>{message}</ErrorMsg>
              }
            />
          </SelectContainer>
        )
      }}
    />
  )
}

const PricingInput = ({
  control,
  fieldName,
  label,
  index,
  currency,
  isRequired,
  dependentValues,
  onBlur,
}: TProgramBenefitProps &
  IsRequired & {
    label: string
    fieldName: keyof TProgramBenefitVariables
    currency: string
    dependentValues: unknown[]
    onBlur: (e: FocusEvent<HTMLInputElement, HTMLElement>) => void
  }) => {
  const { t } = useTranslation()
  const resource = 'programBenefits'
  const name = `${resource}.${index}.${fieldName}` as const
  const fieldNames = ['userPaysRaw', 'partnerPaysRaw', 'discountRaw']
  const dependents = fieldNames.filter(field => field !== fieldName)
  const dependentFields = dependents.map(field => `${resource}.${index}.${field}`)

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        deps: dependentFields,
        required: isRequired && t('partner.errors.two_of_three_required'),
        pattern: PRICE_FORMAT,
        validate: {
          bigEnough: v => {
            if (v) {
              return validations.validPrice(v, t('partner.errors.price_too_small'))
            }

            return true
          },
          dependentsHaveValue: v =>
            validations.dependentsHaveValue(
              v,
              dependentValues,
              t('partner.errors.one_field_must_be_blank', { type: 'pricing' }),
            ),
        },
      }}
      render={({ field, formState: { errors: fieldErrors } }) => {
        const { value, onChange } = field
        let val = value
        // TODO - better typeguard b/c number predicates dont filter :( https://github.com/microsoft/TypeScript/issues/15048
        if (typeof value === 'number' && !Number.isNaN(value)) {
          val = formatCentsAsCurrencyString(value, currency)
        }

        const fieldValue = val ? String(val) : ''

        let errorMessage
        const errAtIndex =
          fieldErrors?.programBenefits && fieldErrors.programBenefits[index]
        const err = errAtIndex && errAtIndex[fieldName]

        if (err) {
          const { message, type } = err
          if (message) {
            errorMessage = message
          } else if (type && typeof type === 'string') {
            let details = ''
            if (type === 'pattern') details = t('partner.errors.price_format_details')
            errorMessage = t(`partner.errors.${type}`, {
              name: 'Prices',
              details,
            })
          } else {
            errorMessage = t('partner.errors.generic', { name })
          }
        }

        return (
          <div>
            <Input
              name={field.name}
              onBlur={onBlur}
              onChange={event => {
                const target = event.target as HTMLInputElement
                const centsValue = formatCurrencyStringAsCents(target.value)
                let newValue: string | number | null =
                  typeof centsValue === 'number' ? centsValue : target.value
                // casting null to string makes tracking dirty state impossible, so
                // treat empty strings as null when setting the actual field value
                if (typeof newValue === 'string' && newValue.trim() === '') {
                  newValue = null
                }
                onChange(newValue)
              }}
              value={fieldValue}
              label={label}
            />

            {errorMessage && (
              <Box marginBottom="12px">
                <ErrorMsg marginTop={errMarginTop}>{errorMessage}</ErrorMsg>
              </Box>
            )}
          </div>
        )
      }}
    />
  )
}

const ActivePayCheckbox = ({
  control,
  index,
  text,
  activePayAvailable,
}: TProgramBenefitProps & { text: string; activePayAvailable: boolean }) => {
  const { t } = useTranslation()
  const resource = 'programBenefits'
  const name = `${resource}.${index}.eligibleForActivePay` as const

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: val => {
          if (val && !activePayAvailable)
            return t('partner.errors.active_pay_unavailable')

          return true
        },
      }}
      render={({ field, formState: { errors } }) => {
        const { onChange, value } = field

        return (
          <>
            <StyledLabel htmlFor={name}>
              <StyledCheckbox
                type="checkbox"
                id={name}
                name={name}
                value={value ? 'true' : ''}
                checked={value ?? false}
                onChange={onChange}
              />
              <StyledVisibleCheckbox isChecked={value}>
                <svg
                  width="14"
                  height="12"
                  viewBox="0 0 14 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 6.5L6.5 10.5L12 1.5"
                    stroke="white"
                    strokeWidth="2.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </StyledVisibleCheckbox>
              <Body size="small">{text}</Body>
            </StyledLabel>

            <Box marginTop="8px">
              <ErrorMessage
                name={field.name}
                errors={errors}
                render={({ message }) =>
                  message && <ErrorMsg marginTop={errMarginTop}>{message}</ErrorMsg>
                }
              />
            </Box>
          </>
        )
      }}
    />
  )
}

const PartnerEnrollmentOptimizationCheckbox = ({ control }: TEligibilityInput) => {
  const { t } = useTranslation()
  const text = t('partner.use_optimized_enrollment')
  const name = 'enrollmentOptimization'

  return (
    <Controller
      name={name}
      defaultValue={false}
      control={control}
      render={({ field }) => {
        const { onChange, value } = field
        let errorMessage

        // using custom checkbox b/c checked state of DS checkbox does not update
        // correctly with changes to value (e.g. after form reset)
        return (
          <div>
            <StyledLabel htmlFor={name}>
              <StyledCheckbox
                type="checkbox"
                id={name}
                name={name}
                value={value ? 'true' : ''}
                checked={value ?? false}
                onChange={onChange}
              />
              <StyledVisibleCheckbox isChecked={!!value}>
                <svg
                  width="14"
                  height="12"
                  viewBox="0 0 14 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 6.5L6.5 10.5L12 1.5"
                    stroke="white"
                    strokeWidth="2.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </StyledVisibleCheckbox>
              <Body size="small">{text}</Body>
            </StyledLabel>

            {errorMessage && (
              <Box marginBottom="12px">
                <ErrorMsg marginTop={errMarginTop}>{errorMessage}</ErrorMsg>
              </Box>
            )}
          </div>
        )
      }}
    />
  )
}

const Container = styled.div`
  padding: 24px 0;
  border-bottom: 1px solid ${grey[50]};

  &:first-child {
    padding-top: 0;
  }

  &:nth-last-child(2) {
    border-bottom: none;
    padding-bottom: 0;
  }
`

const StyledDateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${grey[70]};
  border-radius: 4px;
  height: 56px;
  padding: 0 16px;
  margin: 16px 0;
  background-color: white;
`

const StyledLabel = styled.label`
  display: inline-flex;
  align-items: baseline;
  cursor: pointer;
`

const StyledCheckbox = styled.input`
  position: absolute;
  opacity: 0;
  margin: 0;
  padding: 0;
  outline: none;
  box-shadow: 0 0 0 0 transparent;
  border: 1px solid gray;
  border-radius: 2px;
  background-color: transparent;
`

const StyledVisibleCheckbox = styled.span<{ isChecked: boolean }>`
  display: grid;
  place-content: center;
  height: 1em;
  width: 1em;
  border-radius: 2px;
  border: 1px solid gray;
  background-color: ${props => (props.isChecked ? 'red' : 'white')};
  border-color: ${props => (props.isChecked ? 'red' : 'gray')};
  margin-right: 8px;
  transition: 180ms border-color ease-in-out;
  transition: 180ms background-color ease-in-out;
  svg {
    transform: scale(0)
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    transition-delay: 80ms;
  }
`

export {
  ActivePayCheckbox,
  DateSelect,
  BenefitSelect,
  CountrySelect,
  EmailTemplateSelect,
  EnableEmailCheckbox,
  AfterNDaysSelect,
  TimeSelect,
  TimezoneSelect,
  DateFormatSelect,
  DomainInputsSection,
  PartnerNameInput,
  ProgramNameInput,
  PartnerSlugInput,
  PartnerSlugInput2,
  PartnerSalesforceIdInput,
  PartnerTypeSelect,
  PartnerContactInput,
  PartnerContactFirstNameInput,
  PartnerContactLastNameInput,
  PartnerContactEmailInput,
  PartnerContactSftpNotificationInput,
  PartnerEnrollmentOptimizationCheckbox,
  PricingInput,
  MonthsInactiveUntilSnoozeSelect,
  DOMAIN_DEFAULT,
}
