import { useQuery } from '@apollo/client'
import { GET_EMAIL_TEMPLATES } from 'data/queries'
import { EmailTemplates } from '../../data/queries/types/EmailTemplates'

type TemplateTypes = 'welcome' | 'reminder'

const useEmailTemplates = (templateType?: TemplateTypes) => {
  const { loading, error, data } = useQuery<EmailTemplates>(GET_EMAIL_TEMPLATES)

  let emailTemplates = data?.emailTemplates

  // filter results by template type if one exists, otherwise return all
  if (templateType && !loading && data && data?.emailTemplates) {
    const emailRegex = new RegExp(templateType, 'i')

    emailTemplates = data.emailTemplates.filter(
      template => template?.name && emailRegex.test(template.name.toLowerCase()),
    )
  }

  return {
    loading,
    error,
    data: { emailTemplates },
  }
}

const useWelcomeEmailTemplates = () => useEmailTemplates('welcome')
const useReminderEmailTemplates = () => useEmailTemplates('reminder')

export { useWelcomeEmailTemplates, useReminderEmailTemplates }
