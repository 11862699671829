import { DialogOverlay, DialogContent } from '@reach/dialog'
import { ReactNode } from 'react'
import styled from 'styled-components'
import { X } from 'ui/components/svg'

export type ModalProps = {
  canClose?: boolean
  isOpen?: boolean
  handleClose?: () => void
  children: ReactNode
}

const Modal = ({ canClose = true, isOpen, handleClose, children }: ModalProps) => {
  if (!isOpen) return null

  const handleDismiss = () => {
    if (canClose && typeof handleClose === 'function') {
      handleClose()
    }
  }

  return (
    <DialogOverlay isOpen={isOpen} onDismiss={handleDismiss} style={{ zIndex: 3 }}>
      <StyledDialogContent aria-label="Action required">
        {canClose && (
          <Button type="button" onClick={handleDismiss}>
            <X />
          </Button>
        )}
        {children}
      </StyledDialogContent>
    </DialogOverlay>
  )
}

const StyledDialogContent = styled(DialogContent)`
  position: relative;
  top: 35%;
  transform: translateY(-35%);
  border-radius: 5px;
  padding: 80px 60px;
  width: 65vw;
`

const Button = styled.button`
  position: absolute;
  right: 0;
  top: -28px;
`

export { Modal }
