import { red } from '@pelotoncycle/design-system'
import { createGlobalStyle, css } from 'styled-components'
import { normalize } from 'ui/style'

const primaryColorForEnv = red[80]

const cssVariables = css`
  :root {
    --header-height: 72px;
    --theme-primary: ${primaryColorForEnv};
  }
`
const GlobalStyle = createGlobalStyle`
  ${normalize}
  ${cssVariables}

  body {
    font-family: 'Roboto', sans-serif;
  }
`

export { GlobalStyle }
