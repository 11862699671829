import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Page, EmptyState, LoadingScreen, RequestError } from 'ui/components'
import { usePartnerUpdateContext } from 'ui/components/PartnerUpdateContext'
import { PartnerUpdateForm } from '../forms'
import { PartnerLogoManager } from '../PartnerLogoManager'

const PartnerUpdateDetails = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { partner, loading, error, getPathForNextStep } = usePartnerUpdateContext()

  if (loading) return <LoadingScreen />
  if (error) return <RequestError error={error} />
  if (!partner) return <EmptyState message={t('partner.not_found')} />

  const onSuccess = () => {
    const nextStep = getPathForNextStep()
    history.push(nextStep)
  }

  return (
    <Page title={t('partner.update_details_title')} maxWidth="600px">
      <PartnerUpdateForm partner={partner} onSuccess={onSuccess} />
      <PartnerLogoManager partner={partner} />
    </Page>
  )
}

export { PartnerUpdateDetails }
