import { Icon, black } from '@pelotoncycle/design-system'
import type { AddFileFunc } from 'data/hooks/useUploadFile'
import { Partner_partner as TPartner } from 'data/queries/types/Partner'
import { useEffect } from 'react'
import { FileRejection } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { AddOrDropFile, useAlerts, AlertVariants } from 'ui/components'
import { get } from 'utils'

export type LogoUploaderProps = {
  logo: TPartner['logo']
  onUpload: AddFileFunc
  isUploading: boolean
  errorMessage?: string | null
  children?: React.ReactNode
}

const LogoUploader = ({
  logo,
  onUpload,
  isUploading,
  errorMessage,
  children,
}: LogoUploaderProps) => {
  const { t } = useTranslation()
  const { addAlert } = useAlerts()

  const alertErrorAndScroll = (message: string) => {
    addAlert({
      message,
      variant: AlertVariants.error,
      autoClose: 8000,
    })
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    if (errorMessage) {
      alertErrorAndScroll(errorMessage)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [errorMessage])

  const handleDropzoneRejection = (fileErrors: FileRejection[]) => {
    if (fileErrors && fileErrors.length) {
      const message = get(fileErrors, 0, 'errors', 0, 'message')

      if (message) alertErrorAndScroll(message)
    }
  }

  return (
    <div data-testid="logo-uploader">
      {logo ? (
        <>
          <div>
            <LogoPreview>
              <a href={logo} target="_blank" rel="noreferrer">
                <img
                  src={logo}
                  alt="logo"
                  style={{ height: '100px', border: '1px dashed black' }}
                />
              </a>
            </LogoPreview>
          </div>
          {children}
        </>
      ) : (
        <AddOrDropFile
          disabled={isUploading}
          onDrop={onUpload}
          onDropRejected={handleDropzoneRejection}
          accept=".png"
        >
          {isUploading ? (
            <Icon name="spinner" height={24} />
          ) : (
            t('partner.logo_upload_text')
          )}
        </AddOrDropFile>
      )}
    </div>
  )
}

// background-color is set to match onboarding header
const LogoPreview = styled.div`
  background-color: ${black};
  display: inline-block;
  margin: 8px 0 16px;
`

export { LogoUploader }
