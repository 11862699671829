import {
  from,
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloProvider,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { useAuth0 } from '@auth0/auth0-react'
import { errorLink, retryLink } from 'data/apolloProviderConfigs'
import { useHistory } from 'react-router-dom'
import { LOGOUT_ROUTE } from 'utils/constants/admin'

const {
  REACT_APP_CF_CLIENT_ACCESS_ID = '',
  REACT_APP_CF_CLIENT_ACCESS_SECRET = '',
  REACT_APP_API_URL = '',
} = process.env

const isLocalHost = REACT_APP_API_URL.indexOf('localhost') > -1

const cloudflareHeaders = {
  'CF-Access-Client-Id': REACT_APP_CF_CLIENT_ACCESS_ID,
  'CF-Access-Client-Secret': REACT_APP_CF_CLIENT_ACCESS_SECRET,
}

type TApolloProviderProps = {
  children: React.ReactNode
}

const ApolloClientProvider = ({ children }: TApolloProviderProps) => {
  const cache = new InMemoryCache()
  const history = useHistory()
  const { getAccessTokenSilently } = useAuth0()

  const logoutAndReturnToLogin = () => history.push(LOGOUT_ROUTE)

  const onErrorLink = errorLink({
    handleError: logoutAndReturnToLogin,
  })

  const headerLink = setContext(async (_, { headers }) => {
    const token = await getAccessTokenSilently().catch(
      // force user logout if token refresh request fails
      logoutAndReturnToLogin,
    )

    /* eslint-disable @typescript-eslint/no-unsafe-assignment */
    return {
      headers: {
        ...headers,
        ...(!isLocalHost ? { ...cloudflareHeaders } : {}),
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
    }
  })

  const httpLink = new HttpLink({ uri: `${REACT_APP_API_URL}` })
  const link = from([onErrorLink, retryLink, headerLink, httpLink])

  const options = {
    cache,
    link,
    name: 'onewellness-admin',
    version: '1.0.0',
  }
  const client = new ApolloClient(options)

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export { ApolloClientProvider }
