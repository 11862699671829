import { Children, ReactNode } from 'react'
import styled from 'styled-components'
import { Flex } from 'ui/components'

type SplitRowProps = { children: ReactNode }

const SplitRow = ({ children }: SplitRowProps) => {
  const renderableChildren = Children.toArray(children)
  const totalRenderableChildren = renderableChildren.length
  const itemWidth = Math.round(100 / totalRenderableChildren)

  return (
    <Flex justifyContent="space-between">
      {Children.map(renderableChildren, child => {
        return <FlexItem percentWidth={`${itemWidth}%`}>{child}</FlexItem>
      })}
    </Flex>
  )
}

const FlexItem = styled.div<{ percentWidth: string }>`
  width: ${props => props.percentWidth && `calc(${props.percentWidth} - 8px)}`};
`

export { SplitRow }
