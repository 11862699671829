import { useContext } from 'react'
import { PartnerUpdateContext } from './PartnerUpdateContext'

function usePartnerUpdateContext() {
  const context = useContext(PartnerUpdateContext)

  if (context === undefined) {
    throw new Error(
      'usePartnerUpdateContext must be used within an PartnerUpdateProvider',
    )
  }

  return context
}

export { usePartnerUpdateContext }
