import { ApolloError } from '@apollo/client'
import { Button, LinkButton } from '@pelotoncycle/design-system'
import {
  usePartnerUpdate,
  useWelcomeEmailTemplates,
  useReminderEmailTemplates,
} from 'data/hooks'
import { Partner_partner as TPartner } from 'data/queries/types/Partner'
import addDays from 'date-fns/addDays'
import { BaseSyntheticEvent, useEffect, useCallback } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Box, useAlerts, AlertVariants } from 'ui/components'
import { formatDatepickerDate } from 'utils/date'
import { deserializePartner, serializePartner, TDeserializedPartner } from 'utils/partner'
import {
  DateSelect,
  TimeSelect,
  TimezoneSelect,
  EmailTemplateSelect,
  AfterNDaysSelect,
  EnableEmailCheckbox,
} from './Fields'
import { FormSection } from './FormSection'
import { SplitRow } from './SplitRow'
import { StyledForm } from './StyledForm'
import { StyledFormActionsContainer } from './StyledFormActionsContainer'
import { StyledMultiFieldContainer } from './StyledMultiFieldContainer'
import { StyledRemoveButton } from './StyledRemoveButton'

type TEmailsFormProps = {
  partner: TPartner
  previousStep: string
  onSuccess?: () => void
}

type TDeserializedPartnerKey = keyof TDeserializedPartner

const getTemplateIdForName = (
  templateName: string | undefined,
  templates?: { id: string; name: string }[] | null,
) => {
  if (!templates) return null

  const match = templates.find(t => t.name === templateName)

  return match?.id || null
}

const PartnerEmailsForm = ({ partner, onSuccess, previousStep }: TEmailsFormProps) => {
  const { t } = useTranslation()
  const { addAlert } = useAlerts()

  const { updatePartner, loading: updatePartnerLoading } = usePartnerUpdate({
    partnerId: partner.id,
  })

  const {
    welcomeEmailTemplate,
    reminderEmailTemplate,
    overrideWelcomeEmail,
    overrideReminderEmail,
    reminderEmailDays,
    terminationDate,
    name,
  } = partner

  const welcomeEmailDate = partner?.welcomeEmailDate as string | null

  const welcomeEmailDateDate = deserializePartner.welcomeEmailDateDate(welcomeEmailDate)
  const welcomeEmailTime = deserializePartner.welcomeEmailTime(welcomeEmailDate)
  const welcomeEmailTimezone = deserializePartner.welcomeEmailTimezone(welcomeEmailDate)

  const deserializedTerminationDate = deserializePartner.terminationDate(terminationDate)

  const {
    loading: welcomeEmailsLoading,
    error: welcomeEmailsError,
    data: welcomeEmailsData,
  } = useWelcomeEmailTemplates()
  const {
    loading: reminderEmailsLoading,
    error: reminderEmailsError,
    data: reminderEmailsData,
  } = useReminderEmailTemplates()

  const {
    control,
    formState,
    getValues,
    handleSubmit,
    setValue,
    clearErrors,
    setError,
    watch,
  } = useForm<TDeserializedPartner>({
    defaultValues: {
      overrideWelcomeEmail,
      overrideReminderEmail,
      welcomeEmailTemplate,
      welcomeEmailDate,
      welcomeEmailDateDate,
      welcomeEmailTime,
      welcomeEmailTimezone,
      reminderEmailTemplate,
      reminderEmailDays,
      terminationDate: deserializedTerminationDate,
    },
  })

  const addErrorAlert = useCallback((message: string) => {
    addAlert({
      variant: AlertVariants.error,
      message,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const emailTemplateError = t('partner.email_templates_fetch_error')
  useEffect(() => {
    if (welcomeEmailsError || reminderEmailsError) {
      addErrorAlert(emailTemplateError)
    }
  }, [welcomeEmailsError, reminderEmailsError, addErrorAlert, emailTemplateError])

  const { dirtyFields } = formState

  const isActuallyDirty = Object.keys(dirtyFields).length
  const shouldDisableSubmit = updatePartnerLoading || !isActuallyDirty

  const shouldDirty = true
  const shouldTouch = true

  const resetWelcomeEmailFields = () => {
    const formValues = getValues()
    // We're clearing errors on these fields during a reset, so only want to call
    // these functions if the fields have values (i.e. need to be reset) -- should preserve valid
    // errors on required fields. Cant trust accuracy of dirytValues :(
    const valuesExistToReset =
      !!formValues.welcomeEmailDateDate ||
      !!formValues.welcomeEmailTime ||
      !!formValues.welcomeEmailTimezone ||
      !!formValues.welcomeEmailTemplate?.name

    if (!valuesExistToReset) return

    setValue('welcomeEmailTemplate', null, { shouldDirty, shouldTouch })
    setValue('welcomeEmailTemplate.name', '')
    setValue('welcomeEmailDateDate', null, { shouldDirty, shouldTouch })
    setValue('welcomeEmailTime', null, { shouldDirty, shouldTouch })
    setValue('welcomeEmailTimezone', null, { shouldDirty, shouldTouch })

    // will also clear error on nested values - need this to clear custom error when hasPartialDateParts
    clearErrors([
      'welcomeEmailTemplate',
      'welcomeEmailDateDate',
      'welcomeEmailTime',
      'welcomeEmailTimezone',
    ])
  }

  const resetReminderEmailFields = () => {
    const formValues = getValues()
    // We're clearing errors on these fields during a reset, so only want to call
    // these functions if the fields have values (i.e. need to be reset) -- should preserve valid
    // errors on required fields. Cant trust accuracy of dirytValues :(
    const valuesExistToReset =
      !!formValues.reminderEmailTemplate?.name || !!formValues.reminderEmailDays

    if (!valuesExistToReset) return

    setValue('reminderEmailTemplate', null, { shouldDirty, shouldTouch })
    setValue('reminderEmailTemplate.name', '') // have to manually update nested field
    setValue('reminderEmailDays', null, { shouldDirty, shouldTouch })

    clearErrors(['reminderEmailTemplate', 'reminderEmailDays'])
  }

  const resetTerminationDate = () => {
    setValue('terminationDate', null, { shouldDirty })
  }

  const welcomeEmailsEnabled = !watch('overrideWelcomeEmail')
  const reminderEmailsEnabled = !watch('overrideReminderEmail')

  const welcomeEmails = welcomeEmailsData?.emailTemplates
  const welcomeEmailNames = (welcomeEmails && welcomeEmails.map(e => e.name)) || []

  const reminderEmails = reminderEmailsData?.emailTemplates
  const reminderEmailNames = (reminderEmails && reminderEmails.map(e => e.name)) || []

  const welcomeEmailLabel = welcomeEmailsLoading
    ? t('loading')
    : t('partner.select_template')

  const reminderEmailLabel = reminderEmailsLoading
    ? t('loading')
    : t('partner.select_template')

  type IndexablePartner = {
    [key: string]: unknown
  }

  const handleUpdatePartner: SubmitHandler<TDeserializedPartner> = input => {
    const fieldsToUpdate = Object.keys(dirtyFields).filter(field =>
      Boolean(dirtyFields[field as TDeserializedPartnerKey]),
    )

    const initialValue = {} as IndexablePartner
    const customDateField = {} as Partial<Pick<TDeserializedPartner, 'welcomeEmailDate'>>

    const welcomeEmailDateFields = [
      'welcomeEmailDateDate',
      'welcomeEmailTime',
      'welcomeEmailTimezone',
    ]

    const welcomeEmailDateIsDirty = fieldsToUpdate.some(
      f => welcomeEmailDateFields.indexOf(f) > -1,
    )

    const allDatePartsNull = welcomeEmailDateFields.every(
      field => !input[field as TDeserializedPartnerKey],
    )
    const allDatePartsFilled = welcomeEmailDateFields.every(
      field => !!input[field as TDeserializedPartnerKey],
    )
    // if welcomeEmailDateIsDirty AND incomplete, display an error
    const hasPartialDateParts = !(allDatePartsNull || allDatePartsFilled)
    if (welcomeEmailDateIsDirty && hasPartialDateParts) {
      welcomeEmailDateFields
        .filter(field => !input[field as TDeserializedPartnerKey])
        .forEach(f => {
          setError(f as TDeserializedPartnerKey, {
            type: 'custom',
            message: t('partner.errors.all_required'),
          })
        })

      return
    }

    if (welcomeEmailDateIsDirty) {
      customDateField.welcomeEmailDate = serializePartner.buildDateFromDateParts({
        date: input.welcomeEmailDateDate,
        time: input.welcomeEmailTime,
        timezone: input.welcomeEmailTimezone,
      })
    }

    const normalizedFields = fieldsToUpdate.reduce((acc, field) => {
      switch (field) {
        // ignore these fields b/c handled in customDateField above
        case 'welcomeEmailDateDate':
        case 'welcomeEmailTime':
        case 'welcomeEmailTimezone':
          break
        case 'welcomeEmailTemplate':
          acc.welcomeEmailTemplateId = getTemplateIdForName(
            input?.welcomeEmailTemplate?.name,
            welcomeEmails,
          )
          break
        case 'reminderEmailTemplate':
          acc.reminderEmailTemplateId = getTemplateIdForName(
            input?.reminderEmailTemplate?.name,
            reminderEmails,
          )
          break
        case 'terminationDate':
          acc.terminationDate = input.terminationDate
            ? formatDatepickerDate(input.terminationDate)
            : null
          break
        default:
          acc[field as TDeserializedPartnerKey] = input[field as TDeserializedPartnerKey]
          break
      }

      return acc
    }, initialValue)

    const inputData = { ...normalizedFields, ...customDateField }

    updatePartner({ input: inputData })
      .then(({ data: responseData }) => {
        if (responseData?.partnerUpdate?.requestOk) {
          addAlert({
            variant: AlertVariants.success,
            message: t('partner.update_succeeded', { name }),
            autoClose: 8000,
          })
          if (onSuccess) onSuccess()
        }
      })
      .catch(e => {
        let error = ''
        if (typeof e === 'string') error = e
        if (e instanceof ApolloError && typeof e.message === 'string') error = e.message
        addAlert({
          variant: AlertVariants.error,
          message: t('partner.update_failed', { error }),
          autoClose: 8000,
        })
      })
  }

  const submitForm = (e: BaseSyntheticEvent) => {
    handleSubmit(handleUpdatePartner)(e).catch(err => {
      /* eslint-disable-next-line */
      console.log('err ==>', err)
    })
  }

  const minDate = addDays(new Date(), 1)

  return (
    <StyledForm>
      <form onSubmit={submitForm}>
        <FormSection
          title={t('partner.welcome_email')}
          subtext={t('partner.email_select_template', { type: 'welcome' })}
        >
          <EnableEmailCheckbox
            control={control}
            name="overrideWelcomeEmail"
            text={t('partner.turn_on_emails', { type: 'welcome' })}
          />

          <EmailTemplateSelect
            name="welcomeEmailTemplate.name"
            control={control}
            label={welcomeEmailLabel}
            disabled={welcomeEmailsLoading}
            items={welcomeEmailNames}
            isRequired={welcomeEmailsEnabled}
          />
        </FormSection>

        <FormSection
          title={t('partner.configure_details')}
          subtext={t('partner.welcome_email_configure_prompt')}
          borderBottom
        >
          <StyledMultiFieldContainer>
            <SplitRow>
              <DateSelect
                control={control}
                name="welcomeEmailDateDate"
                minDate={minDate}
                isRequired={welcomeEmailsEnabled}
              />
              <TimeSelect
                control={control}
                name="welcomeEmailTime"
                isRequired={welcomeEmailsEnabled}
              />
            </SplitRow>

            <TimezoneSelect
              control={control}
              name="welcomeEmailTimezone"
              isRequired={welcomeEmailsEnabled}
            />
            <StyledRemoveButton onClick={resetWelcomeEmailFields}>
              {t('partner.reset')}
            </StyledRemoveButton>
          </StyledMultiFieldContainer>
        </FormSection>

        <FormSection
          title={t('partner.reminder_email')}
          subtext={t('partner.email_select_template', { type: 'reminder' })}
          borderBottom
          testId="reminderEmailSection"
        >
          <EnableEmailCheckbox
            control={control}
            name="overrideReminderEmail"
            text={t('partner.turn_on_emails', { type: 'reminder' })}
          />

          <EmailTemplateSelect
            name="reminderEmailTemplate.name"
            control={control}
            label={reminderEmailLabel}
            disabled={reminderEmailsLoading}
            items={reminderEmailNames}
            isRequired={reminderEmailsEnabled}
          />

          <AfterNDaysSelect
            control={control}
            name="reminderEmailDays"
            isRequired={reminderEmailsEnabled}
          />

          <StyledRemoveButton onClick={resetReminderEmailFields}>
            {t('partner.reset')}
          </StyledRemoveButton>
        </FormSection>

        <FormSection title={t('partner.termination_date')}>
          <DateSelect
            control={control}
            name="terminationDate"
            minDate={minDate}
            isRequired={false}
          />

          <StyledRemoveButton onClick={resetTerminationDate}>
            {t('partner.reset')}
          </StyledRemoveButton>
        </FormSection>

        <StyledFormActionsContainer>
          <Box width="126px" marginRight="16px">
            <LinkButton
              color="dark"
              variant="outline"
              component={Link}
              to={previousStep}
              text={t('partner.go_back')}
              width="adaptive"
            />
          </Box>
          <Box width="126px">
            <Button
              type="submit"
              data-testid="partnerEmailsUpdate"
              width="adaptive"
              isDisabled={shouldDisableSubmit}
            >
              {t('partner.save_and_continue')}
            </Button>
          </Box>
        </StyledFormActionsContainer>
      </form>
    </StyledForm>
  )
}

export { PartnerEmailsForm }
