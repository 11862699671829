import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Page } from 'ui/components'
import { PARTNERS_ROUTE } from 'utils/constants/admin'
import { PartnerCreateForm } from '../forms'

const PartnerCreate = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const onSuccess = () => {
    history.push(PARTNERS_ROUTE)
  }

  return (
    <Page title={t('partner.details')} maxWidth="600px">
      <PartnerCreateForm onSuccess={onSuccess} />
    </Page>
  )
}

export { PartnerCreate }
