import { useTranslation } from 'react-i18next'

// should eventually move to a mapper/model object. For now need
// a hook (and not just a method) to use translation hook
const useMonthsInactive = () => {
  const { t } = useTranslation()

  return [
    { value: null, text: t('partner.no_active_pay') },
    { value: 3, text: t('partner.three_months_active_pay') },
    { value: 6, text: t('partner.six_months_active_pay') },
  ]
}

export { useMonthsInactive }
