import { useMutation } from '@apollo/client'
import {
  PartnerContactUpdate,
  PartnerContactUpdateVariables,
} from 'data/mutations/types/PartnerContactUpdate'
import { UPDATE_PARTNER_CONTACT } from '../mutations'
import { GET_PARTNER } from '../queries'

type TID = {
  partnerId: string
}

const usePartnerContactUpdate = ({ partnerId }: TID) => {
  const [mutate, { loading, error }] = useMutation<PartnerContactUpdate>(
    UPDATE_PARTNER_CONTACT,
    {
      refetchQueries: [{ query: GET_PARTNER, variables: { id: partnerId } }],
    },
  )

  const updatePartnerContact = ({ input, contactId }: PartnerContactUpdateVariables) => {
    return mutate({
      variables: { input, contactId },
    })
  }

  return { updatePartnerContact, error, loading }
}

export { usePartnerContactUpdate }
