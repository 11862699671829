import { SingleSelect, LinkButton, Input } from '@pelotoncycle/design-system'
import { usePartners } from 'data/hooks'
import { useState, ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, Link } from 'react-router-dom'
import styled from 'styled-components'
import { Page, EmptyState, LoadingScreen, Box } from 'ui/components'
import { Search } from 'ui/components/svg'
import { PARTNERS_ROUTE, PARTNER_CREATE_ROUTE } from 'utils/constants/admin'
import { QueryResultTable } from '../..'

type TPartner = {
  name: string
  salesforceCustomerId: string
}

// enforce that column values must be keys of data
type TArrayOfDataKeys<T> = (keyof T)[]

// enforce that column values must be keys of data
const columns: TArrayOfDataKeys<TPartner> = ['name', 'salesforceCustomerId']

const EmptyOrLoading = ({ loading, message }: { loading: boolean; message: string }) => {
  if (loading) {
    return <LoadingScreen />
  }

  return <EmptyState message={message} />
}

const Partners = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const SORT_OPTIONS = [t('partners.sort_name_asc'), t('partners.sort_name_desc')]
  const SORT_PARAM = {
    [SORT_OPTIONS[0]]: 'name',
    [SORT_OPTIONS[1]]: '-name',
  }
  const [sortOrder, setSortOrder] = useState<string>(SORT_OPTIONS[0])
  const [searchTerm, setSearchTerm] = useState<string>('')

  // ignoring cache when sort order changes b/c cached result order inconsistent
  const { data, loading } = usePartners({
    orderBy: SORT_PARAM[sortOrder],
    readFromCache: false,
  })
  const partnersData = data?.partners || []
  const downcasedSearchTerm = searchTerm && searchTerm.toLowerCase()
  const results = searchTerm
    ? partnersData.filter(p => p.name.toLowerCase().indexOf(downcasedSearchTerm) > -1)
    : partnersData

  const handleSortOrderChange = (val: string) => {
    const match = SORT_OPTIONS.find(option => option === val)
    if (match) {
      setSortOrder(match)
    }
  }

  const handleRowClick = ({ id }: { id: string | number }) => {
    if (id) {
      history.push(`${PARTNERS_ROUTE}/${id}`)
    }
  }

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value
    setSearchTerm(input.trim())
  }

  return (
    <Page title={t('partners.title')} testId="partners-page">
      <FlexTop>
        <Box position="relative" width="300px">
          <Input
            name="search"
            width="100%"
            label={t('partners.search_by_name')}
            value={searchTerm}
            onChange={handleSearch}
          />
          <SvgContainer>
            <Search />
          </SvgContainer>
        </Box>
        <ActionsContainer>
          <SingleSelect
            data-testid="monthSelect"
            label={t('partners.sort_by')}
            items={SORT_OPTIONS}
            width="202px"
            selectedItem={sortOrder}
            handleSelectedItemChange={handleSortOrderChange}
          />
          <LinkButton
            component={Link}
            to={PARTNER_CREATE_ROUTE}
            text={t('partner.add_partner')}
          />
        </ActionsContainer>
      </FlexTop>
      {results.length ? (
        <QueryResultTable
          testId="reporting-eel-table"
          variant="solid"
          data={results}
          columns={columns}
          onRowClick={handleRowClick}
        />
      ) : (
        <EmptyOrLoading
          loading={loading}
          message={
            searchTerm
              ? t('partners.no_matching_partners', { search: searchTerm })
              : t('partners.no_partners')
          }
        />
      )}
    </Page>
  )
}

const FlexTop = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;
`

const ActionsContainer = styled.div`
  display: flex;
  & > .singleSelectWithError {
    margin-right: 16px;
  }

  li:first-child {
    display: none;
  }
`

// copied from SearchCombobox
const SvgContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 16px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: text;
`

export { Partners }
